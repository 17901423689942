import {BreakpointObserver} from '@angular/cdk/layout';
import {Component, ElementRef, HostBinding, Inject, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {MatFabButton} from '@angular/material/button';
import {BackendService} from '@global/services/backend.service';
import {Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'phscw-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {
    // Additional class on the host element when breakpoints are matching
    @HostBinding('class.large-screen') isLargeScreen = false;
    @ViewChild('input') input!: ElementRef;
    @ViewChild('button') button!: MatFabButton;

    chatStarted = false;
    chatFinished = false;
    messages: {text: string; isUser: boolean; icon: string}[] = [];
    suggestions: string[] = [
        'Wann war mein letzter Besuch bei dieser Einrichtung?',
        'Wann ist mein nächster Termin?',
        'Wie viele Kontakte gab es insgesamt?',
    ];

    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // browser speech api
    synthesis = window.speechSynthesis;

    constructor(
        private bottomSheetRef: MatBottomSheetRef<ChatComponent>,
        // eslint-disable-next-line new-cap
        @Inject(MAT_BOTTOM_SHEET_DATA) public institutionId: number,
        private backendService: BackendService,
        private observer: BreakpointObserver,
    ) {}

    ngOnInit(): void {
        this.initializeEventSubscriptions();
    }

    // Aufräumen
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    private initializeEventSubscriptions() {
        /**
         *  Subscribe to the breakpoint at which the bottom sheet changes its size
         *  Used to set the class on the host element
         */
        this.observer
            .observe(['(min-width: 960px)'])
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((state) => {
                this.isLargeScreen = state.matches;
            });
    }

    send(input: string) {
        this.disableInput();
        this.chatStarted = true;

        // user always sends the first message
        this.messages.push({
            text: input,
            isUser: true,
            icon: 'user',
        });
        this.messages.push({
            text: '...',
            isUser: false,
            icon: 'spinner',
        });

        this.getResponse(input);
    }

    private getResponse(input: string) {
        this.backendService
            .postRequestAsync('ArtificialIntelligence/read', {
                prompt: input,
                context: {route: `/institutions/(details:${this.institutionId})`},
            })
            .pipe(map((response) => response.data))
            .subscribe((responseMessage) => {
                // remove leading newlines
                responseMessage = responseMessage.replace(/^\n+/, '');
                // remove placeholder message
                this.messages = this.messages.filter((message) => message.text !== '...');
                // add response to the messages using typewriter effect
                this.displayTypewriterEffect(responseMessage);
            });
    }

    // Typewriter effect for incoming chat messages
    private displayTypewriterEffect(message: string) {
        const response = {
            text: '',
            isUser: false,
            icon: 'atom-simple',
        };
        this.messages.push(response);

        let index = 0;
        const interval = setInterval(() => {
            if (index < message.length) {
                response.text += message.charAt(index);
                index++;
            } else {
                clearInterval(interval);
                this.chatFinished = true;
            }
        }, 10); // Typewriter effect speed
    }

    // text to speech
    speakMessage(message: string) {
        const textToSpeech = new SpeechSynthesisUtterance(message);
        const voice = speechSynthesis.getVoices().filter((voice) => voice.name === 'Google Deutsch')[0];
        textToSpeech.lang = 'de-DE';
        textToSpeech.voice = voice;
        this.synthesis.speak(textToSpeech);
    }

    closeChat() {
        this.bottomSheetRef.dismiss();
    }

    // restart chat after one question
    restart() {
        this.chatStarted = false;
        this.chatFinished = false;
        this.messages = [];
    }

    /*
     * disable inputs after submitting question
     * TODO form controls
     */
    disableInput() {
        this.input.nativeElement.disabled = true;
        this.button._elementRef.nativeElement.disabled = true;
    }

    openLink(event: MouseEvent): void {
        this.bottomSheetRef.dismiss();
        event.preventDefault();
    }
}
