// Angular-Module
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Services
import {InitService} from '@global/services/init.service';
import {StorageService} from '@global/services/storage.service';
import {UserPermissionsService} from '@global/services/user-permissions.service';
// Service des übergeordneten Feature-Moduls
import {InstitutionsService} from '../institutions.service';
// Eigener Service
import {InstitutionsDataService} from './institutions-data.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
import {Institution} from '@shared/institution';
import {Listentry} from '@shared/listentry';
import {LooseObject} from '@shared/loose-object';
import {ParentInstitution} from '@shared/parent-institution';
import {SelectData} from '@shared/select-data';
// Shared Services importieren
import {ClearingDuplicatesPanelService} from '@shared/clearing/clearing-duplicates-panel/clearing-duplicates-panel.service';
import {GridService} from '@shared/grid/grid.service';
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// PopupConfirmation - Komponente einbinden
import {PopupConfirmationComponent} from '@shared/popups/popup-confirmation/popup-confirmation.component';
import {PopupMessageComponent} from '@shared/popups/popup-message/popup-message.component';
// Pipes
import {IsUndefinedPipe} from '@shared/input/is-undefined.pipe';
// Environment
import {environment} from '@environment';
import {Notification} from '@shared/notification';
import {ArtificialIntelligenceService} from '@shared/artificial-intelligence/artificial-intelligence.service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {hasOwn, notEmpty} from '@shared/utils';
import {Router} from '@angular/router';

@Component({
    selector: 'phscw-institutions-data',
    templateUrl: './institutions-data.component.html',
    styleUrls: ['./institutions-data.component.scss'],
})
export class InstitutionsDataComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Name dieser Komponente, welche in event.target geprüft wird
    myself = 'institutions-data';

    // Referenz auf Formular
    @ViewChild('institutionsDataForm', {static: false}) institutionsDataForm: NgForm;

    // Einstellungen der Datenfelder
    datafieldsConfiguration: any;
    regionAssignmentLabel: any;
    // Optionen für InputSelect
    institutionType1Options: SelectData[] = null;
    institutionType2Options: SelectData[] = null;

    // ID der aktuell ausgewählten Einrichtung
    institutionId: number;
    // Modul-Daten (Stammdaten der Einrichtung)
    data: Institution;
    // Da Cancel doch nicht so funktioniert wie gedacht, hier die schnelle Variante mit eigener Datenkopie vor Benutzeränderungen
    originalData: Institution;
    // EditMode aktiv?
    @Input() editMode = false;

    // Definiert, ob über die Toolbar Einrichtungen editiert werden dürfen, d.h. ob der EditMode-Button sichtbar ist
    allowEditInstitution = false;
    // Definiert, ob über die Toolbar Einrichtungen außerhalb des eigenen Gebietes editiert werden dürfen, d.h. ob der EditMode-Button sichtbar ist
    allowEditForeignInstitution = false;
    // Kombiniert alle edit rights, damit die logik im ts sichtbar ist
    allowEditCombined = false;
    // Befindet sich die ausgewählte Einrichtung im eigenen Gebiet?
    institutionInOwnRegion = false;
    // Definiert, ob ERP-Nummer einer existierenden Einrichtung geändert werden darf
    allowEditInstitutionErpNumber = false;
    disableNewInstitutionErpNumber = false;
    // Definiert, ob Sonder-Gebietszuordnungen manuell editierbar sind
    allowManualRegionAllocations = false;
    // Definiert, ob die übergeordnete Einrichtung angezeigt werden darf.
    allowMoveInstitution = false;
    // Definiert ob Einrichtungen zusammengeführt werden dürfen
    allowMergeEntities = false;

    // Flag definiert ob gerade geladen wird
    loading = false;
    // Flag definiert ob gerade Duplikate geprüft werden
    checkingDuplicates = false;
    // Flag definiert ob gerade gespeichert wird
    saving = false;
    // Flag definiert ob Timestamps angezeigt werden
    timestampsVisible = false;

    // Flag definiert, ob der Button zum Wechsel ins Clearing angezeigt wird
    clearingButtonVisible = false;

    // Ob Buttons for RemoteEdetailerangezigt werden soll
    showEdetailerLinks = false;
    disableEdetailerLinks: unknown = {};

    // Flag definiert ob Typen abhängig von der Hierarchie gesetzt werden
    hierarchyRelatedSelectboxEnabled: boolean = environment.hierarchyRelatedSelectboxEnabled || false;
    // Flag definiert ob Typen abhängig vom ersten Einrichtungstyp gesetzt werden
    institutionTypeRelatedSelectboxEnabled: boolean = environment.institutionTypeRelatedSelectboxEnabled || false;
    // Flag definiert, ob Stadt selbst eingetragen werden darf oder nur anhand der Postleitzahl ausgewählt wird
    combinedZipcodeCityInputEnabled: boolean = environment.combinedZipcodeCityInputEnabled || false;

    // Flag definiert, ob sich die Parent-Daten geändert haben
    parentChanged = false;

    // Es sollen keine gelöschten Regionen mitgeladen werden, wenn die manuelle Regionsauswahl aktiv ist.
    readonly noDeletedRegionsRestriction = {includeDeleted: false};
    dataNotification: Notification;

    institutionsSchema;
    allowEditShadowInstitution = false;
    allowEditShadowCombined = false;

    // Ai Chat aktiviert?
    institutionsEnableAiChat = false;
    // Überschreibt ob der normale Editmode auftaucht
    forceNormalEditMode = false;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param initService
     * @param storageService
     * @param gridService
     * @param institutionsService
     * @param institutionsDataService
     * @param toolbarService
     * @param userPermissions
     * @param dialog
     * @param translateService
     * @param clearingDuplicatesPanelService
     * @param isUndefinedPipe
     * @param aiService
     * @param bottomSheet
     * @param router
     */
    constructor(
        private initService: InitService,
        private storageService: StorageService,
        private gridService: GridService,
        public institutionsService: InstitutionsService,
        private institutionsDataService: InstitutionsDataService,
        private toolbarService: ToolbarService,
        private userPermissions: UserPermissionsService,
        private dialog: MatDialog,
        private translateService: TranslateService,
        private clearingDuplicatesPanelService: ClearingDuplicatesPanelService,
        private isUndefinedPipe: IsUndefinedPipe,
        private aiService: ArtificialIntelligenceService,
        private bottomSheet: MatBottomSheet,
        private router: Router,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
        // Einstellungen der Felder initialisieren
        this.initializeInstitutionInputs();
        // Berechtigung "allowEditInstitution" prüfen
        this.checkAllowEditInstitution();
        // Berechtigung "allowEditInstitutionErpNumber" prüfen
        this.checkAllowEditInstitutionErpNumber();
        // Berechtigung "allowManualRegionAllocations" prüfen
        this.checkAllowManualRegionAllocations();
        // Berechtigung "allowMoveInstitution" prüfen
        this.checkAllowMoveInstititution();
        // Berechtigung "allowMergeEntities" prüfen
        this.checkAllowMergeEntities();
        // Berechtigung "enableModuleClearing" prüfen
        this.checkEnableModuleClearing();
        // Environment "showEdetailerLinks" prüfen
        this.checkEdetailerLink();
        // environment "disableNewInstitutionErpNumber" prüfen
        if (Object.prototype.hasOwnProperty.call(environment, 'newInstitutionErpNumberDisabled')) {
            this.disableNewInstitutionErpNumber = environment.newInstitutionErpNumberDisabled;
        }
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Darauf warten, dass alle listentries in der indexedDB gespeichert sind
        this.initService.allInitialized.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: boolean) => {
            // Abbruch, falls Anfrage erfolglos war
            if (result === false) {
                return;
            }
            this.getInputSelectOptions();
        });

        // In E-Liste wurde eine Einrichtung ausgewählt
        this.institutionsService.selectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: any) => {
                this.onSelectionChanged(result);
            });

        // Wenn der Cancel-Button der Toolbar ausgelöst wurde. Abbrechen auslösen.
        this.toolbarService.eventCloseComponent
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                if (result.target === this.myself) {
                    this.clickCancel();
                }
            });

        // In Toolbar wurde ein Button geklickt
        this.toolbarService.eventToolbarButtonClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event = result;
                // Abbruch, falls Anfrage erfolglos war
                if (event.target === this.myself) {
                    if (event.sender === 'toolbar-google-maps') {
                        // GoogleMaps
                        this.showGoogleMaps();
                    } else if (event.sender === 'toolbar-parkopedia') {
                        // Parkopedia
                        this.showParkopedia();
                    } else if (event.sender === 'toolbar-send-edetailer') {
                        // eVisit
                        this.sendEdetailer();
                    } else if (event.sender === 'toolbar-start-edetailer') {
                        // eVisit
                        this.startEdetailer();
                    } else if (event.sender === 'toolbar-start-chat') {
                        // AI Chat starten
                        this.aiService.startChat(this.institutionId);
                    }
                }

                if (event.sender === 'toolbar-duplicate-search') {
                    // Duplikat-Suche
                    this.checkForDuplicates(this.data.id, true);
                }
            });

        // Wenn der Duplikat-Check abgeschlossen wurde, Flag deaktiveren
        this.clearingDuplicatesPanelService.eventDuplicatesCheckComplete
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((event: CWEvent) => {
                if (event.target !== this.myself && event.data.module !== 'institutions') {
                    return;
                }
                this.checkingDuplicates = false;
                this.toolbarService.loadingComplete('institutions-data', 'SearchDuplicate');
            });

        // Laden der benötigen Datenbank struktruren
        this.initService.allInitialized.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: boolean) => {
            this.loadFrontendConfiguration();
            this.loadDatabaseSchema();
        });

        this.loadDatabaseSchema();
        this.loadFrontendConfiguration();

        // Event der Toolbar zum Editeren einer schatten Einrichtung
        this.toolbarService.eventEditShadowItem
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls diese Komponente nicht Ziel des Events ist
                if (event.target != 'institutions-data') {
                    return;
                }
                this.institutionsService.isShadow = true;
            });
    }

    loadFrontendConfiguration() {
        const promise = this.storageService.getItem('config|institutionsEnableAiChat');
        promise.then((value) => {
            this.institutionsEnableAiChat = value;
        });
    }

    /**
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     * @author  Eric Häußel <e.haeusel@pharmakon.software>
     */
    sendEdetailer(): void {
        const serviceRequest$ = this.toolbarService.getEdetailerLinks('', this.institutionId, null);
        serviceRequest$.subscribe((result: CWResult) => {
            const mailLink = document.createElement('a');
            const link = result['data']['client'];

            let mail = 'mailto:';
            mail += this.data['mail'];
            mail += '?subject= ';
            mail += 'Einladung zur Präsentation';
            mail += '&body=';
            mail += 'Sehr geehrte Damen und Herren, %0D%0A%0D%0A';
            mail += 'Bitte klicken Sie auf folgenden Link um die Präsentation zu starten:%0D%0A';
            mail += link + '%0D%0A%0D%0A';
            mail += 'Mit freundlichen Grüßen%0D%0A%0D%0A';
            mail += result['data']['employee']['firstname'] + ' ' + result['data']['employee']['lastname'];

            mailLink.href = mail;
            mailLink.click();
            mailLink.remove();
        });
    }

    /**
     * @brief   Link für eVisit-Instanz laden und in neuem Tab öffnen
     * @author  Eric Häußel <e.haeusel@pharmakon.software>
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    startEdetailer(): void {
        /*
         * Prüfe ob es sich um ein Apple Device handelt
         * ebenfalls Macintosh abfragen - siehe https://forums.developer.apple.com/thread/119186
         * (ziemlich beschissene Lösung - ändern sobald man korrekt zwischen iPad und Macintosh unterscheiden kann)
         */
        if (window.navigator && window.navigator.userAgent.match(/iPhone|iPad|iPod|Macintosh|MacIntel/i)) {
            this.enterNewRoomApple();
        } else {
            this.enterNewRoom();
        }
    }

    /**
     * @brief   Link-Element erzeugen und klicken, um neuen Tab zu öffnen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private enterNewRoom(): void {
        // Link aus Backend laden
        const serviceRequest$ = this.toolbarService.getEdetailerLinks('', this.institutionId, null);
        serviceRequest$.subscribe(
            (result: CWResult) => {
                // Ergebnis prüfen
                if (result.success) {
                    // Link zwischenspeichern
                    const link = result['data']['server'];

                    // Link in neuem Tab öffnen
                    window.open(link, '_blank');
                }
            },
            (error: any) => {
                // Fehler anzeigen
                console.error(error);
            },
        );
    }

    /**
     * @brief   Link für eVisit-Instanz laden und in neuem Tab öffnen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private enterNewRoomApple(): void {
        // neues Fenster öffnen
        const newTab = window.open();
        newTab.document.title = 'Bitte warten...';

        // Link aus Backend laden
        const serviceRequest$ = this.toolbarService.getEdetailerLinks('', this.institutionId, null);
        serviceRequest$.subscribe(
            (result: CWResult) => {
                // Ergebnis prüfen
                if (result.success) {
                    // Link zwischenspeichern
                    const link = result['data']['server'];

                    // Link in neuem Tab öffnen
                    newTab.location = link;
                } else {
                    // Fenster schließen bei Fehler
                    newTab.close();
                }
            },
            (error: any) => {
                // Fenster schließen bei Fehler
                console.error(error);
                newTab.close();
            },
        );
    }

    /**
     * @brief   Einstellungen der Felder initialisieren
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeInstitutionInputs(): void {
        // Einstellung aus Environment übernehmen
        if (Object.prototype.hasOwnProperty.call(environment, 'institutionsDatafields')) {
            this.datafieldsConfiguration = environment['institutionsDatafields'];
        }
        if (Object.prototype.hasOwnProperty.call(environment, 'regionAssignmentDatafields')) {
            this.regionAssignmentLabel = environment['regionAssignmentDatafields'];
        }
    }

    /**
     * Berechtigung "allowEditInstitution" prüfen
     */
    checkAllowEditInstitution(): void {
        const permissionAllowEditInstitution: boolean = this.userPermissions.getPermissionValue('allowEditInstitution');
        this.allowEditInstitution = permissionAllowEditInstitution;
        const permissionAllowEditShadowInstitution: boolean =
            this.userPermissions.getPermissionValue('allowEditShadowInstitution');
        this.allowEditShadowInstitution = permissionAllowEditShadowInstitution;
        this.forceNormalEditMode = this.userPermissions.getPermissionValue('forceNormalEditMode');

        // Berechtigung einschränken anhand des Einrichtungstyps
        const permissionsAllowEditInstitutionsTypes: string =
            this.userPermissions.getPermissionValue('allowEditInstitutionByTypes');
        if (
            permissionsAllowEditInstitutionsTypes &&
            typeof permissionsAllowEditInstitutionsTypes === 'string' &&
            permissionsAllowEditInstitutionsTypes.length > 0 &&
            permissionsAllowEditInstitutionsTypes !== 'all'
        ) {
            const allowedTypes: string[] = permissionsAllowEditInstitutionsTypes.split('|');
            const editAllowed = allowedTypes.some((allowedType: string) => this.data && allowedType === this.data.type1);

            // Berechtigung überschreiben
            this.allowEditInstitution = permissionAllowEditInstitution && editAllowed;
            this.allowEditShadowInstitution = permissionAllowEditShadowInstitution && editAllowed;
        }

        // Berechtigung "allowEditForeignInstitution" prüfen
        const permissionAllowEditForeignInstitution: boolean =
            this.userPermissions.getPermissionValue('allowEditForeignInstitution');
        this.allowEditForeignInstitution = permissionAllowEditForeignInstitution;

        this.allowEditCombined =
            this.allowEditInstitution &&
            (this.allowEditForeignInstitution || this.institutionsService.institutionIsInOwnRegion);

        this.allowEditShadowCombined =
            this.allowEditShadowInstitution &&
            (this.allowEditForeignInstitution || this.institutionsService.institutionIsInOwnRegion);
    }

    /**
     * Berechtigung "allowEditInstitutionErpNumber" prüfen
     */
    checkAllowEditInstitutionErpNumber(): void {
        const permissionAllowEditInstitutionErpNumber: boolean = this.userPermissions.getPermissionValue(
            'allowEditInstitutionErpNumber',
        );
        this.allowEditInstitutionErpNumber = permissionAllowEditInstitutionErpNumber;
    }

    /**
     * Berechtigung "allowEditInstitution" prüfen
     */
    checkAllowManualRegionAllocations(): void {
        const permissionAllowManualRegionAllocations: boolean =
            this.userPermissions.getPermissionValue('allowManualRegionAllocations');
        this.allowManualRegionAllocations = permissionAllowManualRegionAllocations;
    }

    /**
     * @author  Eric Häußel <e.haeusel@pharmakon.software>
     */
    checkEdetailerLink(): void {
        const showRemoteEdetailerLinks: boolean = this.userPermissions.getPermissionValue('showRemoteEdetailerLinks');
        // Einstellung aus Environment übernehmen
        if (Object.prototype.hasOwnProperty.call(environment, 'showRemoteEdetailerLinks') && showRemoteEdetailerLinks) {
            this.showEdetailerLinks = environment['showEdetailerLinks'];
        }
    }

    /**
     * Auf geänderte Auswahl reagieren
     * @param id
     */
    onSelectionChanged(id: number): void {
        // ID der aktuellen Person merken
        this.institutionId = id;
        // Falls Formular bereits zuvor initialisiert war...
        if (this.institutionsDataForm) {
            // ...wird es zurückgesetzt
            this.institutionsDataForm.resetForm();
        }
        // Daten für neu ausgewählten Datensatz laden
        this.loadData();

        // Flag "timestampsVisible" deaktivieren
        this.timestampsVisible = false;

        if (this.showEdetailerLinks) {
            if (this.data != undefined && (this.data['mail'] == '' || this.data['mail'] == undefined)) {
                this.disableEdetailerLinks = {disabled: true};
            }
        }
    }

    /**
     * Daten laden
     */
    loadData(): void {
        // Flag "loading" aktivieren
        this.loading = true;

        const serviceRequest$ = this.institutionsDataService.loadData(this.institutionId);
        serviceRequest$.subscribe((result: CWResult) => {
            /**
             * Prüfe, ob die Daten des eintreffenden Requests auch
             * zur aktuell ausgewählten Einrichtung passen. Durch
             * asynchrone Abfragen kann es nämlich passieren, dass
             * zwischenzeitlich bereits die Einrichtung gewechselt wurde
             * und die Antwort eines Requests verspätet eintrifft und
             * dadurch die korrekten Daten wieder überschreibt.
             */
            if (this.institutionsService.selectedInstitution && result.data) {
                if (this.institutionsService.selectedInstitution.id != result.data['id']) {
                    return;
                }
            }

            // Prüfe ob ein Eintrag angelegt werden soll (ID < 0 Leer-Eintrag, kein neuer Eintrag gewünscht)
            if (result.success && result.data) {
                // Geladene Daten als <Institution> in Component speichern
                this.data = Object.assign(new Institution(), result.data);

                this.dataNotification = new Notification();
                /*
                 * Kopie der Daten als "originalData" speichern
                 * this.originalData = Object.assign({}, this.data);
                 */
                this.originalData = <Institution>JSON.parse(JSON.stringify(this.data));

                // Anzeigenamen der Gebiete zusammenbauen
                this.prepareRegionLabels();

                // Falls es sich um einen Neueintrag (id = 0) handelt...
                if (this.data && this.data['id'] == 0) {
                    // ...wird direkt EditMode aktiviert
                    this.editMode = true;
                } else {
                    // ...ansonsten startet das Modul immer in der normalen Ansicht
                    this.editMode = false;
                }
            } else if (this.institutionsService.selectedInstitution !== null) {
                this.institutionsService.selectEmptyInstitution();
            }

            // Prüfen, ob die ausgewählte Einrichtung zum eigenen Gebiet gehört
            const promise = this.institutionsService.checkInstitutionOwnRegion(
                this.data,
                this.allowEditInstitution,
                this.allowEditForeignInstitution,
                this.allowEditCombined,
            );
            promise.then(() => {
                // Berechtigung "allowEditInstitution" und "allowEditInstitutionByTypes" prüfen
                this.checkAllowEditInstitution();
            });

            // Mail prüfen
            this.checkIfInstitutionHasMail();

            // Optionen für Select laden
            this.getInputSelectOptions();

            // Prüfen, ob es sich um eine Neuanlage einer Abteilung handelt
            this.checkIfInstitutionHasParentId();

            // Hierarchie-Daten für andere Komponenten ankündigen
            if (
                this.data &&
                Object.prototype.hasOwnProperty.call(this.data, 'current_hierarchy_level') &&
                Object.prototype.hasOwnProperty.call(this.data, 'max_hierarchy_level')
            ) {
                this.institutionsDataService.dataChanged(this.data);
            }

            // Flag "loading" deaktivieren
            this.loading = false;
        });
    }

    /**
     * Prüft bei Neuanlage, ob eine übergeordnete Einrichtung existiert und setzt entsprechend Werte
     * @author d.nita@pharmakon.software
     */
    checkIfInstitutionHasParentId(): void {
        if (
            this.data.id === 0 &&
            this.institutionsService.selectedInstitution !== null &&
            Object.prototype.hasOwnProperty.call(this.institutionsService.selectedInstitution, 'parent_id') &&
            this.institutionsService.selectedInstitution.parent_id !== null
        ) {
            this.data.street = this.institutionsService.selectedInstitution.street;
            this.data.city = this.institutionsService.selectedInstitution.city;
            this.data.zipcode = this.institutionsService.selectedInstitution.zipcode;
            this.data.district = this.institutionsService.selectedInstitution.district;

            // Standard-Wert für Abteilungsschlüssel aus Environment laden
            if (Object.prototype.hasOwnProperty.call(environment, 'hierarchyDepartmentKey')) {
                this.data.type1 = environment.hierarchyDepartmentKey;
            } else {
                this.data.type1 = 'department';
            }

            // Objekt initialisieren zur Übergabe
            const pseudoEvent = {
                id: this.institutionsService.selectedInstitution.parent_id,
                label: '',
            };
            this.updateParentInstitutionData(pseudoEvent);
        }
    }

    /**
     * @author  Eric Häußel <e.haeusel@pharmakon.software>
     */
    checkIfInstitutionHasMail(): void {
        if (this.showEdetailerLinks) {
            if (this.data != undefined && (this.data['mail'] == '' || this.data['mail'] == undefined)) {
                this.disableEdetailerLinks = {disabled: true};
            } else {
                this.disableEdetailerLinks = {};
            }
        }
    }

    /**
     * @brief   Optionen aller Selects laden
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    getInputSelectOptions(): void {
        this.getListentries('institutionType1', 'institutionType1Options', this.datafieldsConfiguration.type1.required);
        this.getListentries('institutionType2', 'institutionType2Options', this.datafieldsConfiguration.type2.required);
    }

    /**
     * @param listentries
     * @param optionsName
     * @param requiredField
     * @brief   Listentries laden
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    getListentries(listentries: string, optionsName: string, requiredField = false): void {
        // Falls der Name einer Liste hinterlegt wurde
        if (listentries != '') {
            // Daten über Service anfordern
            const promise = this.storageService.getItem('listentries|' + listentries);
            // let promise = this.storageService.getItemSlowly('listentries|' + listentries);
            promise.then((val) => this.buildOptionsFromListentries(val, optionsName, requiredField));
        }
    }

    /**
     * @param listentries
     * @param optionsName
     * @param requiredField
     * @brief   Optionen für Input-Select zusammenbauen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    buildOptionsFromListentries(listentries: Listentry[], optionsName: string, requiredField = false): void {
        // Initialisierung dieser Komponente war schneller als das Laden der Listentries
        if (listentries == null || typeof this.data === 'undefined') {
            return;
        }

        // Hierarchie-Level der Einrichtung prüfen
        if (this.data.parent.length === 0) {
            this.data['hierarchyLevel'] = 1;
        } else {
            this.data['hierarchyLevel'] = 2;
        }

        /*
         * Leereinträge bei Pflichtfeldern verschwinden lassen
         * überprüft die variable "personFieldIsMandatory" in der environment
         * true-> keine leereinträge
         */
        let tempOptions: SelectData[] = [{
            id: 0,
            label: '',
            data: '',
        }];
        if (requiredField) {
            tempOptions = [];
        }

        const tmpOption: SelectData = {
            id: 0,
            label: '',
            data: '',
        };

        // Daten prüfen
        if (listentries) {
            // Werte der Liste (list_key & list_value) in Select-Optionen (id & label) übernehmen
            for (const entry of listentries) {
                tmpOption.id = entry['list_key'];
                tmpOption.label = entry['list_value'];
                // Wenn es list.data gibt diese zu einem JSON-Parsen und ebenfalls speichern.
                if (entry['list_data'] !== '' && entry['list_data'] !== null) {
                    tmpOption.data = JSON.parse(entry['list_data']);
                } else {
                    // Wenn der List_data-Eintrag leer ist, muss die data der tmpOption auf null gesetzt werden. Kein JSON.parse um Fehler zu vermeiden.
                    tmpOption.data = null;
                }

                // Wenn die entsprechende Hierarchie gesetzt ist oder keine Einschränkung besteht, die Option anhängen
                if (this.checkSelectboxListentryConfiguration(tmpOption, 'hierarchyLevel')) {
                    // Typ2 abhängig von Typ1 laden
                    if (
                        optionsName === 'institutionType2Options' &&
                        this.checkSelectboxListentryConfiguration(tmpOption, 'type1') === false
                    ) {
                        continue;
                    }
                    tempOptions.push({...tmpOption});
                }
            }
        }

        // Nur wenn Auswahl sich geändert hat, Auswahl überschreiben
        if (JSON.stringify(tempOptions) != JSON.stringify(this[optionsName])) {
            this[optionsName] = tempOptions;
        }
    }

    /**
     * @param tmpOption
     * @param relationType
     * @brief   Selectbox-Bedingungen prüfen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    checkSelectboxListentryConfiguration(tmpOption: any, relationType: string): boolean {
        // Feature deaktiviert -> Option anhängen
        if (this.hierarchyRelatedSelectboxEnabled === false && this.institutionTypeRelatedSelectboxEnabled === false) {
            return true;
        }

        // Keine Daten -> Option anhängen
        if (
            tmpOption.data === null ||
            typeof tmpOption.data === 'undefined' ||
            this.data[relationType] == '0' ||
            this.data[relationType] === null ||
            typeof this.data[relationType] === 'undefined'
        ) {
            return true;
        }

        // Konfiguration nicht vorhanden oder übereinstimmend -> Option anhängen
        if (!tmpOption.data[relationType] || tmpOption.data[relationType] === Number(this.data[relationType])) {
            return true;
        }

        // Konfiguration als Array vorhanden und Element in Array gefunden -> Option anhängen
        if (
            Array.isArray(tmpOption.data[relationType]) &&
            tmpOption.data[relationType].some((element: any) => element === Number(this.data[relationType]))
        ) {
            return true;
        }

        // Sonst nicht anhängen
        return false;
    }

    /**
     * Speichert die Änderungsanfrage
     * @param deleteRequest ob D als validation process gesetzt werden soll
     */
    clickShadowSubmit(deleteRequest = false) {
        if (this.institutionsDataForm.form.valid) {
            const formValues = JSON.parse(JSON.stringify(this.institutionsDataForm.form.value));
            formValues.isDeleteRequest = deleteRequest;
            if (hasOwn(this.originalData, 'id')) {
                formValues.id = this.originalData.id;
            }
            // Submit der Formular-Daten über InstitutionsDataService
            const serviceRequest$ = this.institutionsDataService.saveShadowData(formValues);
            serviceRequest$.subscribe((result: CWResult) => {
                // Dialog konfigurieren und öffnen
                const dialogRef = this.dialog.open(PopupMessageComponent, {
                    width: '350px',
                    data: {
                        title: 'Anfrage zur Änderung wurde gestellt',
                        message: 'Der aktuelle Status kann im Bericht der Änderungsanfragen abgerufen werden.',
                    },
                });
                // Auf das Schließen des Dialogs reagieren
                dialogRef.afterClosed().subscribe((result) => {
                    this.institutionsService.isShadow = false;
                    this.clickCancel();
                });
            });
        }
    }

    /**
     * Klick auf "Speichern" (Form-Submit)
     * @param confirmation
     */
    clickSubmit(confirmation = false): void {
        // Nur gültige Formulare werden submitted
        if (this.institutionsDataForm.form.valid) {
            // Flag "saving" aktivieren
            this.saving = true;
            // Neue Variable, damit manuell Änderungen hinzugefügt werden können.
            const formValues = JSON.parse(JSON.stringify(this.institutionsDataForm.form.value));
            // Wenn die Parent-Zuordnung geändert werden soll, setze die Daten
            if (this.parentChanged && this.institutionId > 0) {
                // Prüfe, ob das Speichern schon bestätigt wurde
                if (confirmation === false) {
                    // Wenn nicht, öffne den Bestätigungs-Dialog
                    this.openChangeParentDialog();
                    return;
                }
                formValues.parent = this.data.parent;
            } else if (this.parentChanged && this.institutionId === 0) {
                formValues.parent = this.data.parent;
            }

            formValues.Notifications = this.dataNotification;

            // Submit der Formular-Daten über InstitutionsDataService
            const serviceRequest$ = this.institutionsDataService.saveData(this.institutionId, formValues);
            serviceRequest$.subscribe((result: CWResult) => {
                // Falls es sich um eine Neuanlage handelte...
                if (this.data['id'] == 0) {
                    // ...wird die neue ID übernommen
                    this.data['id'] = result['data']['id'];
                    this.institutionId = result['data']['id'];
                    // Router-Link zusammenbauen
                    const routerLink: string = '/institutions/' + this.institutionId;
                    // Route-Navigation ausführen
                    this.router.navigate([routerLink]);
                    // E-Liste neu laden
                    this.gridService.reloadGridData('institutionsList');
                }

                // Falls sich die Segment-Zuordnungen geändert haben...
                if (
                    Object.prototype.hasOwnProperty.call(result.data, 'segments_changed') &&
                    result.data.segments_changed === true
                ) {
                    // ...E-Liste neu laden
                    this.gridService.reloadGridData('institutionsList');
                }

                // Geladene Daten als <Institution> in Component speichern
                this.data = Object.assign(new Institution(), result.data);

                // Wenn sich die Eltern-Daten geändert haben...
                if (this.parentChanged === true) {
                    // E-Liste neu laden
                    this.gridService.reloadGridData('institutionsList');

                    // Daten neu Laden. Da die Response nicht die Gebietsdaten wiederpspiegelt
                    this.editMode = false;
                    // Parent-Flag zurücksetzen
                    this.parentChanged = false;
                    // Flag "saving" deaktivieren
                    this.saving = false;

                    if (this.institutionsService.selectedInstitution.id === 0) {
                        this.institutionsService.selectInstitution(result.data);
                    } else {
                        this.loadData();
                    }
                    return;
                }

                // Icons überschreiben
                if (
                    result.data &&
                    Object.prototype.hasOwnProperty.call(result.data, 'icons') &&
                    result.data.icons !== this.data.icons
                ) {
                    this.data.icons = result.data.icons;
                    this.institutionsService.updateInstitutionIcons(result.data.icons);
                }

                // Daten wurden erfolgreich geändert --> Event über Service auslösen
                this.institutionsDataService.dataChanged(this.data);
                // Kopie der geänderten Daten als neue "originalData" speichern
                this.originalData = <Institution>JSON.parse(JSON.stringify(this.data));

                // nach erfolgreichen Speichern, regions label neusetzen
                this.prepareRegionLabels();

                // EditMode verlassen
                this.editMode = false;
                // Parent-Flag zurücksetzen
                this.parentChanged = false;
                // Flag "saving" deaktivieren
                this.saving = false;
            });
        }
    }

    /**
     * Klick auf "Abbrechen"
     */
    clickCancel(): void {
        /*
         * Da Cancel doch nicht so funktioniert wie gedacht, hier die schnelle Variante mit eigener Datenkopie vor Benutzeränderungen
         * this.data= Object.assign({}, this.originalData);
         */
        this.data = <Institution>JSON.parse(JSON.stringify(this.originalData));

        /*
         * Daten wurden geändert --> Event über Service auslösen
         * this.institutionsDataService.dataChanged(this.data);
         */

        // Parent institution nicht verändert
        this.parentChanged = false;

        // immer schattenmodus beenden
        this.institutionsService.isShadow = false;

        // EditMode verlassen
        this.editMode = false;
    }

    /**
     * @brief   Zeitstempel ein-/ausblenden
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    toggleTimestamps(): void {
        this.timestampsVisible = !this.timestampsVisible;
    }

    /**
     * @brief   Berechtigung "allowMergeEntities" prüfen
     */
    checkAllowMergeEntities(): void {
        const permissionAllowMergeEntities: boolean = this.userPermissions.getPermissionValue('allowMergeEntities');
        this.allowMergeEntities = permissionAllowMergeEntities && environment.enableClearingMergeInstitutions;
    }

    /**
     * Berechtigung "enableModuleClearing" prüfen
     */
    checkEnableModuleClearing(): void {
        const permissionEnableModuleClearing: boolean = this.userPermissions.getPermissionValue('enableModuleClearing');
        this.clearingButtonVisible = permissionEnableModuleClearing;
    }

    /**
     * @brief   Klick in der Toolbar simulieren, um Prüfung auf Duplikate auszulösen und Ladeanimation zu starten
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initiateDuplicatesCheck() {
        if (this.data.name1) {
            this.toolbarService.searchDuplicate(this.myself, {action: 'SearchDuplicate'});
        }
    }

    /**
     * @param entityId
     * @param existingData
     * @brief   Event senden um Prüfung auf Duplikate auszulösen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    checkForDuplicates(entityId: number, existingData = false): void {
        // Prüfe ob es sich um eine Neuanlage handelt und eine Eingabe getätigt wurde
        if (
            (existingData || this.data.id === 0) &&
            this.data.name1 &&
            (this.data.name1.length > 0 || this.data.name2.length > 0 || this.data.city.length > 0)
        ) {
            // Flag setzen
            this.checkingDuplicates = true;

            // Daten übergeben
            const formData = {
                table: 'institutions',
                name1: this.data.name1,
                name2: this.data.name2,
                city: this.data.city,
                street: this.data.street,
                entityId,
            };

            // Event auslösen
            this.clearingDuplicatesPanelService.checkForDuplicates(entityId, formData);
        }
    }

    /**
     * In Toolbar wurde auf "Google Maps" geklickt.
     * Die Adresse der Einrichtung mit Google Maps in neuem Tab öffnen.
     */
    showGoogleMaps(): void {
        let url = 'https://www.google.de/maps/place/';
        url = url + this.data.street + ' ' + this.data.zipcode + ' ' + this.data.city;
        window.open(url, '_blank');
    }

    /**
     * In Toolbar wurde auf "Parkopedia" geklickt.
     * Die Adresse der Einrichtung mit Parkopedia in neuem Tab öffnen.
     */
    showParkopedia(): void {
        let url = 'http://www.parkopedia.de/parken/';
        url = url + this.data.street + ' ' + this.data.zipcode + ' ' + this.data.city;
        window.open(url, '_blank');
    }

    /**
     * Berechtigung "allowMoveInstitution" prüfen
     */
    checkAllowMoveInstititution(): void {
        const permissionAllowMoveInstitution: boolean = this.userPermissions.getPermissionValue('allowMoveInstitution');
        this.allowMoveInstitution = permissionAllowMoveInstitution;

        // Setze außerdem das Flag, ob sich die Parent-Einrichtung geändert hat zurück
        this.parentChanged = false;
    }

    /**
     * Updated die Zugeordnete Eltern-Einrichtung
     * @param event
     */
    updateParentInstitutionData(event: any): void {
        if (this.data.parent !== undefined) {
            delete this.data.parent;
        }
        this.data.parent = [new ParentInstitution(event.id)];
        this.data.parent[0]['label'] = event.label;
        this.parentChanged = true;
    }

    /**
     * Lösche die Zugeordnete Parent-Institution
     */
    deleteParentInstitutionData(): void {
        if (this.data.parent !== undefined) {
            this.data.parent = [];
            this.parentChanged = true;
        }
    }

    /**
     * Exportdialog öffnen
     */
    openChangeParentDialog(): void {
        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant('MODULES.INSTITUTIONS.DATA.MOVEINSTITUTION'),
                message: this.translateService.instant('MODULES.INSTITUTIONS.DATA.MOVEINSTITUTIONQUESTION'),
            },
        });
        // Auf das Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe((result) => {
            this.confirmChangeParent(result.answer);
        });
    }

    /**
     * Auf die Antwort dese Dialogs warten
     * @param
     * @param answer
     */
    confirmChangeParent(answer: string): void {
        if (answer == 'yes') {
            this.clickSubmit(true);
        } else {
            // Flag "saving" deaktivieren
            this.saving = false;
        }
    }

    /**
     * @param event
     * @brief   Sonder-Gebietszuordnung zurücksetzen
     * @author  Olga Salomatina <o.salomatina@pharmakon.software>
     */
    resetRegionData(event: any): void {
        if (event !== null && Object.prototype.hasOwnProperty.call(event, 'index') && event.index == 0) {
            // Leere Region anhängen
            const newRegion = [];
            newRegion['region_id'] = 0;
            newRegion['id'] = 0;
            newRegion['checked'] = 'false';
            newRegion['label'] = '';
            this.data.manualregions[event.index] = newRegion;

            /*
             * Element am angegebenen Index löschen
             * this.data.regions.splice(event.index, 1);
             */
        }
    }

    /**
     * @brief   Baut den initialen Wert für die Autocomplete-Komponente der Ortsauswahl zusammen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    getZipcodeCityCombinationData(): string {
        // Init
        let returnValue = '';

        // Wert prüfen
        if (this.isUndefinedPipe.transform(this.data.zipcode) === false) {
            returnValue = this.data.zipcode;

            // nur ein Leerzeichen einfügen, wenn Werte vorhanden sind
            if (returnValue.length > 0) {
                returnValue += ' ';
            }

            returnValue += this.data.city;
        }

        // Wert zurückgeben
        return returnValue;
    }

    /**
     * @param selectedValue
     * @brief   Ausgewählte PLZ/Stadt Kombination in die entsprechenden Felder speichern
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    setZipcodeCityCombinationData(selectedValue: LooseObject): void {
        // String an Leerzeichen aufteilen
        const values = selectedValue.label.split(' ');

        // erstes Element vom Array entfernen und als PLZ speichern
        this.data.zipcode = values.shift();
        // die verbliebenen Elemente wieder mit Leerzeichen verbinden und als Ort speichern
        this.data.city = values.join(' ');
    }

    /**
     * @brief   Gebietsnamen zusammenbauen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    prepareRegionLabels(): void {
        // Prüfen, ob Daten vorhanden sind
        if (Object.prototype.hasOwnProperty.call(this.data, 'regions') === false || this.data.regions.length <= 0) {
            return;
        }

        // Für jede Region die Werte zusammensetzen
        this.data.regions.forEach((region: LooseObject, index: number) => {
            // Wert initialisieren
            this.data.regions[index]['templateLabel'] = '';

            // Für jedes definierte Feld den entsprechenden Wert anhängen
            this.regionAssignmentLabel.forEach((field: string) => {
                // Werte mit Leerzeichen trennen
                if (this.data.regions[index]['templateLabel'].length > 0) {
                    this.data.regions[index]['templateLabel'] += ' ';
                }
                this.data.regions[index]['templateLabel'] += region[field];
            });
        });
    }

    /**
     * Laden der benötigten Datenbankstruktur
     */
    private loadDatabaseSchema() {
        const institutionsSchema = this.storageService.getItem('InstitutionsSchema');
        institutionsSchema.then((value) => {
            if (value) {
                this.institutionsSchema = value;
            }
        });
    }

    // wrapper das im html direkt es benutzt werden kann
    public notEmptyLocal(str) {
        return notEmpty(str);
    }
}
