import {Directive, Input, ViewContainerRef, OnInit, Type} from '@angular/core';
import {componentMappings, GridCellRenderer} from '@shared/grid-new/grid-new-config';
import {COLUMN_COMPONENT, COLUMN_PLAIN, TableColumn, TableColumnMode} from '@shared/table-column';

/**
 * Direktive, die eine Zelle in einem Grid dynamisch mit einem bestimmten Komponententyp
 * füllt, abhängig von der Konfiguration der Spalte.
 *
 * Die Direktive wird im Template der Grid-Zelle verwendet und erhält als Input die
 * Konfiguration der Spalte und die Daten der Zeile.
 * @example
 * <td phscwGridNewDynamicCell [column]="column" [row]="row"></td>
 */
@Directive({selector: '[phscwGridNewDynamicCell]'})
export class GridNewDynamicCellDirective<T> implements OnInit {
    @Input({required: true}) column: TableColumn<T>;
    // Info: row kommt trotzdem als "any" weil Material das aktuell nicht unterstützt
    @Input({required: true}) row: T;

    constructor(public viewContainerRef: ViewContainerRef) {}

    ngOnInit() {
        this.loadComponent();
    }

    /**
     * Lädt die Komponente dynamisch, die für die Zelle verwendet werden soll.
     */
    private loadComponent(): void {
        // Komponententyp bestimmen anhand der Spalten-Konfiguration
        const componentType = this.getComponentType(this.column.mode);

        // Komponente erstellen
        const componentRef = this.viewContainerRef.createComponent<GridCellRenderer<unknown>>(componentType);

        /*
         * Übergebene Daten an die erstellte Komponente senden
         * Durch das GridCellRenderer interface wird sichergestellt, dass die variablen existieren
         */
        componentRef.instance.value = this.column.cell(this.row);
        componentRef.instance.column = this.column;
    }

    /**
     * Gibt den Typ der Komponente zurück, die für die Zelle verwendet werden soll,
     * abhängig von der Spalten-Konfiguration.
     *
     * Wenn der Typ nicht gefunden wird, wird der Standard-Typ verwendet.
     * @param {TableColumnMode} mode Spalten-Modus
     * @returns {Type<unknown>} Typ der Komponente
     */
    private getComponentType(mode: string): Type<GridCellRenderer<unknown>> {
        if (mode === COLUMN_COMPONENT && this.column.component) {
            return this.column.component;
        }
        const component = componentMappings[mode];
        if (!component) {
            // eslint-disable-next-line no-console
            console.warn(`Column mode '${mode}' not found. Using default component.`);
            return componentMappings[COLUMN_PLAIN];
        }
        return component;
    }
}
