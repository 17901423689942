// Angular-Module
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
// Komponenten
import {EntityIconsComponent} from './entity-icons/entity-icons.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {SingleIconRendererComponent} from './single-icon-renderer/single-icon-renderer.component';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
    imports: [CommonModule, FontAwesomeModule, MatTooltipModule],
    declarations: [EntityIconsComponent, SingleIconRendererComponent],
    exports: [EntityIconsComponent, SingleIconRendererComponent],
})
export class EntityIconsModule {}
