// Angular-Module
import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// Angular-Material
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// Interfaces für Structured Objects einbinden
import {Characteristic} from '@shared/characteristic';
import {SamplesProduct} from '@shared/samples-product';
import {SelectData} from '@shared/select-data';
import {SelectionFilter} from '@shared/selection-filter';
// Globale Services einbinden
import {StorageService} from '@global/services/storage.service';
// Eigenen Service einbinden
import {GridSelectionService} from './../grid-selection.service';
// Services anderer Shared-Modules
import {InputDateService} from './../../../input/input-date/input-date.service';
// Moment-Modul zur Datums-Verarbeitung
import * as _moment from 'moment';

const moment = _moment;

@Component({
    selector: 'phscw-grid-selection-popup',
    templateUrl: './grid-selection-popup.component.html',
    styleUrls: ['./grid-selection-popup.component.scss'],
})
export class GridSelectionPopupComponent implements OnInit, OnDestroy {
    availableComparisons: SelectData[] = [];
    responsible_employee_fullname = '';
    characteristicComparisons: SelectData[] = [
        {
            id: 'equals',
            label: 'ist',
        },
        {
            id: 'contains',
            label: 'enthält',
        },
        {
            id: 'starts with',
            label: 'beginnt mit',
        },
        {
            id: 'smaller than',
            label: 'ist kleiner als',
        },
        {
            id: 'greater than',
            label: 'ist größer als',
        },
        {
            id: 'between',
            label: 'liegt zwischen',
        },
        {
            id: 'NOT equals',
            label: 'ist NICHT',
        },
        {
            id: 'is null',
            label: 'ist leer',
        },
        {
            id: 'is NOT null',
            label: 'ist NICHT leer',
        },
    ];

    dateComparisons: SelectData[] = [
        {
            id: 'equals',
            label: 'ist',
        },
        {
            id: 'NOT equals',
            label: 'ist NICHT',
        },
        {
            id: 'is before',
            label: 'liegt vor dem',
        },
        {
            id: 'is after',
            label: 'liegt nach dem',
        },
        {
            id: 'between',
            label: 'liegt zwischen',
        },
    ];

    listComparisons: SelectData[] = [
        {
            id: 'equals',
            label: 'ist',
        },
        {
            id: 'NOT equals',
            label: 'ist NICHT',
        },
    ];

    multiselectComparisons: SelectData[] = [
        {
            id: 'equals',
            label: 'ist',
        },
        {
            id: 'NOT equals',
            label: 'ist NICHT',
        },
    ];

    regionsMultiSelectComparisons: SelectData[] = [{
        id: 'in',
        label: 'zugeordnet zu',
    }];

    numberComparisons: SelectData[] = [
        {
            id: 'equals',
            label: 'ist',
        },
        {
            id: 'NOT equals',
            label: 'ist NICHT',
        },
        {
            id: 'smaller than',
            label: 'ist kleiner als',
        },
        {
            id: 'greater than',
            label: 'ist größer als',
        },
        {
            id: 'between',
            label: 'liegt zwischen',
        },
    ];

    zipcodeComparisons: SelectData[] = [
        {
            id: 'equals',
            label: 'ist',
        },
        {
            id: 'NOT equals',
            label: 'ist NICHT',
        },
        {
            id: 'starts with',
            label: 'beginnt mit',
        },
        {
            id: 'NOT starts with',
            label: 'beginnt NICHT mit',
        },
        {
            id: 'between',
            label: 'liegt zwischen',
        },
    ];

    stringComparisons: SelectData[] = [
        {
            id: 'equals',
            label: 'ist',
        },
        {
            id: 'NOT equals',
            label: 'ist NICHT',
        },
        {
            id: 'contains',
            label: 'enthält',
        },
        {
            id: 'NOT contains',
            label: 'enthält NICHT',
        },
        {
            id: 'starts with',
            label: 'beginnt mit',
        },
        {
            id: 'NOT starts with',
            label: 'beginnt NICHT mit',
        },
    ];

    conversationTopicsComparisons: SelectData[] = [{
        id: 'equals',
        label: 'ist',
    }];

    allComparisons = this.dateComparisons.concat(
        this.listComparisons,
        this.numberComparisons,
        this.zipcodeComparisons,
        this.stringComparisons,
        this.regionsMultiSelectComparisons,
        this.conversationTopicsComparisons,
        this.characteristicComparisons,
    );

    // Welche Eingabefelder werden angezeigt?
    showInputDate = false;
    showInputSelect = false;
    showInputText = false;
    showInputMultiselect = false;
    showInputAutocomplete = false;
    // Nach welchem listentry wird gefiltert?
    chosenListentry = '';
    // Wurde ein vorhandener Filter geändert?
    reopenedFilter = false;
    // Gewählter Vergleich
    comparison: any = '';
    // Wert der im Frontend angezeigt wird
    frontendValue: any[] = [];
    // Wert aus der Selection-Checkbox für Listentries => wird mom. nicht genutzt
    listkey = '';
    // ID des gewählten Kennzeichens
    characteristic_id = 0;
    // Verfügbare Kennzeichen (abhängig von gewählter Kennzeichengruppe)
    availableCharacteristics: Characteristic[] = [];
    // Gewähltes Kennzeichen
    characteristic: Characteristic = {
        id: 0,
        label: '',
    };

    // ID des gewählten Besprechungsproduktes
    product_id = 0;
    // Verfügbare Besprechungsprodukte
    availableConversationTopicsProduct: SamplesProduct[] = [];
    // ID der ausgewählten Kennzeichengruppe
    characteristic_group_id = 0;
    // Auswählbare Kennzeichengruppen
    characteristics_groups: any[] = [];
    // Wurde ein Kennzeichen gewählt, nach welchem gefiltert werden soll?
    characteristicChosen = false;
    // Verfügbare Optionen für gewähltes Kennzeichen
    availableOptions: any[] = [];
    // Gewählte Kennzeichenoption(en)
    option: any = '';
    multiOptions: any[] = [];
    // Button "Filteroptionen anzeigen" ausblenden, wenn Daten geladen werden
    loading = false;
    filterDataSent = false;
    filterData: any = '';
    importantCharacteristic = false;
    // Objekt um Daten eines im autocomplete gewählten Mitarbeiters zu speichern
    responsible_employee: any = {
        id: 0,
        label: '',
    };

    // Referenz auf Form
    @ViewChild('createFilterForm', {static: true}) createFilterForm: NgForm;

    constructor(
        // eslint-disable-next-line new-cap
        @Inject(MAT_DIALOG_DATA) public data: any,
        private gridSelectionService: GridSelectionService,
        public dialogRef: MatDialogRef<GridSelectionPopupComponent>,
        private inputDateService: InputDateService,
        private storageService: StorageService,
        private translateService: TranslateService,
    ) {}

    // Initialisierungen
    ngOnInit() {
        // Übersetzungen subscriben
        this.initializeTranslations();

        this.initData();
    }

    // Aufräumen
    ngOnDestroy() {
        return undefined;
    }

    /**
     * @brief   Übersetzungen laden
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeTranslations() {
        // Übersetzungen laden
        const translations = {
            equals: this.translateService.instant('SHARED.GRID.SELECTION.POPUP.EQUALS'),
            equalsnot: this.translateService.instant('SHARED.GRID.SELECTION.POPUP.EQUALSNOT'),
            before: this.translateService.instant('SHARED.GRID.SELECTION.POPUP.BEFORE'),
            after: this.translateService.instant('SHARED.GRID.SELECTION.POPUP.AFTER'),
            between: this.translateService.instant('SHARED.GRID.SELECTION.POPUP.BETWEEN'),
            smaller: this.translateService.instant('SHARED.GRID.SELECTION.POPUP.SMALLERTHAN'),
            greater: this.translateService.instant('SHARED.GRID.SELECTION.POPUP.GREATERTHAN'),
            starts: this.translateService.instant('SHARED.GRID.SELECTION.POPUP.STARTSWITH'),
            startsnot: this.translateService.instant('SHARED.GRID.SELECTION.POPUP.STARTSWITHNOT'),
            contains: this.translateService.instant('SHARED.GRID.SELECTION.POPUP.CONTAINS'),
            containsnot: this.translateService.instant('SHARED.GRID.SELECTION.POPUP.CONTAINSNOT'),
            isNull: this.translateService.instant('SHARED.GRID.SELECTION.POPUP.ISNULL'),
            isNotNull: this.translateService.instant('SHARED.GRID.SELECTION.POPUP.ISNOTNULL'),
        };

        // Übersetzungen zuweisen
        this.dateComparisons[0].label = translations.equals;
        this.dateComparisons[1].label = translations.equalsnot;
        this.dateComparisons[2].label = translations.before;
        this.dateComparisons[3].label = translations.after;
        this.dateComparisons[4].label = translations.between;

        this.listComparisons[0].label = translations.equals;
        this.listComparisons[1].label = translations.equalsnot;

        this.multiselectComparisons[0].label = translations.equals;
        this.multiselectComparisons[1].label = translations.equalsnot;

        this.numberComparisons[0].label = translations.equals;
        this.numberComparisons[1].label = translations.equalsnot;
        this.numberComparisons[2].label = translations.smaller;
        this.numberComparisons[3].label = translations.greater;
        this.numberComparisons[4].label = translations.between;

        this.zipcodeComparisons[0].label = translations.equals;
        this.zipcodeComparisons[1].label = translations.equalsnot;
        this.zipcodeComparisons[2].label = translations.starts;
        this.zipcodeComparisons[3].label = translations.startsnot;
        this.zipcodeComparisons[4].label = translations.between;

        this.stringComparisons[0].label = translations.equals;
        this.stringComparisons[1].label = translations.equalsnot;
        this.stringComparisons[2].label = translations.contains;
        this.stringComparisons[3].label = translations.containsnot;
        this.stringComparisons[4].label = translations.starts;
        this.stringComparisons[5].label = translations.startsnot;

        this.characteristicComparisons[0].label = translations.equals;
        this.characteristicComparisons[1].label = translations.contains;
        this.characteristicComparisons[2].label = translations.starts;
        this.characteristicComparisons[3].label = translations.smaller;
        this.characteristicComparisons[4].label = translations.greater;
        this.characteristicComparisons[5].label = translations.between;
        this.characteristicComparisons[6].label = translations.equalsnot;
        this.characteristicComparisons[7].label = translations.isNull;
        this.characteristicComparisons[8].label = translations.isNotNull;

        this.conversationTopicsComparisons[0].label = translations.equals;
    }

    initData() {
        // Alte Daten löschen und neue setzen
        this.filterData = {};
        this.filterData = this.data['chosenFilter'];

        // Falls ein Filter geändert wird, setze die bisherigen Eingaben
        if (this.filterData['frontendValue'] !== undefined) {
            this.comparison = this.filterData['comparison'];
            this.frontendValue[0] = this.filterData['frontendValue'][0];
            this.frontendValue[1] = this.filterData['frontendValue'][1];
            this.listkey = this.filterData['listkey'];
            this.option = this.filterData['characteristic_option_id'];
            this.multiOptions = this.filterData['multiOptions'];
            this.characteristic_id = this.filterData['characteristic_id'];
            this.product_id = this.filterData['product_id'];
            this.characteristic_group_id = this.filterData['characteristic_group_id'];
            this.reopenedFilter = true;
        }

        // Wenn nicht nach Kennzeichen gefiltert wird, input-select-"Vergleich" direkt einblenden
        if (
            this.filterData['target'] !== 'characteristics' &&
            this.filterData['target'] !== 'importantCharacteristics'
        ) {
            this.characteristicChosen = true;
        }
        // Wenn nach Besprechungsprodukte gefiltert werden
        if (this.filterData['target'] === 'contact_items' && this.filterData['datatype'] === 'conversationTopics') {
            // Hole die Daten aus dem Storage und speichere sie in die Select-Data-Optionen für den Multi-Input
            this.storageService.getItem('conversationTopics').then((data: any) => {
                // Alle Produkten aus allen Divisionen nehmen
                const productsArrays: any[] = Object.values(data);
                // Array für Ergebnis-Array
                let resultArray: any[] = [];

                // Alle Produkte durchgehen
                productsArrays.forEach((products) => {
                    products.forEach((product) => {
                        // Produkte mit gleichem Id ausfiltern
                        resultArray = resultArray.filter((element) => element.product_id !== product.product_id);
                        resultArray.push(product);
                    });
                });
                this.availableOptions = resultArray;
            });
        }

        // Wenn nach Mustareabgabeprodukten gefiltert werden
        if (this.filterData['target'] === 'view_person_samples' && this.filterData['datatype'] === 'samples') {
            // Hole die Daten aus dem Storage und speichere sie in die Select-Data-Optionen für den Multi-Input
            this.storageService.getItem('sampleProducts').then((data: any) => {
                // Array für Ergebnis-Array
                let resultArray: any[] = [];
                for (const groups of Object.values(data)) {
                    for (const value of Object.values(groups)) {
                        // Alle Produkten aus allen Divisionen nehmen
                        const productsArrays: any[] = Object.values(value['children']);

                        // Alle Produkte durchgehen
                        productsArrays.forEach((product) => {
                            product.id = product.product_id;
                            // Produkte mit gleichem Id ausfiltern
                            resultArray = resultArray.filter((element) => element.product_id !== product.product_id);
                            resultArray.push(product);
                        });
                    }
                }
                this.availableOptions = resultArray;
            });
        }

        // Wenn bestehender Kennzeichenfilter geändert wird, rufe das Kennzeichen und seine Optionen ab
        if (this.filterData['target'] === 'characteristics' && this.reopenedFilter) {
            this.characteristicChosen = true;
            this.storageService
                .getItem('characteristicsForGroup|' + this.characteristic_group_id)
                .then((characteristics) => {
                    this.availableCharacteristics = characteristics;
                    this.checkDatatype();
                    this.getCharacteristicAndOptions();
                });
        }
        // Setze die auswählbaren Kennzeichengruppen anhand der gewählten Kategorie
        if (this.filterData['target'] === 'characteristics' && this.filterData['parentName'] === 'institutions') {
            if (typeof this.filterData['importantCharacteristicsGroupId'] !== 'undefined') {
                this.importantCharacteristic = true;
                const importantCharacteristicGroupIndex = this.data['characteristics_groups_institutions'].findIndex(
                    (obj) => obj['id'] === this.filterData['importantCharacteristicsGroupId'],
                );
                this.characteristic_group_id =
                    this.data['characteristics_groups_institutions'][importantCharacteristicGroupIndex]['id'];
                this.characteristics_groups =
                    this.data['characteristics_groups_institutions'][importantCharacteristicGroupIndex];
                this.storageService
                    .getItem('characteristicsForGroup|' + this.characteristic_group_id)
                    .then((characteristics) => {
                        this.availableCharacteristics = characteristics;
                    });
            } else {
                this.characteristics_groups = this.data['characteristics_groups_institutions'];
            }
        } else if (this.filterData['target'] === 'characteristics' && this.filterData['parentName'] === 'people') {
            if (typeof this.filterData['importantCharacteristicsGroupId'] !== 'undefined') {
                this.importantCharacteristic = true;
                const importantCharacteristicGroupIndex = this.data['characteristics_groups_people'].findIndex(
                    (obj) => obj['id'] === this.filterData['importantCharacteristicsGroupId'],
                );
                this.characteristic_group_id =
                    this.data['characteristics_groups_people'][importantCharacteristicGroupIndex]['id'];
                this.characteristics_groups =
                    this.data['characteristics_groups_people'][importantCharacteristicGroupIndex];
                this.storageService
                    .getItem('characteristicsForGroup|' + this.characteristic_group_id)
                    .then((characteristics) => {
                        this.availableCharacteristics = characteristics;
                    });
            } else {
                this.characteristics_groups = this.data['characteristics_groups_people'];
            }
        }
        // Wenn nach Regionen gefiltert werden soll Binde die Regions-Daten ein.
        if (this.filterData['target'] === 'regions' && this.filterData['parentName'] === 'institutions') {
            // Hole die Daten aus dem Storage und speichere sie in die Select-Data-Optionen für den Multi-Input
            this.storageService.getItem('regions').then((data) => {
                this.availableOptions = data;
            });
        }

        this.checkDatatype();
    }

    // Datentyp prüfen, da hiervon das einzublendende Eingabefeld abhängt
    checkDatatype() {
        switch (this.filterData['datatype']) {
            case 'date':
                this.availableComparisons = this.dateComparisons;
                this.showInputDate = true;
                break;
            case 'list':
                this.availableComparisons = this.listComparisons;
                if (this.filterData['db_field'] === 'responsible_employee_id') {
                    this.showInputAutocomplete = true;
                } else {
                    // Erlaube für die Listentries auch die Multi-Selektion.
                    this.showInputMultiselect = true;
                    this.chosenListentry = this.filterData['listname'];
                }
                break;
            case 'number':
                this.availableComparisons = this.numberComparisons;
                this.showInputText = true;
                break;
            case 'plz':
                this.availableComparisons = this.zipcodeComparisons;
                this.showInputText = true;
                break;
            case 'string':
                this.availableComparisons = this.stringComparisons;
                this.showInputText = true;
                break;
            case 'conversationTopics':
                this.availableComparisons = this.conversationTopicsComparisons;
                // Erlaube für die Listentries auch die Multi-Selektion.
                this.showInputMultiselect = true;
                break;
            case 'samples':
                this.availableComparisons = this.conversationTopicsComparisons;
                // Erlaube für die Listentries auch die Multi-Selektion.
                this.showInputMultiselect = true;
                break;
            // Sonderlogik zur Selektion von (mehreren) Regionen
            case 'regionsList':
                this.showInputMultiselect = true;
                /*
                 * Erlaubten Vergleiche für die Regionen überschreiben
                 * @todo, wäre vermutlich auch für die Kennzeichen mit Mehrfachausprägung sinnvoll, ist aber einiges an Aufwand
                 * @todo Das In-Statement funktioniert zwar schon. grid-selection-panel dröselt mögliche Mehrfach-Werte allerdings
                 * immernoch in einzelne Werte-Vergleiche auf.
                 */
                this.availableComparisons = this.regionsMultiSelectComparisons;
                break;
            default:
                break;
        }
        // Wenn vom Nutzer noch kein Vergleich ausgewählt wurde, wähle automatisch den Ersten
        if (this.comparison === '' && this.filterData['target'] !== 'characteristics') {
            this.comparison = this.availableComparisons[0].id;
        }
    }

    onChangeCharacteristicGroup() {
        const promise = this.storageService.getItem('characteristicsForGroup|' + this.characteristic_group_id);
        promise.then((characteristics) => {
            this.availableCharacteristics = characteristics;
            this.characteristicChosen = false;
            this.availableOptions = [];
            this.showInputText = false;
            this.characteristic = {
                id: 0,
                label: '',
            };
            this.characteristic_id = 0;
            this.onChangeCharacteristic();
        });
    }

    onChangeCharacteristic() {
        // Daten des alten Kennzeichens löschen
        this.availableComparisons = [];
        this.comparison = '';
        this.option = '';
        this.multiOptions = [];
        this.frontendValue[0] = '';
        // Neues Kennzeichen und zugehörige Optionen setzen
        this.getCharacteristicAndOptions();
    }

    // Kennzeichen und die zugehörigen Optionen setzen
    getCharacteristicAndOptions() {
        // Es muss leider auch auf den leeren String verglichen werden, da Select Werte nur als String zurückgibt
        if (
            this.characteristic_id !== 0 &&
            this.characteristic_id !== null &&
            this.characteristic_id.toString() !== ''
        ) {
            // Aktuelles Kennzeichen in this.characteristic speichern
            this.characteristic = this.availableCharacteristics.find(
                (characteristic) => characteristic.id == this.characteristic_id,
            );

            if (typeof this.characteristic === 'undefined') {
                this.characteristic = {
                    id: 0,
                    label: '',
                };
            } else {
                if (Array.isArray(this.characteristic['options']) && this.characteristic['options'].length > 0) {
                    this.availableOptions = this.characteristic['options'];
                    this.availableComparisons = this.multiselectComparisons;
                    this.showInputText = false;
                    this.showInputMultiselect = true;
                    this.showInputDate = false;
                    this.filterData['datatype'] = null;
                } else if (this.characteristic['value_type'] == 'date') {
                    this.availableOptions = [];
                    this.availableComparisons = this.characteristicComparisons;
                    this.showInputText = false;
                    this.showInputMultiselect = false;
                    this.showInputDate = true;
                    this.filterData['datatype'] = 'date';
                } else {
                    this.availableOptions = [];
                    this.availableComparisons = this.characteristicComparisons;
                    this.showInputText = true;
                    this.showInputMultiselect = false;
                    this.showInputDate = false;
                    this.filterData['datatype'] = null;
                }
                // Wenn vom Nutzer noch kein Vergleich ausgewählt wurde, wähle automatisch den Ersten
                if (this.comparison === '') {
                    this.comparison = this.availableComparisons[0].id;
                }
                this.characteristicChosen = true;
            }
        }
    }

    // Nutzereingaben speichern und Event auslösen
    clickCreateFilter() {
        if (this.reopenedFilter) {
            this.setFilterData(this.filterData, false);
        } else {
            this.setFilterData(new SelectionFilter(), true);
        }

        this.clickAndClose();
    }

    // Erstelle/ ändere einen Filter anhand der Nutzereingaben
    setFilterData(filter: any, newFilter: boolean) {
        const fittingComparison = this.allComparisons.find(
            (comparison) => comparison.id === this.createFilterForm.form.value['comparison'],
        );
        filter['comparison_name'] = fittingComparison.label;
        filter['comparison'] = this.createFilterForm.form.value['comparison'];
        // eslint-disable-next-line no-multi-assign
        filter['frontendValue'][0] = filter['value'] = this.createFilterForm.form.value['frontendValue0'];
        filter['frontendValue'][1] = this.createFilterForm.form.value['frontendValue1'];
        if (newFilter) {
            filter['db_field'] = this.filterData['db_field'];
            filter['headerText'] = this.filterData['headerText'];
            filter['target'] = this.filterData['target'];
            filter['icon'] = this.filterData['icon'];
            filter['parentName'] = this.filterData['parentName'];
        }

        if (this.filterData['target'] === 'characteristics') {
            filter['importantCharacteristics'] = this.filterData['importantCharacteristics'];
            filter['characteristic_id'] = this.characteristic_id;
            filter['characteristic_group_id'] = this.characteristic_group_id;
            filter['characteristic_option_id'] = this.option;
            filter['multiOptions'] = this.multiOptions;
            filter['characteristic_type'] = this.characteristic['characteristic_type'];
            filter['name'] = this.characteristic['label'];
            filter['value_type'] = this.characteristic['value_type'];

            if (filter['characteristic_option_id'] != 0) {
                filter['value'] = filter['characteristic_option_id'];
            }

            if (this.characteristic['value_type'] === 'options') {
                if (this.multiOptions.length > 0) {
                    filter['frontendValue'][0] = this.characteristic['options'].find(
                        (option) => option.id == this.multiOptions[0],
                    )['label'];
                    if (this.multiOptions.length > 1) {
                        // eslint-disable-next-line operator-assignment
                        filter['frontendValue'][0] = filter['frontendValue'][0] + ', ...';
                    }
                } else {
                    filter['frontendValue'][0] = this.characteristic['options'].find(
                        (option) => option.id == this.option,
                    )['label'];
                }
            }

            if (this.characteristic['value_type'] === 'date' && this.isMomentObject(filter['frontendValue'][0])) {
                // falls ein datum gesetzt ist, uhrzeit abschneiden
                filter['frontendValue'][0] = moment(filter['frontendValue'][0].format('YYYY-MM-DD'), 'YYYY-MM-DD');
            }
        } else {
            if (newFilter) {
                filter['datatype'] = this.filterData['datatype'];
                filter['listname'] = this.filterData['listname'];
                filter['name'] = this.filterData['shownText'];
            }
            filter['listkey'] = this.listkey;
            // Falls es eine Mehrfach-Auswahl gibt nutze die Labels und nicht die Ids zur Anzeige des Filter-Textes
            if (this.multiOptions.length > 0 && (filter['listname'] === undefined || filter['listname'] === '')) {
                filter['frontendValue'][0] = this.availableOptions.find((option) => option.id == this.multiOptions[0])[
                    'label'
                ];
                if (this.multiOptions.length > 1) {
                    // Lange Filter-Texte sollen mit ... abgekürzt werden.
                    // eslint-disable-next-line operator-assignment
                    filter['frontendValue'][0] = filter['frontendValue'][0] + ', ...';
                }
                /*
                 *  Sowohl value als auch multiOptions müssen gesetzt werden.
                 * Alternativ muss die reinitialisierung von gespeicherten Kriterien umgebaut werden.
                 */
                filter['value'] = this.multiOptions;
                filter['multiOptions'] = this.multiOptions;
            }

            if (this.isMomentObject(filter['frontendValue'][0])) {
                filter['value'] = filter['frontendValue'][0];
                // filter['value'] = this.inputDateService.getDateValueForSave(filter['frontendValue'][0]);
            }

            if (filter['listname'] !== '' && filter['listname'] !== undefined) {
                this.filterDataSent = true;
                const promise = this.storageService.getItem('listentries|' + filter['listname']);
                promise.then((result) => {
                    if (this.multiOptions.length === 0 && this.listkey !== '') {
                        /*
                         * @todo: Dieser Teil des if-Statements wird evtl nicht mehr
                         * gebraucht, aber nicht sicher. unsicher
                         */
                        const index = result.findIndex((obj) => obj['list_key'] === filter['listkey']);
                        filter['frontendValue'][0] = result[index]['list_value'];
                        filter['value'] = filter['listkey'];
                    } else if (this.multiOptions.length > 0) {
                        /*
                         * Wenn die Mehrfachauswahl für die Selektion eingeblendet ist
                         * ... Suche die ausgewählten Listentries
                         */
                        const selections = result.filter((obj) => this.multiOptions.includes(obj['list_key']));
                        filter['frontendValue'][0] = selections[0]['list_value'];
                        if (selections.length > 1) {
                            filter['frontendValue'][0] += ', ...';
                        }
                        filter['value'] = selections.map((obj) => obj.list_key);
                        filter['multiOptions'] = this.multiOptions;
                    }
                    if (newFilter) {
                        this.gridSelectionService.filterCreated(filter);
                    } else {
                        this.gridSelectionService.filterEdited();
                    }
                });
            }

            // Typ der Nummer aus Konfiguration ergänzen
            if (this.filterData['target'] === 'supplementary_numbers') {
                filter['number_type'] = this.filterData['number_type'];
            }

            // Besprechungsprodukt
            if (this.filterData['target'] === 'contact_items') {
                filter['product_id'] = this.createFilterForm.form.value['options'];
                filter['multiOptions'] = this.multiOptions;
            }
        }

        // Falls ein Mitarbeiter gewählt wurde, setze dessen Daten
        if (this.responsible_employee.id != 0) {
            filter['value'] = this.responsible_employee.id;
            filter['frontendValue'][0] = this.responsible_employee.label;
        }

        // Falls "between" gewählt wurde, speichere zwei Werte unter "value"
        if (filter['comparison'] === 'between') {
            filter['value'] = [];
            /*
             * if (this.isMomentObject(filter['frontendValue'][0])) {
             *      filter['value'].push(this.inputDateService.getDateValueForSave(filter['frontendValue'][0]),
             *      this.inputDateService.getDateValueForSave(this.getDatePlus1(filter['frontendValue'][0])));
             *  } else {
             *      filter['value'].push(filter['frontendValue'][0], filter['frontendValue'][1]);
             *  }
             */
            filter['value'].push(filter['frontendValue'][0], filter['frontendValue'][1]);
        }

        /*
         * Falls auf ein genaues Datum gefiltert werden soll, speichere das gesuchte Datum und den Tag danach unter "value",
         * da alle Einträge die zwischen diesen Tagen liegen angezeigt werden sollen.
         * Da der Vergleich für das Frontend "equals" bleiben muss, wird er erst im Panel für das Backend auf "between" geändert.
         */
        if (filter['datatype'] === 'date' && filter['comparison'] === 'equals') {
            filter['value'] = [];
            filter['value'].push(filter['frontendValue'][0], this.getDatePlus1(filter['frontendValue'][0]));
            /*
             *  filter['value'].push(this.inputDateService.getDateValueForSave(filter['frontendValue'][0]),
             * this.inputDateService.getDateValueForSave(this.getDatePlus1(filter['frontendValue'][0])));
             */
        }

        if (filter['comparison'].includes('starts with')) {
            filter['value'] += '%';
        }
        if (filter['comparison'].includes('contains')) {
            filter['value'] = '%' + filter['value'] + '%';
        }

        if (filter['datatype'] === 'date' && filter['comparison'] === 'is after') {
            filter.frontendValue[0] = filter.frontendValue[0].add(23, 'hours').add(59, 'minutes');
        }

        if (!this.filterDataSent) {
            if (newFilter) {
                this.gridSelectionService.filterCreated(filter);
            } else {
                this.gridSelectionService.filterEdited();
            }
        }
    }

    // Gib das Datum zurück, das einen Tag nach dem übergebenen liegt.
    getDatePlus1(date) {
        const originalDate = moment(date, 'DD.MM.YYYY');
        const originalDatePlus1 = moment(originalDate).add(1, 'days');

        return originalDatePlus1;
    }

    /**
     * @brief   Prüfe, ob es sich beim übergebenen Value um ein Moment-Objekt handelt
     * @param {any} value test
     * @returns {boolean}
     * @author  Dominik Treutle <d.treutle@pharmakon.software>
     */
    isMomentObject(value) {
        return moment.isMoment(value);
    }

    clickAndClose() {
        // Dialog (über Referenz) schließen und Dialog-Ergebnis mitsenden
        this.dialogRef.close();
    }
}
