import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
// Service des übergeordneten Feature-Moduls
import {InstitutionsService} from '../institutions.service';
import {InstitutionsProductAnalysisService} from './institutions-product-analysis.service';
// Services der Feature-Modulen (zu denen ggf. gewechselt werden soll)
import {SalesService} from '@modules/sales/sales.service';
// Interfaces für Structured Objects einbinden
import {CWResult} from '@shared/cw-result';
import {Institution} from '@shared/institution';

@Component({
    selector: 'phscw-institutions-product-analysis',
    templateUrl: './institutions-product-analysis.component.html',
    styleUrls: ['./institutions-product-analysis.component.scss'],
    // !ChangeDetection nicht mehr über Lifecycle sondern manuell ausgelöst
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstitutionsProductAnalysisComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Id der ausgewählten Einrichtung.
    institutionId: number;
    // Produkt-Umsatz-Daten für das Grid
    data: any[] = [];
    // Soll das Lade-Icon gezeigt werden
    loading = false;

    // Spalten-Definition für das Grid
    gridColumns: any = [];
    // Angezeigten Spalten im Grid
    gridDisplayedColumns: string[] = [];

    constructor(
        private translateService: TranslateService,
        private institutionsService: InstitutionsService,
        private institutionsProductAnalysisService: InstitutionsProductAnalysisService,
        private salesService: SalesService,
        private appCore: AppCoreService,
        private changeDetector: ChangeDetectorRef,
    ) {}

    /**
     * Initialisierung
     */
    ngOnInit() {
        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Subscriptions intialisieren
     */
    initializeEventSubscriptions() {
        // In E-Liste wurde eine Einrichtung ausgewählt
        this.institutionsService.selectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: number) => {
                this.onSelectionChanged(result);
            });
    }

    /**
     * Wenn im Grid eine neue Einrichtungs ausgewählt werden
     * @param id
     */
    onSelectionChanged(id: number) {
        // ID der aktuellen Einrichtung ändern
        this.institutionId = id;
        // Daten neu laden
        this.loadData();
    }

    /**
     * Lade die Produkt-Umsatz-Daten aus dem Backend
     */
    loadData() {
        // Flag "loading" aktivieren
        this.loading = true;
        // Daten leeren
        this.data = [];
        // Change-Detection auslösen
        this.changeDetector.detectChanges();
        // Backen-Request starten
        const serviceRequest$ = this.institutionsProductAnalysisService.loadData(this.institutionId);
        serviceRequest$.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: CWResult) => {
            if (!result.success) {
                // @todo Fehlerbehandlung
                this.data = [];
                this.loading = false;
                // Change-Detection auslösen
                this.changeDetector.detectChanges();
                return;
            }

            // Daten für das Grid in die data-Variable speichern
            this.data = result.data['result'];
            /*
             * Informationen für die Header / Tabellen-Überschriften an
             * die Funktion zum Bauen der Spalten-Definition geben.
             */
            this.buildColumns(result.data['header']);

            this.loading = false;

            // Change-Detection auslösen
            this.changeDetector.detectChanges();
        });
    }

    /**
     * Erstellt die Spalten-Definition
     * @param headerData
     */
    buildColumns(headerData: any[]) {
        if (this.gridColumns.length !== 0) {
            /*
             * Wenn die Spaltendefinitionen schon einmal generiert wurden müssen
             * sie nicht erneut angepasst werden, da sich nur ihr Inhalt ändert.
             */
            return;
        }

        /*
         * Fügte die erste Spalte für den generischen Namen hinzu.
         * Hier wird mit Absicht nicht die sales-analysis-name-Spalte hinzugefügt.
         */
        this.gridColumns.push({
            columnDef: 'product-analysis-name',
            header: this.translateService.instant('GENERAL.PRODUCT'),
            cell: (element: any) => {
                if (typeof element['firstColumn'] !== 'undefined') {
                    return `${element['firstColumn']}`;
                }
                return '';
            },
            formatWidth: '200px',
            sticky: true,
            tooltip: true,
        });
        this.gridDisplayedColumns = ['product-analysis-name'];

        let index = 0;
        // Gehe die Spaltendefinitionen aus dem Backend durch
        for (const columnInfo of headerData) {
            // ... und erstelle die Frontend-Spalten-Definition
            const columnName = 'column' + index;
            const columnDef: any = {
                columnDef: columnName,
                header: columnInfo.label,
                cell: (element: any) => {
                    if (typeof element[columnName] !== 'undefined') {
                        return `${element[columnName]}`;
                    }
                    return '';
                },
                formatWidth: '80px',
                formatTemplate: 'currency',
                alignHeader: 'right',
            };

            index++;

            // Fals es sich um die letzte Spalte handelt
            if (index === headerData.length) {
                // Mache sie am Ende Sticky / damit sie immer zu sehen ist.
                columnDef.stickyEnd = true;
            }

            this.gridColumns.push(columnDef);
            this.gridDisplayedColumns.push(columnName);
        }
    }

    /**
     * Spezifische Funktion für den Wechsel zur Produktanalyse. Anderes Vorgehen
     * als beim Wechsel zu Personen oder Einrichtungsliste.
     * Analog zur Funktionalität in der Info-Komponente
     * @param target
     * @param institutionId
     * @author   Michael Schiffner <m.schiffner@pharmakon.software>
     */
    customSalesAnalysisProductViewNavigation(target: string, institutionId: number) {
        /*
         * Einrichtungsname wird hier zusammengebaut analog zur Suchmethode im Backend
         * Das eigentliche Format des Suchfelds ist im Backend definiert
         * (CONCAT(name1, ', ', Institutions.street, ' ', Institutions.street_number_from, ', ', Institutions.zipcode, ' ', Institutions.city)).
         */
        let institutionLabel = '';
        if (this.institutionsService.selectedInstitution) {
            const selectedInstitution: Institution = this.institutionsService.selectedInstitution;
            const joinArray: string[] = [
                selectedInstitution.name1,
                selectedInstitution.street,
                selectedInstitution.zipcode,
                selectedInstitution.city,
            ];
            institutionLabel = joinArray.join(', ');
        }

        // Falls das Modul schon initialisiert ist, löse ich das neu Laden der Daten über den Modul-Service aus.
        this.salesService.loadSalesAnalysisProductView('overlay-info', {
            id: institutionId,
            label: institutionLabel,
        });

        // Für den Fall, dass das Modul noch nicht initialisiert ist, gebe ich die Parameter als crossmoduleNavigationParameter mit
        this.appCore.crossModuleNavigation(target, {
            id: institutionId,
            label: '',
        });
    }
}
