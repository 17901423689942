// Angular-Module
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
// Komponenten
import {EntityIconsComponent} from './entity-icons/entity-icons.component';

@NgModule({
    imports: [CommonModule],
    declarations: [EntityIconsComponent],
    exports: [EntityIconsComponent],
})
export class EntityIconsModule {}
