/**
 * @brief   Globaler Service zur Speicherung des Tokens im Session-Storage.
 * @details Speichert das Authentication-Token, sowie dessen Ablauf
 *          im nicht persistenten Browser-Session-Storage. Dadurch werden
 *          die Token-Informationen nach dem Schließen des Fensters / Browsers
 *          gelöscht und bleiben nicht wie im Local-Storage weiterhin vorhanden.
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {Injectable} from '@angular/core';

// Konstanten
const TOKEN_KEY = 'authToken';
const TOKEN_EXPIRES_KEY = 'authTokenExpiresAt';

@Injectable({
    // Root-Injector (app.module.ts) ist verantwortlich für das Instanziieren dieses globalen Services
    providedIn: 'root',
})
export class TokenStorageService {
    // [private] Authentication-Token
    private _token: string = window.sessionStorage.getItem(TOKEN_KEY);
    // GET
    get token() {
        return window.sessionStorage.getItem(TOKEN_KEY);
    }

    // SET
    set token(value: string) {
        this._token = value;
        window.sessionStorage.removeItem(TOKEN_KEY);
        window.sessionStorage.setItem(TOKEN_KEY, value);
    }

    // [private] Ablauf der Gültigkeit des Authentication-Tokens
    private _tokenExpiresAt: number = JSON.parse(window.sessionStorage.getItem(TOKEN_EXPIRES_KEY));
    // GET
    get tokenExpiresAt() {
        return JSON.parse(window.sessionStorage.getItem(TOKEN_EXPIRES_KEY));
    }

    // SET
    set tokenExpiresAt(value: number) {
        this._tokenExpiresAt = value;
        window.sessionStorage.removeItem(TOKEN_EXPIRES_KEY);
        window.sessionStorage.setItem(TOKEN_EXPIRES_KEY, JSON.stringify(value));
    }

    // Konstruktor
    constructor() {}
}
