import {Type} from '@angular/core';
import {GridTemplateCurrencyComponent} from './templates/grid-template-currency/grid-template-currency.component';
import {GridTemplateDateComponent} from './templates/grid-template-date/grid-template-date.component';
import {GridTemplateNumberComponent} from './templates/grid-template-number/grid-template-number.component';
import {GridTemplatePlainComponent} from './templates/grid-template-text/grid-template-plain.component';
import {TableColumn} from '@shared/table-column';

export interface GridCellRenderer<T> {
    value: T; // Wert der in der Zelle angezeigt werden soll
    column?: TableColumn<unknown>; // Spaltenkonfiguration
}

// Typ der Komponente, die für die Zelle verwendet werden soll
export const componentMappings: {[key: string]: Type<GridCellRenderer<unknown>>} = {
    plain: GridTemplatePlainComponent,
    date: GridTemplateDateComponent,
    number: GridTemplateNumberComponent,
    currency: GridTemplateCurrencyComponent,
};
