/**
 * @brief   Popup für Terminplanung
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */

// Angular-Module
import {Component, Inject, OnDestroy, OnInit, ChangeDetectorRef} from '@angular/core';
// Angular-Material
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
// ngx-translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subscription} from 'rxjs';
// Eigener Service
import {PopupSchedulingService} from './popup-scheduling.service';
// Service von Shared Modules
import {GridService} from '@shared/grid/grid.service';
import {InputDateService} from './../../input/input-date/input-date.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from './../../cw-event';
// Environment einbinden
import {environment} from '@environment';
// Moment-Modul zur Datums-Verarbeitung
import {hasOwn} from '@shared/utils';
import * as _moment from 'moment';

const moment = _moment;

// Interface für Dialogdaten
export interface DialogData {
    title?: string;
    message?: string;
    answer?: string;
    displayedColumns: string[];
    gridData: any[];
    backendController: string;
    entity: string;
}

@Component({
    selector: 'phscw-popup-scheduling',
    templateUrl: './popup-scheduling.component.html',
    styleUrls: ['./popup-scheduling.component.scss'],
})
export class PopupSchedulingComponent implements OnInit, OnDestroy {
    // Referenzen auf Subject-Subscriptions
    private _subscriptions = new Subscription();

    // Daten
    gridData: any[] = [];
    gridDataIds: number[] = [];

    // Wochenauswahl
    startDate: any = moment().startOf('isoWeek');
    endDate: any = moment().endOf('isoWeek').subtract(2, 'days');
    weekNumber: any = moment().isoWeek();

    // Spaltendefinitionen für Grid
    gridColumns = [
        {
            columnDef: 'institution-address',
            header: 'Adresse',
            cell: (element: any) => `${element.street || ''} ${element.street_number_from || ''} ${element.street_number_to || ''}<br>${element.zipcode || ''} ${
                element.city || ''
            }`,
            formatWidth: '150px',
        },
        {
            columnDef: 'last-contact',
            header: 'Letzter',
            headerSecondRow: 'Besuch',
            cell: (element: any) => `${element.last_contact || ''}`,
            formatTemplate: 'datetime',
            formatWidth: '120px',
        },
        {
            columnDef: 'next-contact',
            header: 'Nächster',
            headerSecondRow: 'Termin',
            cell: (element: any) => `${element.next_appointment || ''}`,
            formatTemplate: 'datetime',
            formatWidth: '120px',
        },
    ];

    // Anzuzeigende Spalten für Grid
    gridDisplayedColumns: string[] = [
        'last-contact',
        'next-contact',
        'contact-quick-create-monday',
        'contact-quick-create-tuesday',
        'contact-quick-create-wednesday',
        'contact-quick-create-thursday',
        'contact-quick-create-friday',
    ];

    // Flag definiert ob gerade geladen wird
    loading = false;
    // Flag definiert ob gerade gespeichert wird
    saving = false;

    // Konstruktor (inkl. dependency injection)
    constructor(
        // eslint-disable-next-line new-cap
        @Inject(MAT_DIALOG_DATA) private data: DialogData,
        private dialogRef: MatDialogRef<PopupSchedulingComponent>,
        private gridService: GridService,
        private inputDateService: InputDateService,
        private popupSchedulingService: PopupSchedulingService,
        private translateService: TranslateService,
        private changeDetector: ChangeDetectorRef,
    ) {}

    // Initialisierungen
    ngOnInit() {
        // Übersetzungen subscriben
        this.initializeTranslateSubscriptions();

        // Events subscriben
        this.initializeEventSubscriptions();

        // Anzuzeigende Spalten der Entität setzen
        this.gridDisplayedColumns = this.data.displayedColumns.concat(this.gridDisplayedColumns);

        // Grid setzen
        this.gridData = this.data.gridData;

        // IDs der ausgewählte Entitäten sammeln
        this.gridData.forEach((entity) => {
            this.gridDataIds.push(entity.id);
        });

        // Kontakte laden
        this.loadContactsData(this.gridDataIds);
    }

    // Aufräumen
    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    /**
     * @brief   Übersetzungen subscriben
     * @details Subscribe auf Stream bekommt Änderung der Sprache mit
     *          und lädt Übersetzungen neu statt nur bei Initialisierung
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeTranslateSubscriptions() {
        this._subscriptions.add(
            this.translateService
                .stream([
                    'GENERAL.INSTITUTION',
                    'GENERAL.ADDRESS',
                    'SHARED.POPUP.SCHEDULING.LASTCONTACT',
                    'SHARED.POPUP.SCHEDULING.LASTCONTACTSECONDROW',
                    'SHARED.POPUP.SCHEDULING.NEXTAPPOINTMENT',
                    'SHARED.POPUP.SCHEDULING.NEXTAPPOINTMENTSECONDROW',
                ])
                .subscribe((translation) => {
                    this.gridColumns[0].header = translation['GENERAL.ADDRESS'];
                    this.gridColumns[1].header = translation['SHARED.POPUP.SCHEDULING.LASTCONTACT'];
                    this.gridColumns[1].headerSecondRow = translation['SHARED.POPUP.SCHEDULING.LASTCONTACTSECONDROW'];
                    this.gridColumns[2].header = translation['SHARED.POPUP.SCHEDULING.NEXTAPPOINTMENT'];
                    this.gridColumns[2].headerSecondRow = translation['SHARED.POPUP.SCHEDULING.NEXTAPPOINTMENTSECONDROW'];
                }),
        );
    }

    // Events subscriben
    initializeEventSubscriptions() {
        // Button wurde geklickt...
        this._subscriptions.add(
            this.gridService.eventQuickManageContact.subscribe((result) => {
                const event: CWEvent = result;
                if (event.sender == 'schedulingList') {
                    this.onEventQuickManageContact(result);
                }
            }),
        );
    }

    // Kontakt anlegen
    onEventQuickManageContact(event) {
        // Warten bis Kontakt verarbeitet wurde, bevor neuer Request ausgelöst wird
        if (this.loading || this.saving) {
            return;
        }

        // Flag "saving" aktivieren
        this.saving = true;

        // Werte aus Event zwischenspeichern
        const day = event.data.day;
        const entity = event.data.row;
        const contactId = event.data.contactId;

        // Muss ein Kontakt angelegt oder gelöscht werden?
        if (contactId === null) {
            // Kontakt anlegen und in Grid anzeigen
            this.createContact(entity, day);
        } else {
            // Kontakt löschen und aus Grid entfernen
            this.deleteContact(contactId, entity, day);
        }
    }

    /**
     * @param entityIds
     * @brief   Geplante Termine für Woche laden
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    loadContactsData(entityIds: number[]): void {
        // Flag "loading" aktivieren
        this.loading = true;

        // Kontaktanzeige zurücksetzen
        this.resetContacts();

        const formData: any = {
            entityIds,
            startDate: this.inputDateService.getDateValueForSave(this.startDate),
            orderByInstitution: true,
        };

        // Kontakte laden
        const serviceRequest$ = this.popupSchedulingService.loadContactsData(this.data.backendController, formData);
        serviceRequest$.subscribe((result) => {
            // Bei Erfolg Kontakte anzeigen
            if (result['success']) {
                this.gridData.forEach((row) => {
                    const entityId = row['id'];
                    // Prüfe ob Kontakte für Reihe vorhanden sind
                    if (typeof result['data'][entityId] === 'object') {
                        const contacts = result['data'][entityId];

                        // Kontakte der Entität hinzufügen
                        Object.assign(row, contacts);
                    }
                });
            }

            // Flag "loading" deaktivieren
            this.loading = false;
        });
    }

    /**
     * @param date
     * @brief   Wochenauswahl aktualisieren
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    updateWeek(date: any): void {
        this.startDate = date.clone().startOf('isoWeek');
        this.endDate = date.clone().endOf('isoWeek').subtract(2, 'days');
        this.weekNumber = date.clone().isoWeek();

        // Kontakte für ausgewählte Woche laden
        this.loadContactsData(this.gridDataIds);
    }

    /**
     * @brief   Kontakte zurücksetzen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    resetContacts(): void {
        // Kontakte zurücksetzen
        this.gridData.forEach((row) => {
            // Für jeden Wochentag prüfen
            for (let day = 1; day <= 5; day++) {
                if (hasOwn(row, 'day' + day)) {
                    delete row['day' + day];
                }
            }
        });
    }

    /**
     * @brief   Dialog schließen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    clickClose(): void {
        this.dialogRef.close();
    }

    /**
     * @param row
     * @param day
     * @brief   Kontakt löschen und aus Grid entfernen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private createContact(row: any, day: number): void {
        // Initialisiere
        let contactCount = 1;

        // Spätesten Termin des Tages finden
        const latestContactRow = this.gridData.reduce((row1, row2) => {
            const row1HasDay = hasOwn(row1, 'day' + day);
            const row2HasDay = hasOwn(row2, 'day' + day);

            if (
                (row1HasDay && !row2HasDay) ||
                (row1HasDay && row2HasDay && row1['day' + day]['count'] > row2['day' + day]['count'])
            ) {
                return row1;
            }
            return row2;
        });

        // Kontaktdaten vorbereiten
        const formData: any = {
            institution_id: 0,
            person_id: 0,
            contact_method: environment.contactsSchedulingMethod,
            contact_type: environment.contactsSchedulingType,
            contact_priority: 2,
            contact_start_date_backendsave: '',
            contact_start_time: '',
            contact_duration: environment.contactsSchedulingDuration,
            contacts_use_duration: true,
        };
        // Entitäts ID setzen
        formData[this.data.entity + '_id'] = row.id;
        // Start-Datum setzen
        formData['contact_start_date_backendsave'] = this.inputDateService.getDateValueForSave(
            this.startDate.clone().add(day - 1, 'days'),
        );
        // Start-Uhrzeit setzen
        if (hasOwn(latestContactRow, 'day' + day)) {
            formData['contact_start_time'] = moment(latestContactRow['day' + day]['contact_start'])
                .add(environment.contactsSchedulingStartGap, 'minutes')
                .format('HH:mm');
            // Anzahl der Kontakte setzen
            contactCount = latestContactRow['day' + day]['count'] + 1;
        } else {
            formData['contact_start_time'] = environment.contactsSchedulingStartTime;
        }

        // Kontakt speichern
        const serviceRequest$ = this.popupSchedulingService.saveDetails(this.data.backendController, formData);
        serviceRequest$.subscribe((result) => {
            // Bei Erfolg Kontakt zwischenspeichern
            if (result['success']) {
                // Daten aufbereiten
                const contact = {};
                contact['day' + day] = {
                    count: contactCount,
                    contact_id: result['data']['id'],
                    contact_start: result['data']['contact_start'],
                };

                // Kontakte der Entität hinzufügen
                Object.assign(row, contact);

                // Spalte "Nächster Termin" aktualisieren
                this.updateNextAppointment(row);
            }
        });
    }

    /**
     * @param contactId
     * @param row
     * @param day
     * @brief   Kontakt löschen und aus Grid entfernen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private deleteContact(contactId: number, row: any, day: number): void {
        // Kontakt löschen
        const serviceRequest$ = this.popupSchedulingService.deleteContact(this.data.backendController, contactId);
        serviceRequest$.subscribe((result) => {
            // Bei Erfolg Kontakt nicht mehr anzeigen
            if (result['success']) {
                // Kontakt aus Entität entfernen
                delete row['day' + day];

                // Spalte "Nächster Termin" aktualisieren
                this.updateNextAppointment(row);
            }
        });
    }

    /**
     * @param row
     * @brief   Nächsten Termin aktualisieren nach Neuanlage oder Löschung
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    private updateNextAppointment(row: any): void {
        // Nächsten Termin laden
        const serviceRequest$ = this.popupSchedulingService.loadNextAppointment(
            this.data.backendController,
            row.id,
        );
        serviceRequest$.subscribe((result) => {
            // Bei Erfolg geladenen Kontakt als nächsten Termin anzeigen
            if (result['success']) {
                // Kontakte der Entität hinzufügen
                Object.assign(row, result['data'][0]);
                this.changeDetector.detectChanges();
                this.saving = false;
            }
        });
    }
}
