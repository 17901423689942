import {Type} from '@angular/core';
import {GridCellRenderer} from './grid-new/grid-new-config';

// Definition des TableColumn-Interfaces für die Spaltenkonfiguration
export interface TableColumn<T> {
    columnDef: string; // Eindeutige Spaltenbezeichnung
    header: string; // Überschrift, die im Tabellenkopf angezeigt wird
    cell: (row: T) => unknown; // Funktion, die den Zellinhalt aus einer Zeile extrahiert
    cellClasses?: string[]; // Optionale CSS-Klassen für die Zelle
    headerClasses?: string; // Optionale CSS-Klassen für den Kopf
    mode: TableColumnMode; // Modus zur Darstellung des Zellinhalts (z.B. Datum, Währung)
    component?: Type<GridCellRenderer<unknown>>; // Komponente, die für die Zelle verwendet werden soll - nur bei mode: 'component'
    routerLinkFn?: (row: T) => unknown[]; // Optional: Funktion für Navigation bei Klick
    sortable?: boolean; // Gibt an, ob die Spalte sortierbar ist - default: true
    filterable?: boolean; // Gibt an, ob die Spalte filterbar ist
}

// Definition der möglichen Modi für die Darstellung von Zellen
export const COLUMN_PLAIN = 'plain'; // einfacher Text
export const COLUMN_NUMBER = 'number'; // Zahlen
export const COLUMN_HTML = 'html'; // HTML-Inhalte
export const COLUMN_ICON = 'icon'; // Font-Awesome-Icons / SVGs
export const COLUMN_DATE = 'date'; // Datumsangaben
export const COLUMN_CURRENCY = 'currency'; // Währungen
export const COLUMN_COMPONENT = 'component'; // Darstellung von eigenen Komponenten - dafür ist die Angabe der "component" der TabelColumn notwendig

// Typdefinition für TableColumnMode, um die möglichen Werte einzuschränken
export type TableColumnMode =
  | typeof COLUMN_PLAIN
  | typeof COLUMN_NUMBER
  | typeof COLUMN_HTML
  | typeof COLUMN_ICON
  | typeof COLUMN_DATE
  | typeof COLUMN_CURRENCY
  | typeof COLUMN_COMPONENT;
