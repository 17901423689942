// Angular-Module
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services einbinden
import {AuthService} from './../../services/auth.service';
// Interfaces für Structured Objects einbinden
import {CWResult} from '@shared/cw-result';
// Environment einbinden
import {environment} from '@environment';
import {hasOwn} from '@shared/utils';
import {ActivatedRoute} from '@angular/router';
import {BackendService} from '@global/services/backend.service';

@Component({
    selector: 'phscw-global-login',
    templateUrl: './global-login.component.html',
    styleUrls: ['./global-login.component.scss'],
})
export class GlobalLoginComponent implements OnInit, OnDestroy {
    // Referenz auf die Form
    @ViewChild('loginForm', {static: true}) loginForm: NgForm;

    // Flag definiert, ob es beim Login zu einem Fehler kam
    public loginError = false;
    // Flag definiert, welcher Fehler ausgegeben wird
    public loginErrorTranslation = 'MISMATCH';
    // Flag definiert, ob Loginanfrage gerade läuft
    public loading = false;

    // Link für "Passwort vergessen"
    passwordForgotLink = '';

    public ssoConfig = [];

    /**
     * Konstruktor (inkl. dependency injection)
     * @param authService
     * @param route
     */
    constructor(
        private authService: AuthService,
        private route: ActivatedRoute,
        private backendService: BackendService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        this.checkSSOConfig();
        // Setze passwordForgotLink über Environment-Variable. Falls nicht vorhanden, erscheint kein Link "Passwort vergessen"
        if (typeof environment.passwordForgotLink !== 'undefined') {
            this.passwordForgotLink = environment.passwordForgotLink;
        }

        this.checkSSO();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {}

    /**
     * @brief   Submit des Login-Formulars.
     * @details Login-Prozess läuft über authService.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    clickLogin(): boolean {
        // Credentials aus Formular
        const loginCredentials = {
            username: this.loginForm.form.value['username'],
            password: this.loginForm.form.value['password'],
        };
        // Falls Username und / oder Passwort fehlen wird abgebrochen
        if (loginCredentials.username === '' || loginCredentials.password === '') {
            return false;
        }
        // Flag setzen --> Loginanfrage läuft nun
        this.loading = true;
        // Bei neuer Loginanfrage wird loginError zurück gesetzt
        this.loginError = false;
        // Login über authService aufrufen
        const authServiceLogin$: Observable<any> = this.authService.login(loginCredentials);
        authServiceLogin$.subscribe(
            // onNext
            (result: CWResult) => {
                // Login fehlgeschlagen?
                if (!result.success) {
                    // Nachricht setzen
                    this.setErrorMessage(result.data);

                    // Flag setzen
                    this.loginError = true;
                }
                this.loading = false;
            },
            // onError
            (error: any) => {
                // Keine Antwort vom Backend erhalten (Servererror)
                this.loginError = true;
                this.loading = false;
            },
        );
    }

    /**
     * @brief   Weiter leiten zur Microsoft Login seite
     * @author  Nick Mühlberger <n.muehlberger@pharmakon.software> Eric Haeussel <e.haeusel@pharmakon.software>
     */
    clickLoginMicrosoft() {
        const clientId = this.ssoConfig['client_id'];
        const tenantId = this.ssoConfig['tenant_id'];
        const redirectUri = encodeURIComponent(this.ssoConfig['redirect_uri']);
        const scope = 'openid profile email phone address';
        const responseType = 'code';

        /*
         * wird benutzt um csrf attacken zu verhindern src:
         * https://learn.microsoft.com/en-us/aspnet/core/security/anti-request-forgery?view=aspnetcore-8.0
         */
        const state = this.generateRandomString(100);
        localStorage.setItem('state', state);

        const baseUrl = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize`;
        const queryParams = [
            `client_id=${clientId}`,
            `redirect_uri=${redirectUri}`,
            `response_type=${responseType}`,
            `scope=${encodeURIComponent(scope)}`,
            `state=${state}`,
        ].join('&');

        const url = `${baseUrl}?${queryParams}`;
        window.location.href = url;
    }

    /**
     * @param resultData
     * @brief   Prüft geladene Daten und setzt Fehlermeldung entsprechend
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    setErrorMessage(resultData: any): void {
        if (hasOwn(resultData, 'logins_failed') && resultData.logins_failed === true) {
            this.loginErrorTranslation = 'MAXLOGINSFAILED';
        } else if (hasOwn(resultData, 'deleted') && resultData.deleted === true) {
            this.loginErrorTranslation = 'DELETED';
        } else if (hasOwn(resultData, 'not_found') && resultData.not_found === true) {
            this.loginErrorTranslation = 'NOTFOUND';
        } else if (hasOwn(resultData, 'incomplete_configuration') && resultData.incomplete_configuration === true) {
            this.loginErrorTranslation = 'INCOMPLETECONFIGURATION';
        } else {
            this.loginErrorTranslation = 'MISMATCH';
        }
    }

    /**
     * @brief   Erzeugt random string für state
     * @author  Eric Haeussel <e.haeusel@pharmakon.software>
     */
    private generateRandomString(length: number): string {
        const array = new Uint32Array(length);
        window.crypto.getRandomValues(array);
        return Array.from(array, (x) => ('00' + x.toString(16)).slice(-2)).join('').substring(0, length);
    }

    /**
     * @brief   ausgelagerte Method um automatisch Weiterzuleiten wenn von SSo Login erfolgreich war
     * @author  Eric Haeussel <e.haeusel@pharmakon.software>
     */
    private checkSSO() {
        // redirect through sso login
        if (this.route.snapshot.url[0].path == 'sso') {
            this.loading = true;
            this.route.queryParams.subscribe((params) => {
                const code = params['code'];
                /*
                 * wird benutzt um csrf attacken zu verhindern src:
                 * https://learn.microsoft.com/en-us/aspnet/core/security/anti-request-forgery?view=aspnetcore-8.0
                 */
                if (params['state'] != localStorage.getItem('state')) {
                    console.error('state inconsistent - potential CSRF attack');
                    return false;
                }

                const authServiceLogin$: Observable<any> = this.authService.ssoLogin(code);
                authServiceLogin$.subscribe(
                    // onNext
                    (result: CWResult) => {
                        // Login fehlgeschlagen?
                        if (!result.success) {
                            // Nachricht setzen
                            this.setErrorMessage(result.data);
                            // Flag setzen
                            this.loginError = true;
                        }
                        this.loading = false;
                    },
                    // onError
                    (error: any) => {
                        // Keine Antwort vom Backend erhalten (Servererror)
                        this.loginError = true;
                        this.loading = false;
                    },
                );
            });
        }
    }

    /**
     * @brief   Lädt die endpunkte für SSO, endpunkt geht ohne Login da keine sicherheitsrelevanten infos vorhanden
     * @author  Eric Haeussel <e.haeusel@pharmakon.software>
     */
    private checkSSOConfig() {
        const authServiceLogin$: Observable<any> = this.backendService.getRequest('Config/getSSOConfig');
        authServiceLogin$.subscribe(
            // onNext
            (result: any) => {
                this.ssoConfig = result.data;
            },
            // onError
            (error: any) => {
                // Keine Antwort vom Backend erhalten (Servererror)
                console.error('ConfigEndpunkt');
                console.error(error);
            },
        );
    }
}
