// Angular-Module
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {NavigationEnd, Router} from '@angular/router';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {filter, first} from 'rxjs/operators';
// Shared Components
import {PopupConfirmationComponent} from '@shared/popups/popup-confirmation/popup-confirmation.component';
// import {PopupConfirmationComponent} from '@shared/popups/popup-confirmation/popup-confirmation.component';

@Injectable({providedIn: 'root'})
/*
 * @see https://blog.nodeswat.com/automagic-reload-for-clients-after-deploy-with-angular-4-8440c9fdd96c
 */
export class VersionCheckService {
    // Hier wird durch Ausführen von post-build.js der eigentliche Hashwert eingetragen
    private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

    // Platzhalter-String ({{POST_BUILD_ENTERS_HASH_HERE}}) um zu prüfen ob die Ersetzung durchgeführt wurde, base64-kodiert (um nicht ersetzt zu werden).
    private placeholderHashBase64 = 'e3tQT1NUX0JVSUxEX0VOVEVSU19IQVNIX0hFUkV9fQ==';
    private placeholderHashDecoded = '';

    // Intervall in Minuten, in welchem der Hashwert geprüft werden soll
    private minuteIntervall = 30;

    // URL der Version.json
    private url = '';

    constructor(
        private http: HttpClient,
        private dialog: MatDialog,
        private translateService: TranslateService,
        private router: Router,
    ) {}

    // Events subscriben
    initializeEventSubscriptions() {
        // Router prüfen - wurde zu einem anderen Modul gewechselt?
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
            this.checkVersion();
        });
    }

    /**
     * Prüft im angegebenen Intervall, ob sich der gespeicherte Hashwert geändert hat
     * @param url   URL zur version.json, in welcher der aktuelle Hashwert gespeichert ist
     */
    public initVersionCheck(url) {
        // Geschützten Platzhalter-String für Vergleich dekodieren und speichern
        this.placeholderHashDecoded = atob(this.placeholderHashBase64);

        // URL-Variable initialisieren
        this.url = url;

        // Eventsubscriptions
        this.initializeEventSubscriptions();

        // alle x Minuten erneut den Check durchführen
        const millisecondIntervall = this.minuteIntervall * 60 * 1000;
        setInterval(() => {
            this.checkVersion();
        }, millisecondIntervall);
    }

    /**
     * Prüft, ob sich der gespeicherte Hashwert geändert hat
     */
    private checkVersion() {
        // Schicke bei jedem Request einen Timestamp mit, um den Cache zu umgehen
        this.http
            .get(this.url + '?t=' + new Date().getTime())
            .pipe(first())
            .subscribe(
                (response: any) => {
                    if (response !== null) {
                        const hash = response.hash;
                        const hashChanged = this.hasHashChanged(this.currentHash, hash);

                        // Wenn sich die Version geändert hat oder zuvor auf "Abbrechen" geklickt wurde, öffne ein Benachrichtigungspopup
                        if (hashChanged) {
                            // Dialog konfigurieren und öffnen
                            const dialogRef = this.dialog.open(PopupConfirmationComponent, {
                                width: '350px',
                                data: {
                                    title: 'C-World Update',
                                    message: this.translateService.instant('GENERAL.UPDATEMESSAGE'),
                                    buttonLabelNo: this.translateService.instant('GENERAL.CANCEL'),
                                    buttonLabelYes: this.translateService.instant('GENERAL.REFRESHNOW'),
                                },
                            });
                            // Auf das Schließen des Dialogs reagieren
                            dialogRef.afterClosed().subscribe((result) => {
                                if (result['answer'] === 'yes') {
                                    location.reload();
                                }
                            });
                        }
                    }
                },
                (err) => {
                    console.error(err, 'Hashwert für den Versionscheck konnte nicht abgerufen werden!');
                },
            );
    }

    /**
     * Vergleicht den im js gespeicherten Hashwert mit dem im json gespeicherten
     * @param currentHash   Im js gespeicherter Hashwert
     * @param newHash       Im version.json gespeicherter Hashwert
     * @returns boolean     true wenn sich der Hashwert geändert hat, ansonsten false
     */
    private hasHashChanged(currentHash, newHash) {
        if (!currentHash || currentHash === this.placeholderHashDecoded) {
            return false;
        }

        return currentHash !== newHash;
    }
}
