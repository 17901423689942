// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {BackendService} from '@global/services/backend.service';
// Interfaces für Structured Objects einbinden
import {CWResult} from '@shared/cw-result';

@Injectable({providedIn: 'root'})
export class InstitutionsInvoicesService {
    // Konstruktor (inkl. dependency injection)
    constructor(private backendService: BackendService) {}

    /**
     * @brief   Gruppierte Rechnungsansicht einer Einrichtung laden
     * @param institutionId
     * @param gridPage
     * @param regionsFilter
     * @param filter
     * @details Liefert Rechnungen mit Summe, ohne Details zu Rechnungspositionen
     * @param   number  institutionId   ID der Einrichtung
     * @param   number  gridPage        Zu ladende Seite für Pagination
     * @returns  Observable<CWResult>
     */
    loadDataList(institutionId: number, gridPage: number, regionsFilter: object, filter: object): Observable<CWResult> {
        // GET-Request über BackendService senden
        const postRequest$ = this.backendService.postRequest('InstitutionsInvoices/index', {
            institutionId,
            page: gridPage,
            regionsFilter,
            filter,
        });
        // Observable<Object> zu Observable<CWResult> casten und an Komponente zurückliefern.
        return <Observable<CWResult>>postRequest$;
    }

    /**
     * @brief  Liefert alle Details zu einer bestimmten Rechnung
     * @param  string orderErpNumber: Identifier des Auftrags
     * @param orderErpNumber
     * @param invoiceErpNumber
     * @param regionsFilter
     * @param  string invoiceErpNumber: Identifier der Rechnung
     * @param  object regionsFilter: aktiver Regionsfilter
     * @returns Observable<CWResult>
     */
    loadDataDetails(orderErpNumber: string, invoiceErpNumber: string, regionsFilter: object): Observable<CWResult> {
        // POST-Request über BackendService senden
        const postRequest$ = this.backendService.postRequest('InstitutionsInvoices/details/', {
            orderErpNumber,
            regionsFilter,
            invoiceErpNumber,
        });
        // Observable<Object> zu Observable<CWResult> casten und an Komponente zurückliefern.
        return <Observable<CWResult>>postRequest$;
    }
}
