// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Service des globalen Regionsfilters
import {GlobalRegionsfilterService} from '@global/components/global-regionsfilter/global-regionsfilter.service';
import {InitService} from '@global/services/init.service';
// Service des übergeordneten Feature-Moduls
import {InstitutionsService} from '../institutions.service';
// Service von Shared Modules
import {StorageService} from '@global/services/storage.service';
import {UserPermissionsService} from '@global/services/user-permissions.service';
import {ContactsService} from '@shared/contacts/contacts.service';
import {GridService} from '@shared/grid/grid.service';
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// Interfaces für Structured Objects einbinden
import {Contacts} from '@shared/contacts';
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
import {Listentry} from '@shared/listentry';
import {LooseObject} from '@shared/loose-object';
import {MenuData} from '@shared/menu-data';
import {hasOwn} from '@shared/utils';
import {User} from '@shared/user';
// Environment
import {environment} from '@environment';
// Shared Komponenten einbinden
import {PopupConfirmationComponent} from '@shared/popups/popup-confirmation/popup-confirmation.component';
import {PopupFormularContactFilterComponent} from '@shared/popups/popup-formular-contact-filter/popup-formular-contact-filter.component';

@Component({
    selector: 'phscw-institutions-contacts',
    templateUrl: './institutions-contacts.component.html',
    styleUrls: ['./institutions-contacts.component.scss'],
})
export class InstitutionsContactsComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Grid-ID
    gridId = 'institutionsContacts';

    // ID der aktuell ausgewählten Einrichtung
    institutionId: number;
    // ID des ausgewählten Kontakts
    contactId: number;

    // Modul-Daten (Kontakte der Einrichtung)
    data: any[] = [];

    // Backend-Controller
    controllerName = 'InstitutionsContacts';

    // Filter für die Kontakte
    targetFilter: LooseObject = {
        key: 'advanced',
        formular: {
            contact_start_date: null,
            contact_end_date: null,
        },
    };

    // EditMode aktiv?
    @Input() editMode = false;
    // Kontakt schreibgeschützt?
    readonly = true;

    // "Mehr laden..."
    loadMoreEnabled = false;
    loadMoreVisible = true;
    gridPage = 1;

    // Grid-Filter
    gridRegionsFilter: any = {};

    // Um das korrekte Form zu öffnen muss der Kontakttyp an die shared component weitergegeben werden
    contactType = '';

    // Spaltendefinitionen für Grid (Spezial-Spalten, z.B. "contact-info", sind direkt im Grid definiert)
    gridColumns = [
        {
            columnDef: 'contact-start',
            header: 'Datum',
            cell: (element: any) => `${element.contact_start}`,
            formatTemplate: 'date',
            formatWidth: '100px',
        },
        {
            columnDef: 'employee',
            header: 'Mitarbeiter',
            cell: (element: Contacts) => `${element.employee_lastname || ''}, ${element.employee_firstname || ''}`,
            formatWidth: '200px',
        },
    ];

    // Anzuzeigende Spalten für Grid
    gridDisplayedColumns = ['contact-start', 'contact-type', 'contact-method', 'contact-person', 'contact-info'];

    // Mit Checkbox ausgewählte Kontakte
    checkedContacts: Contacts[] = [];

    // Dürfen neue Einrichtungs-Kontakte angelegt werden
    allowNewInstitutionContact = false;

    /*
     * Wie viele Tage darf der Kontakt in die Vergangenheit noch bearbeitet werden.
     * Wird über die Berechtigungen gesetzt (0 heißt unendlich viele Tage)
     */
    allowEditContacts = 0;

    // Flag definiert, ob Löschen von Kontakten erlaubt ist
    allowDeleteContacts = false;

    // Flag definiert, ob Löschen von Kontakten erlaubt ist
    allowDeleteOwnContacts = false;
    // Flag definiert, wie alt ein Kontakt für die Löschung maximal seien darf - 0 heißt unendlichviele Tage
    DeleteContactWithinDays = 0;
    // Flag definiert, ob der Kontakt gelöscht werden darf
    disableDeleteButton = false;
    // Die User employee id
    employeeId = null;
    // Sollen Multikontakte gruppiert werden?
    enableMultiContactGrouping = false;
    // Sichtbare Produkttypen
    allowedProductTypes = [];

    // Daten für das Toolbar-Menü
    public addMenuData: MenuData[] = [
        {
            name: 'contact',
            shownText_deu: 'Kontaktbericht',
            shownText_eng: 'Contact Report',
            icon: 'icon-contact',
        },
        {
            name: 'task',
            shownText_deu: 'Aufgabe',
            shownText_eng: 'Task',
            icon: 'icon-task',
        },
        {
            name: 'appointment',
            shownText_deu: 'Termin',
            shownText_eng: 'Appointment',
            icon: 'icon-appointment',
        },
        {
            name: 'ticket',
            shownText_deu: 'Support',
            shownText_eng: 'Support',
            icon: 'icon-contact',
        },
        {
            name: 'scheduled',
            shownText_deu: 'geplanter Termin',
            shownText_eng: 'geplanter Termin',
            icon: 'icon-contact',
        },
    ];

    /**
     * Konstruktor (inkl. dependency injection)
     * @param dialog
     * @param userPermissions
     * @param regionsfilterService
     * @param gridService
     * @param toolbarService
     * @param institutionsService
     * @param contactsService
     * @param translateService
     * @param storageService
     * @param initService
     * @param filterDialog
     */
    constructor(
        private dialog: MatDialog,
        private userPermissions: UserPermissionsService,
        private regionsfilterService: GlobalRegionsfilterService,
        private gridService: GridService,
        private toolbarService: ToolbarService,
        private institutionsService: InstitutionsService,
        private contactsService: ContactsService,
        private translateService: TranslateService,
        private storageService: StorageService,
        private initService: InitService,
        private filterDialog: MatDialog,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Mitarbieter Id vom Benutzer holen
        this.getOwnUserEmployeeId();
        // Übersetzungen subscriben
        this.initializeTranslateSubscriptions();

        // Events subscriben
        this.initializeEventSubscriptions();

        // Konfiguration prüfen
        this.checkFeatureConfiguration();

        // Berechtigung "allowEditContactFor" prüfen
        this.checkAllowEditContactsFor();

        // Berechtigung "allowDeleteContacts" prüfen
        this.checkAllowDeleteContacts();

        // Berechtigung "allowDeleteOwnContacts" prüfen
        this.checkAllowDeleteOwnContacts();

        // Übersetzungen subscriben
        this.initializeTranslateSubscriptions();

        /*
         * Prüfe, ob es für die Menü-Daten zum Anlegen eines neuen Kontakts,
         * auch die entsprechenden Listentries gibt, sonst entferne die entspr. Menü-Einträge
         */
        this.checkContactTypeMenuData();

        // Prüfe, ob neue Einrichtungs-Kontakte angelegt werden dürfen.
        this.checkAllowCreateInstitutionContact();

        // Prüfe, ob Multikontakte gruppiert werden sollen
        this.checkMultiContactGrouping();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Übersetzungen subscriben
     * @details Subscribe auf Stream bekommt Änderung der Sprache mit
     *          und lädt Übersetzungen neu statt nur bei Initialisierung
     * @todo    Keys für stream() in Variable auslagern sobald von ngx-translate unterstützt wird
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     * @author  Min Hye Park     <m.park@pharmakon.software>
     */
    initializeTranslateSubscriptions() {
        this.translateService
            .stream(['GENERAL.DATE'])
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((translation: any) => {
                this.gridColumns.find((column: any) => column.columnDef === 'contact-start').header =
                    translation['GENERAL.DATE'];
            });
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // In der E-Liste wird eine Einrichtung ausgewählt
        this.institutionsService.selectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: number) => {
                this.onSelectionChanged(result);
            });

        // In der Kontaktliste wird ein Kontakt angeklickt
        this.gridService.eventGridSelectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                const event: CWEvent = result;
                if (event.sender != 'institutionsContacts') {
                    return;
                }
                this.onEventGridSelectionChanged(result);
            });

        // Es wurde auf "Mehr laden..." geklickt
        this.gridService.eventGridPageCounterChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                const event: CWEvent = result;
                if (event.target != 'institutionsContacts') {
                    return;
                }
                this.onEventGridPageCounterChanged(result);
            });

        // Liste muss neu geladen werden
        this.gridService.eventReloadGridData.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: CWEvent) => {
            if (result.sender != 'contacts-form') {
                return;
            }
            this.onEventGridReloadGridData();
        });

        // Wenn Regionsfilter geändert wird
        this.regionsfilterService.eventRegionsfilterChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls Regionsfilter nicht für Kontaktübersicht gilt
                if (event.target != 'institutions') {
                    return;
                }
                this.onEventRegionsfilterChanged(result);
            });

        // Event des Grid bei Klick einer Checkbox
        this.gridService.eventGridCheckboxClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht vom eigenen Grid kam
                if (event.sender !== 'institutionsContacts') {
                    return;
                }
                this.onEventGridCheckboxClicked(event);
            });

        // Event der Toolbar zum Löschen eines Kontakts
        this.toolbarService.eventDeleteItem.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: CWEvent) => {
            // Event-Daten
            const event: CWEvent = result;
            // Abbruch, falls diese Komponente nicht Ziel des Events ist
            if (event.target !== 'institutionsContacts') {
                return;
            }
            this.onEventDeleteItem();
        });

        // Es wurde in der Toolbar auf "Neuer Kontakt" geklickt
        this.toolbarService.eventAddSpecificItem
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Abbruch passiert in der Funktion
                this.onEventAddSpecificItem(result);
            });

        // Event reageirt auf Filter oder Sortieren Button Interaktionen bei den Einrichtungen
        this.toolbarService.eventGridExtensionToggle
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                const event: CWEvent = result;
                // Prüfung, of der Kontaktfilter gedrückt wurde
                if (event.data && event.data.extension === 'filter' && event.target === 'institutionsContacts') {
                    // Daten für das Popup inizialisieren
                    const popupData = null;
                    const componentRef = PopupFormularContactFilterComponent;
                    const selectedFilterItem = {
                        label: 'Erweiterter Filter',
                        key: 'advanced',
                        display: 'popup',
                        popupTitle: 'Erweiterter Filter - Kontakte',
                        formular: {type: 'contacts'},
                    };
                    const popupTitle = selectedFilterItem.popupTitle;

                    const dialogRef = this.filterDialog.open(componentRef, {
                        width: '450px',
                        data: {
                            title: popupTitle,
                            message: '',
                            data: popupData,
                            module: event.target,
                        },
                    });
                    // Auf das Schließen des Dialogs reagieren
                    dialogRef.afterClosed().subscribe((result) => {
                        this.onFilterDialogAnswer(selectedFilterItem, result);
                    });
                }
            });

        // Alle Daten beim Login wurden in den Storage geladen
        this.initService.allInitialized.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: boolean) => {
            if (result) {
                this.checkContactTypeMenuData();
            }
        });
    }

    /**
     * In der E-Liste wird eine Einrichtung ausgewählt
     * @param id
     */
    onSelectionChanged(id: number): void {
        // Bereits vorhandene Daten (einer anderen Einrichtung) entfernen
        this.resetData();
        // ID der aktuellen Einrichtung merken
        this.institutionId = id;
        // Daten laden, falls eine existente Einrichtung ausgewählt wurde
        if (this.institutionId > 0) {
            this.loadData();
        }
    }

    /**
     * In der Kontaktliste wird ein Kontakt angeklickt
     * @param event
     */
    onEventGridSelectionChanged(event: CWEvent): void {
        this.contactId = event.data['selectedRow']['id'];
        this.contactType = event.data['selectedRow']['contact_type'];

        this.editMode = true;
    }

    /**
     * Es wurde auf "Mehr laden..." geklickt
     * @param event
     */
    onEventGridPageCounterChanged(event: CWEvent): void {
        this.gridPage = event.data['gridPageCounter'];
        this.loadData();
    }

    /**
     * Liste muss neu geladen werden
     */
    onEventGridReloadGridData(): void {
        this.resetData();
        this.loadData();
    }

    /**
     * Auf Event "eventRegionsfilterChanged" des globalen Regionsfilters reagieren
     * @param event
     */
    onEventRegionsfilterChanged(event: CWEvent): void {
        // Grid-Filter setzen (über Binding wird geänderter Filter direkt an Grid weitergegeben)
        if (event.data['refreshGrid'] === true) {
            this.gridRegionsFilter = {
                division: event.data['division'],
                region: event.data['region'],
            };
        }
    }

    /**
     * Auf Event "eventGridCheckboxClicked" von "gridService" reagieren
     * @param event
     */
    onEventGridCheckboxClicked(event: CWEvent): void {
        // Die Angehakten Reihen in Variable speichern.
        this.checkedContacts = event.data.selection;
        // Markierte Kontakte prüfen (zur Löschung)
        if (this.allowDeleteOwnContacts && !this.allowDeleteContacts) {
            this.checkDeleteOwnContacts();
        }
    }

    /**
     * Auf Event "eventDeleteItem" von "toolbarService" reagieren
     */
    onEventDeleteItem(): void {
        // Bestägigungsdialog öffnen
        this.openDeleteDialog();
    }

    /**
     * @brief   Dialog öffnen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    openDeleteDialog(): void {
        // Dialog Text
        let dialogTitle: string;
        let dialogMessage: string;

        // Anzahl der ausgewählten Elemente prüfen
        if (this.checkedContacts.length === 1) {
            dialogTitle = this.translateService.instant('GENERAL.DELETECONTACT');
            dialogMessage = this.translateService.instant('GENERAL.DELETECONTACTQUESTION');
        } else if (this.checkedContacts.length > 1) {
            dialogTitle = this.translateService.instant('GENERAL.DELETECONTACTS');
            dialogMessage = this.translateService.instant('GENERAL.DELETECONTACTSQUESTION', {count: this.checkedContacts.length});
        }

        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
            width: '350px',
            data: {
                title: dialogTitle,
                message: dialogMessage,
            },
        });

        // Auf das Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe((result) => {
            this.deleteContacts(result);
        });
    }

    /**
     * @param result
     * @brief   Kontakte löschen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    deleteContacts(result: any): void {
        // Wenn in Confirm-Dialog Button "Ja" geklickt wurde
        if (result.answer == 'yes') {
            const formData = {contacts: this.checkedContacts};

            // Submit der Formular-Daten über adminNotificationsService
            const serviceRequest$ = this.contactsService.deleteContacts(this.controllerName, formData);
            serviceRequest$.subscribe((result: CWResult) => {
                // Nach erfolgreichem Löschen Reset durchführen
                if (result['success']) {
                    // Zurücksetzen
                    this.checkedContacts = [];

                    // Neu laden
                    this.resetData();
                    this.loadData();
                }
            });
        }
    }

    /**
     * Kontakte der Einrichtung laden für Listenansicht
     */
    loadData(): void {
        // Funktion "Mehr laden..." wird (wieder) deaktiviert
        this.loadMoreEnabled = false;

        // Daten vorbereiten
        const formData = {
            institutionId: this.institutionId,
            regionsfilter: this.gridRegionsFilter,
            page: this.gridPage,
            filter: this.targetFilter,
            type: 'institutions',
            limit: 5,
        };

        // Daten laden
        const serviceRequest$ = this.contactsService.loadContactsListForInstitution(formData);
        serviceRequest$.subscribe((result: CWResult) => {
            // Falls Daten geladen wurden
            if (result.data['contacts'].length > 0) {
                /**
                 * Prüfe, ob die Daten des eintreffenden Requests auch
                 * zum ausgewählten Element passen.
                 */
                if (this.institutionsService.selectedInstitution.id != result.data['institution_id']) {
                    return;
                }
                // Falls sich die vorhandenen Grid-Daten von den neu geladenen Daten unterscheiden...
                if (JSON.stringify(this.data) != JSON.stringify(result.data['contacts'])) {
                    // Vorhandene Grid-Daten mit neu geladenen Daten erweitern
                    this.data = this.data.concat(result.data['contacts']);
                }

                // Multikontakte gruppieren
                if (this.enableMultiContactGrouping) {
                    this.groupMultiContacts();
                }

                // Funktion "Mehr laden..." wird wieder aktiviert
                this.loadMoreEnabled = true;
            } else {
                // Funktion "Mehr laden..." wird ausgeblendet, da es keine weiteren Daten mehr gibt
                this.loadMoreVisible = false;
            }

            // Nachdem Daten geladen wurden befindet sich das Modul immer in der normalen Ansicht
            this.editMode = false;
        });
    }

    /**
     * Klick auf "Abbrechen"
     */
    clickCancel(): void {
        // EditMode verlassen
        this.editMode = false;
    }

    /**
     * Daten zurücksetzen (z.B. wenn Einrichtung gewechselt wird)
     */
    resetData(): void {
        this.editMode = false;
        this.loadMoreEnabled = false;
        this.loadMoreVisible = true;
        this.gridPage = 1;
        this.data = [];

        // Checkboxen zurücksetzen
        this.checkedContacts = [];
        this.gridService.checkboxUncheckAll(this.gridId);
    }

    /**
     * Berechtigung "allowDeleteContacts" prüfen
     */
    private checkAllowDeleteContacts(): void {
        const permissionAllowDeleteContacts: boolean = this.userPermissions.getPermissionValue('allowDeleteContacts');
        this.allowDeleteContacts = permissionAllowDeleteContacts;

        // Spalte zum auswählen anzeigen
        if (permissionAllowDeleteContacts && this.gridDisplayedColumns.includes('select') === false) {
            this.gridDisplayedColumns.unshift('select');
        }
    }

    /**
     * Berechtigung "allowDeleteContacts" prüfen
     */
    /**
     * @brief Prüfen ob die Berechtigung "allowDeleteContacts" gesetzt ist
     * @author  Julia Zitzmann <j.zitzmann@pharmakon.software>
     */
    private checkAllowDeleteOwnContacts(): void {
        const permissionAllowDeleteOwnContacts: boolean =
            this.userPermissions.getPermissionValue('allowDeleteOwnContacts');
        this.allowDeleteOwnContacts = permissionAllowDeleteOwnContacts;
        // Spalte zum auswählen anzeigen
        if (permissionAllowDeleteOwnContacts && this.gridDisplayedColumns.includes('select') === false) {
            this.gridDisplayedColumns.unshift('select');
        }
    }

    /**
     * @brief Prüfen ob die Kontakte gelöscht werden dürfen und graut den Delete Button aus
     * @author  Julia Zitzmann <j.zitzmann@pharmakon.software>
     */
    private checkDeleteOwnContacts(): void {
        // initialisiert DeleteContactWithinDays
        if (this.userPermissions.getPermissionValue('allowEditContactFor')) {
            this.DeleteContactWithinDays = this.userPermissions.getPermissionValue('allowEditContactFor');
        }
        const disableDeleteButton = this.contactsService.checkDeleteUsersContacts(
            this.checkedContacts,
            this.employeeId,
            this.DeleteContactWithinDays,
        );

        if (disableDeleteButton) {
            this.disableDeleteButton = true;
        } else {
            this.disableDeleteButton = false;
        }
    }

    /**
     * @brief Holt sich die MitarbeiterID des aktuellen Benutzers
     * @author  Julia Zitzmann <j.zitzmann@pharmakon.software>
     */

    private getOwnUserEmployeeId() {
        this.storageService.getItem<User | null>('ownUser').then((result: User | null) => {
            if (result === null) {
                this.employeeId = 0;
            } else {
                this.employeeId = result.employee_id;
            }
        });
    }

    /**
     * Environment prüfen
     */
    private checkFeatureConfiguration(): void {
        // Environment "enableTicketContactType" prüfen
        if (hasOwn(environment, 'enableTicketContactType') && environment.enableTicketContactType !== true) {
            const index = this.addMenuData.findIndex((element) => element.name == 'ticket');
            this.addMenuData.splice(index, 1);
        }

        // Environment "enableAuthorColumn" prüfen
        if (
            hasOwn(environment, 'institutionsContactsDisplayedGridColumns') &&
            typeof environment.institutionsContactsDisplayedGridColumns !== 'undefined'
        ) {
            this.gridDisplayedColumns = environment.institutionsContactsDisplayedGridColumns;
        }
    }

    /**
     * Es wurde in der Toolbar auf "Neuer Kontakt" geklickt
     * @param event
     */
    onEventAddSpecificItem(event: CWEvent): void {
        if (event.target == 'institutionsContacts') {
            this.contactId = 0;
            this.contactType = event.data['specification'];
            this.editMode = true;
        }
    }

    /**
     * Environment "enableTicketContactType" prüfen
     */
    private checkAllowCreateInstitutionContact(): void {
        if (hasOwn(environment, 'allowNewInstitutionContact')) {
            this.allowNewInstitutionContact = environment.allowNewInstitutionContact;
            this.readonly = !environment.allowNewInstitutionContact;
        }
    }

    /**
     * Berechtigung "allowEditContactsFor" prüfen
     */
    private checkAllowEditContactsFor(): void {
        const permissionAllowEditContacts: number = this.userPermissions.getPermissionValue('allowEditContactFor');
        this.allowEditContacts = permissionAllowEditContacts;
    }

    /**
     * Entferne die Kontakt-Typ-Menü-Einträge, für die es keinen Listentry-
     * Eintrag gibt, oder für den der Nutzer keine Edit-Rechte hat.
     */
    private checkContactTypeMenuData() {
        const promise = this.storageService.getItem('listentries|contactType');

        promise.then((listentries: Listentry[]) => {
            if (listentries === null) {
                // @todo: Eigentlich wäre es hier Sinnvoller this.addMenuData = [] zu setzen.
                return;
            }

            for (const [key, menu] of this.addMenuData.entries()) {
                const listentry: Listentry = listentries.find((element: any) => element.list_key === menu.name);
                if (typeof listentry === 'undefined') {
                    this.addMenuData.splice(key, 1);
                    continue;
                }
                if (listentry.list_data === null || listentry.list_data === '') {
                    continue;
                }
                const listData = JSON.parse(listentry.list_data);
                if (hasOwn(listData, 'readonly') && listData.readonly === true) {
                    this.addMenuData.splice(key, 1);
                }
            }
        });
    }

    /**
     * @param selectedFilterItem
     * @param popupResult
     * @brief   Falls Filter-Dialog-Popup geschlossen wurde
     */
    onFilterDialogAnswer(selectedFilterItem, popupResult: any) {
        // Falls Antwort nicht auswertbar ist oder "Cancel" betätigt wurde, wird nichts weiter gemacht
        if (hasOwn(popupResult, 'answer')) {
            if (popupResult.answer == 'cancel' || popupResult.answer == 'no') {
                return;
            }
        } else {
            return;
        }

        // Prüft ob im Formular vom popup was steht > Die Filterdaten
        if (hasOwn(popupResult, 'formular')) {
            /*
             * Lösung für festen Kontaktfilter. @todo: raus sobald dort auch das reguläre FormularPopup verwendet wird
             * Objekt duplizieren, damit die Formularkonfiguration nicht
             * überschrieben wird und das Popup erneut geöffnet werden kann
             */
            const filterItemCopy = {...selectedFilterItem};
            // Formular Daten aus Ergebnis ziehen
            filterItemCopy.formular = popupResult.formular;
            this.targetFilter = filterItemCopy;
        } else if (hasOwn(selectedFilterItem, 'formular')) {
            /*
             * Objekt duplizieren, damit die Formularkonfiguration nicht
             * überschrieben wird und das Popup erneut geöffnet werden kann
             */
            const filterItemCopy = {...selectedFilterItem};
            // Formular mit Ergebnis überschreiben
            filterItemCopy.formular = popupResult;
            this.targetFilter = filterItemCopy;
            // Attribute, die nicht für den Filter notwendig sind, entfernen
            delete this.targetFilter.formular.answer;
            const filterKeys = Object.keys(this.targetFilter.formular);
            filterKeys.forEach((inputField: any) => {
                // "_input" in Attributnamen finden
                const hasInputName: boolean = inputField.includes('_input');
                // "_input" von Attributnamen entfernen
                const cleanAttributeName: string = inputField.replace('_input', '');
                /*
                 * Wenn der gefilterte Name bereits existiert, dann das Zusatzfeld aus dem Ergebnis löschen
                 * Wird hauptsächlich bei Jahres- und Monatseingabfeldern auftreten
                 */
                if (hasInputName && filterKeys.some((key: string) => key === cleanAttributeName)) {
                    delete this.targetFilter.formular[inputField];
                }
            });
        } else {
            // Gewählten Filter übergeben
            this.targetFilter = selectedFilterItem;
        }
        /**
         * Formular neu initialisieren mit zwischengespeicherten Werten
         *    ToDO?
         *    Attribute, die nicht ans Backend übergeben werden sollen, entfernen
         */
        Object.keys(this.targetFilter.formular).forEach((element: string) => {
            // "_value_label" in Attributnamen finden
            const hasInputName: boolean = element.includes('_value_label');
            if (hasInputName) {
                delete this.targetFilter.formular[element];
            }
        });
        /*
         * Daten zurücksetzen
         */
        this.resetData();
        // Filter aktivieren
        this.loadData();
    }

    /**
     * @brief Listeneinträge für Multikontakte gruppieren
     */
    private groupMultiContacts() {
        for (const [key, contact] of this.data.entries()) {
            // Prüfe ob ein Kontakt ein Multi-Kontakt ist
            if (contact.multi_contact_id !== null) {
                // Entferne alle Kontakte aus der Liste, welche die selbe multi_contact_id haben
                for (let i = this.data.length - 1; i > key; i--) {
                    if (contact.multi_contact_id === this.data[i].multi_contact_id) {
                        this.data.splice(i, 1);
                    }
                }
            }
        }
    }

    /**
     * Lade Frontend Config ob Multikontakte gruppiert werden sollen
     */
    checkMultiContactGrouping(): void {
        const promise = this.storageService.getItem('config|enableGroupContacts');
        promise.then((value) => {
            this.enableMultiContactGrouping = value;
        });
    }
}
