import {JsonPipe} from '@angular/common';
import {AfterContentInit, Component} from '@angular/core';
import {GridCellRenderer} from '@shared/grid-new/grid-new-config';
import {TableColumn} from '@shared/table-column';

@Component({
    selector: 'phscw-grid-template-plain',
    standalone: true,
    imports: [JsonPipe],
    templateUrl: './grid-template-plain.component.html',
    styleUrl: './grid-template-plain.component.scss',
})
export class GridTemplatePlainComponent implements GridCellRenderer<string> {
    value: string;
    column?: TableColumn<unknown>;
}
