// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Services
import {UserPermissionsService} from '@global/services/user-permissions.service';
// Service des übergeordneten Feature-Moduls
import {InstitutionsService} from '../institutions.service';
// Eigener Service
import {InstitutionsPeopleService} from './institutions-people.service';
// Service von Shared Modules
import {GridService} from '@shared/grid/grid.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
import {GridColumn} from '@shared/grid-column';
import {Person} from '@shared/person';
// Shared Komponenten
import {ContactsFormPopupComponent} from '@shared/contacts/contacts-form-popup/contacts-form-popup.component';
// Environment
import {environment} from '@environment';
import {hasOwn} from '@shared/utils';

@Component({
    selector: 'phscw-institutions-people',
    templateUrl: './institutions-people.component.html',
    styleUrls: ['./institutions-people.component.scss'],
})
export class InstitutionsPeopleComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscriptions zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ID der aktuell ausgewählten Einrichtung
    @Input() institutionId: number = null;

    // Modul-Daten (Personen der Einrichtung)
    data: any[] = [];

    // EditMode aktiv?
    @Input() editMode = false;

    // "Mehr laden..."
    loadMoreEnabled = false;
    loadMoreVisible = true;
    gridPage = 1;

    // Spaltendefinitionen für Grid (Spezial-Spalten werden direkt im Grid definiert)
    gridColumns: GridColumn[] = [
        {
            columnDef: 'person-id',
            header: 'ID',
            cell: (element: any) => `${element.id}`,
        },
        {
            columnDef: 'person-assignment-communication',
            header: 'Telefon',
            headerSecondRow: 'E-Mail',
            // cell: (element: Person) => `${element.phone1}<br>${element.mail}`
            cell: (element: Person) => `${element.phone1 || ''}<br>${element.mail || ''}`,
            formatWidth: '200px',
        },
        {
            columnDef: 'person-function',
            columnField: 'person_function',
            header: 'Funktion',
            cell: (element: Person) => `${element.person_function}`,
            formatTemplate: 'listentries',
            listentry: 'personFunction',
        },
        {
            columnDef: 'custom-field1',
            header: '',
            cell: (element: Person) => `${element.custom_field1 || ''}`,
        },
        {
            columnDef: 'custom-field2',
            header: '',
            cell: (element: Person) => `${element.custom_field2 || ''}`,
        },
        {
            columnDef: 'custom-field3',
            header: '',
            cell: (element: Person) => `${element.custom_field3 || ''}`,
        },
        {
            columnDef: 'custom-field4',
            header: '',
            cell: (element: Person) => `${element.custom_field4 || ''}`,
        },
    ];

    // Anzuzeigende Spalten für Grid
    gridDisplayedColumns = [
        'person-icon',
        'person-title-name',
        'person-assignment-communication',
        'person-function',
        'person-specialization',
    ];

    // Definiert, ob über die Toolbar eine neue Person zur Einrichtung hinzugefügt werden darf
    allowNewPerson = false;
    allowNewShadowPerson = false;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param dialog
     * @param userPermissions
     * @param institutionsService
     * @param institutionsPeopleService
     * @param gridService
     * @param translateService
     */
    constructor(
        private dialog: MatDialog,
        private userPermissions: UserPermissionsService,
        private institutionsService: InstitutionsService,
        private institutionsPeopleService: InstitutionsPeopleService,
        private gridService: GridService,
        private translateService: TranslateService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Übersetzungen subscriben
        this.initializeTranslateSubscriptions();

        // Events subscriben
        this.initializeEventSubscriptions();

        // Angezeigte Spalten laden
        this.initializeDisplayedGridColumns();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Übersetzungen subscriben
     * @details Subscribe auf Stream bekommt Änderung der Sprache mit
     *          und lädt Übersetzungen neu statt nur bei Initialisierung
     * @todo    Keys für stream() in Variable auslagern sobald von ngx-translate unterstützt wird
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeTranslateSubscriptions() {
        this.translateService
            .stream([
                'GENERAL.FUNCTION',
                'MODULES.INSTITUTIONS.PEOPLE.HEADERCUSTOMFIELD1',
                'MODULES.INSTITUTIONS.PEOPLE.HEADERCUSTOMFIELD2',
                'MODULES.INSTITUTIONS.PEOPLE.HEADERCUSTOMFIELD3',
                'MODULES.INSTITUTIONS.PEOPLE.HEADERCUSTOMFIELD4',
            ])
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((translation: string[]) => {
                this.gridColumns.find((column: any) => column.columnDef === 'custom-field1').header =
                    translation['MODULES.INSTITUTIONS.PEOPLE.HEADERCUSTOMFIELD1'];
                this.gridColumns.find((column: any) => column.columnDef === 'custom-field2').header =
                    translation['MODULES.INSTITUTIONS.PEOPLE.HEADERCUSTOMFIELD2'];
                this.gridColumns.find((column: any) => column.columnDef === 'custom-field3').header =
                    translation['MODULES.INSTITUTIONS.PEOPLE.HEADERCUSTOMFIELD3'];
                this.gridColumns.find((column: any) => column.columnDef === 'custom-field4').header =
                    translation['MODULES.INSTITUTIONS.PEOPLE.HEADERCUSTOMFIELD4'];
                this.gridColumns.find((column: any) => column.columnDef === 'person-function').header =
                    translation['GENERAL.FUNCTION'];
            });
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // In der E-Liste wird eine Einrichtung ausgewählt
        this.institutionsService.selectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: number) => {
                this.onSelectionChanged(result);
            });

        // Es wurde auf "Mehr laden..." geklickt
        this.gridService.eventGridPageCounterChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht für Komponente gesendet wurde
                if (event.target != 'institutionsPeople') {
                    return;
                }
                this.onEventGridPageCounterChanged(result);
            });

        // Es wurde auf "Favorit" geklickt
        this.gridService.eventGridFavoriteIconClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht von Komponente gesendet wurde
                if (event.sender != 'institutionsPeople') {
                    return;
                }
                this.onEventGridFavoriteIconClicked(event);
            });

        // Es wurde auf "Kontakt" geklickt
        this.gridService.eventGridContactIconClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht von Komponente gesendet wurde
                if (event.sender != 'institutionsPeople') {
                    return;
                }
                this.onEventGridContactIconClicked(event);
            });
    }

    /**
     * angezeigte Spalten aus environment laden
     */
    initializeDisplayedGridColumns(): void {
        // Einstellung aus Environment übernehmen
        if (
            hasOwn(environment, 'institutionsPeopleDisplayedColumnsCustomer') &&
            environment['institutionsPeopleDisplayedColumnsCustomer'].length > 0
        ) {
            this.gridDisplayedColumns = environment['institutionsPeopleDisplayedColumnsCustomer'];
        } else if (hasOwn(environment, 'institutionsPeopleDisplayedColumns')) {
            this.gridDisplayedColumns = environment['institutionsPeopleDisplayedColumns'];
        }
    }

    /**
     * In der E-Liste wurde eine (andere) Einrichtung ausgewählt
     * @param id
     */
    onSelectionChanged(id: number): void {
        // Bereits vorhandene Daten (einer anderen Einrichtung) entfernen
        this.resetData();
        // ID der aktuellen Einrichtung merken
        this.institutionId = id;
        // Daten laden, falls eine existente Einrichtung ausgewählt wurde
        if (this.institutionId > 0) {
            this.loadData();
        }
        // Prüfe, ob das Hinzufügen einer Person zu dieser Einrichtung erlaubt ist oder nicht
        this.checkAllowNewPerson();
    }

    /**
     * Es wurde auf "Mehr laden..." geklickt
     * @param event
     */
    onEventGridPageCounterChanged(event: CWEvent): void {
        this.gridPage = event.data['gridPageCounter'];
        this.loadData();
    }

    /**
     * Personen der Einrichtung laden
     */
    loadData(): void {
        // Funktion "Mehr laden..." wird (wieder) deaktiviert
        this.loadMoreEnabled = false;
        // Daten über Service anfragen
        const serviceRequest$ = this.institutionsPeopleService.loadData(this.institutionId, this.gridPage);
        serviceRequest$.subscribe((result: CWResult) => {
            // Falls Daten geladen wurden
            if (result['data']['people'].length > 0) {
                /**
                 * Prüfe, ob die Daten des eintreffenden Requests auch
                 * zur aktuell ausgewählten Einrichtung passen. Durch
                 * asynchrone Abfragen kann es nämlich passieren, dass
                 * zwischenzeitlich bereits die Einrichtung gewechselt wurde
                 * und die Antwort eines Requests verspätet eintrifft und
                 * dadurch die korrekten Daten wieder überschreibt.
                 */
                if (this.institutionsService.selectedInstitution && result['data']) {
                    if (this.institutionsService.selectedInstitution.id != result['data']['id']) {
                        return;
                    }
                }
                // Falls sich die vorhandenen Grid-Daten von den neu geladenen Daten unterscheiden...
                if (JSON.stringify(this.data) != JSON.stringify(result['data']['people'])) {
                    // Vorhandene Grid-Daten mit neu geladenen Daten erweitern
                    this.data = this.data.concat(result['data']['people']);
                }
                // Funktion "Mehr laden..." wird wieder aktiviert --- es sei denn, es wurden weniger als LIMIT-Datensätze geladen
                if (result['data']['people'].length < result['data']['limit']) {
                    this.loadMoreEnabled = false;
                    this.loadMoreVisible = false;
                } else {
                    this.loadMoreEnabled = true;
                }
            } else {
                // Funktion "Mehr laden..." wird ausgeblendet, da es keine weiteren Daten mehr gibt
                this.loadMoreVisible = false;
            }
        });
    }

    /**
     * Klick auf "Abbrechen"
     */
    clickCancel(): void {
        // EditMode verlassen
        this.editMode = false;
    }

    /**
     * Daten zurücksetzen (z.B. wenn Einrichtung gewechselt wird)
     */
    resetData(): void {
        this.loadMoreEnabled = false;
        this.loadMoreVisible = true;
        this.gridPage = 1;
        this.data = [];
    }

    /**
     * Prüfe, ob das Hinzufügen einer Person zu dieser Einrichtung erlaubt ist
     */
    checkAllowNewPerson(): void {
        const permissionAllowNewPerson: boolean = this.userPermissions.getPermissionValue('allowNewPerson');
        if (this.institutionId > 0 && permissionAllowNewPerson == true) {
            this.allowNewPerson = true;
        } else {
            this.allowNewPerson = false;
        }
        const allowNewShadowPerson: boolean = this.userPermissions.getPermissionValue('allowNewShadowPerson');
        if (this.institutionId > 0 && allowNewShadowPerson == true) {
            this.allowNewShadowPerson = true;
        } else {
            this.allowNewShadowPerson = false;
        }
    }

    /**
     * Es wurde auf "Favorit" geklickt
     * @param event
     */
    onEventGridFavoriteIconClicked(event: CWEvent): void {
        // Backend-Request absenden zum Speichern
        const serviceRequest$ = this.institutionsPeopleService.setFavorite(
            this.institutionId,
            event.data.id,
            event.data.newFavoriteValue,
        );
        serviceRequest$.subscribe((result: CWResult) => {
            /**
             * Prüfe, ob die Daten des eintreffenden Requests auch
             * zur aktuell ausgewählten Einrichtung passen. Durch
             * asynchrone Abfragen kann es nämlich passieren, dass
             * zwischenzeitlich bereits die Einrichtung gewechselt wurde
             * und die Antwort eines Requests verspätet eintrifft und
             * dadurch die korrekten Daten wieder überschreibt.
             */
            if (
                this.institutionId != result['data']['institution_id'] &&
                event.data.id != result['data']['person_id']
            ) {
                return;
            }

            // Falls Änderungen gespeichert werden konnten
            if (result['success']) {
                // Daten durchlaufen und Favorit ändern
                this.data.forEach((element: any) => {
                    if (element.id === event.data.id) {
                        element.favorite = !element.favorite;
                    } else {
                        element.favorite = false;
                    }
                });
            } else {
                /*
                 * Fehlernachricht anzeigen
                 * @todo: Fehleranzeige programmieren
                 */
            }
        });
    }

    /**
     * Es wurde auf "Kontakt" geklickt
     * @param event
     */
    onEventGridContactIconClicked(event: CWEvent): void {
        // ID aus Event nehmen
        const clickedRowId = event.data.id;
        const sampleAuthorization: boolean = event.data.sampleAuthorization;

        // Dialog öffnen
        this.openContactDialog(clickedRowId, sampleAuthorization);
    }

    /**
     * @param personId
     * @param sampleAuthorization
     * @brief   Dialog öffnen für Kontaktformular
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    openContactDialog(personId: number, sampleAuthorization: boolean): void {
        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(ContactsFormPopupComponent, {
            height: 'auto',
            maxHeight: 'calc(100vh - 125px)',
            width: '900px',
            data: {
                personId,
                institutionId: this.institutionId,
                sampleAuthorization,
            },
        });

        // Auf das Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe((result: any) => {});
    }
}
