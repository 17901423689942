import {Component} from '@angular/core';
import {GridCellRenderer} from '@shared/grid-new/grid-new-config';

@Component({
    selector: 'phscw-grid-template-date',
    standalone: true,
    imports: [],
    templateUrl: './grid-template-date.component.html',
    styleUrl: './grid-template-date.component.scss',
})
export class GridTemplateDateComponent implements GridCellRenderer<Date> {
    value: Date;
}
