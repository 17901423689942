// Angular-Module
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
// Angular-Material
import {MatDialog} from '@angular/material/dialog';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subscription} from 'rxjs';
// GridComponent
import {GridComponent} from './../grid/grid.component';
// Service dieses Shared-Moduls
import {GridService} from './../grid.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {SelectData} from '@shared/select-data';
// Globalen Service einbinden
import {StorageService} from '@global/services/storage.service';
// Shared COmponents einbinden
import {PopupConfirmationComponent} from '@shared/popups/popup-confirmation/popup-confirmation.component';
import {PopupMessageComponent} from '@shared/popups/popup-message/popup-message.component';
// Environment
import {environment} from '@environment';

@Component({
    selector: 'phscw-grid-layout',
    templateUrl: './grid-layout.component.html',
    styleUrls: ['./grid-layout.component.scss'],
})
export class GridLayoutComponent implements OnInit, OnDestroy {
    // Referenzen auf Subject-Subscriptions
    private _subscriptions = new Subscription();

    /**
     * *************************************************************************
     * Parameter, welche beim Einbinden der Komponente gesetzt werden
     *************************************************************************
     */
    // Daten für Selectbox
    private _gridLayouts: SelectData[];

    get gridLayouts(): SelectData[] {
        return this._gridLayouts;
    }

    @Input() set gridLayouts(value: SelectData[]) {
        if (value instanceof Array) {
            if (value.length > 0) {
                // Grid-Layout setzen
                this._gridLayouts = value;
                // Änderungen des Grid-Layouts durchführen - bewirkt das Neuladen des Grid(!)
                this.changeGridLayout(this.currentLayout);
            }
        }
    }

    // Referenz auf verbundene Grid-Komponente, da Layout-Selectbox nicht ohne ein verbundenes Grid funktionieren kann
    @Input() gridConnection: GridComponent;
    // ID des (verbundenen) Grids
    @Input() gridId = '';
    // Ausgewähltes Layout
    @Input() currentLayout = 1;

    // Variable zum Speichern von Geladenen Kennzeichengruppen
    private characteristicGroups: any = {};

    // Geht auf false, sobald einmal Daten geladen wurden
    private firstLoad = true;

    // Konstruktor
    constructor(
        private gridService: GridService,
        private storageService: StorageService,
        private dialog: MatDialog,
        private translateService: TranslateService,
    ) {}

    // Initialisierungen
    ngOnInit() {
        // Prüfe Anforderungen
        this.checkRequirements();
        // Events subscriben
        this.initializeEventSubscriptions();
        // Initiale Auswahl in Selectbox
        this.changeGridLayout(this.currentLayout);
    }

    // Aufräumen
    ngOnDestroy() {
        this._subscriptions.unsubscribe();
    }

    // Prüfe Anforderungen
    checkRequirements() {
        /*
         * Grid-Layout benötigt gridLayouts
         * 2018-08-03, PhS(MFe): Deaktiviert, da gridLayouts nun asynchron geladen werden und zu diesem Zeitpunkt nocht nicht vorliegen
         */
        /*
         * if (typeof this.gridLayouts == 'undefined') {
         *    console.error('Pharmakon - GridLayoutComponent bekam keine gridLayouts zugewiesen!');
         * }
         */
        // Grid-Layout benötigt ein verbundenes Grid
        if (typeof this.gridConnection === 'undefined') {
            console.error(
                'Pharmakon - GridLayoutComponent verfügt über kein verbundenes Grid und kann deshalb nicht funktionieren. Bitte beim Einbinden der Komponente die [gridConnection] setzen!',
            );
        }
    }

    // Events subscriben
    initializeEventSubscriptions() {
        // Layout wird geändert
        this._subscriptions.add(
            this.gridService.eventGridChangeLayout.subscribe((result) => {
                // Hier wird absichtlich nicht geprüft, ob das Layout schon in gridLayouts existiert. Da diese Event vor der Änderung der gridLayouts ankommt.
                this.currentLayout = result['data']['layoutId'];
            }),
        );
        // Layout wird geändert nachdem die Daten neu geladen wurden
        this._subscriptions.add(
            this.gridService.eventGridChangeLayoutAfterReload.subscribe((result) => {
                this.changeGridLayout(result['data']['layoutId'], true);
            }),
        );
        // Layout soll gelöscht werden
        this._subscriptions.add(
            this.gridService.eventGridLayoutDeleted.subscribe((result: CWEvent) => {
                if (result.target !== this.gridId) {
                    return;
                }
                this.onDeleteGridLayout();
            }),
        );
    }

    // Auswahl in Selectbox wird geändert
    changeGridLayout(selectedEntry: number, dataReloaded = false) {
        /**
         * Falls gridLayout direkt im Code (TS) vorgegeben wurde und nicht
         * asynchron vom Backend geladen wird, kann es passieren, dass hier
         * die gridConnection noch nicht gesetzt ist.
         * Ohne existente gridConnection wird die Funktion verlassen.
         */
        if (typeof this.gridConnection === 'undefined') {
            return;
        }
        /**
         * Falls gridLayouts (noch) nicht vorhanden sind, da diese z.B. erst
         * asynchron geladen werden müssen, wird die Funktion verlassen.
         */
        if (typeof this.gridLayouts === 'undefined') {
            return;
        }

        // Layout über ID ermitteln
        const selectedGridLayout: any = this.gridLayouts.find((entry) => entry.id == selectedEntry);
        // Falls es keinen Eintrag gibt, weil das Layout für eine andere Rolle gespeichert wurde, bleibe im aktuellen Layout
        if (typeof selectedGridLayout === 'undefined') {
            // Setze das currentLayout zurück auf in gridConnection gespeicherte Layout
            this.currentLayout = this.gridConnection.selectedLayout;
            return;
        }

        // ID des in Selectbox ausgewählten Layouts
        this.gridConnection.selectedLayout = selectedEntry;

        const frontendArray = JSON.parse(selectedGridLayout['frontend_array']);
        // Darf das Layout gelöscht werden // Nur typunsicherer Vergleich, da je nach DB unterschiedliche Werte (1 oder '1') zurückkommen
        let allowDeletion = false;
        if (
            typeof selectedGridLayout['allow_deletion'] !== 'undefined' &&
            selectedGridLayout['allow_deletion'] == '1'
        ) {
            allowDeletion = true;
        }

        this.checkChangedCharacteristics(frontendArray, {
            selectedEntry,
            selectedGridLayout,
            dataReloaded,
            allowDeletion,
        });
    }

    // Fortsetzung von preparGridLayoutFrontend, mit übergebenen Bereinigten FrontendArray
    changeGridLayoutFrontend(
        selectedEntry: number,
        selectedGridLayout: any,
        // eslint-disable-next-line @typescript-eslint/default-param-last
        dataReloaded = false,
        frontendArray: any[],
        allowDeletion = false,
    ) {
        const list = this.gridId.replace('List', '');
        let foreignColumnFound = false;
        // Prüfe, ob Spalten einer fremden Liste ausgewählt wurden
        for (let i = 0; i < frontendArray.length && !foreignColumnFound; i++) {
            if (frontendArray[i]['target'] !== list) {
                foreignColumnFound = true;
            }
        }

        if ((foreignColumnFound || this.firstLoad) && !dataReloaded) {
            // Beim allerersten Layoutwechsel müssen Daten geladen werden, auch wenn keine "fremden" Spalten im Layout sind
            this.firstLoad = false;

            // Falls "fremde" Spalten ausgewählt wurden, benachrichtige die gewählte Liste um dort zu prüfen, ob diese Spalten bereits geladen wurden
            const data = {
                frontendArray,
                layoutId: selectedEntry,
            };

            this.gridService.reloadData(this.gridId, data);
        } else if (!foreignColumnFound || dataReloaded) {
            // Falls keine "fremden" Spalten gewählt bzw. die Daten bereits neu geladen wurden, wechsle das Layout

            this.gridService.columnChoiceChanged(this.gridId, frontendArray);
            selectedGridLayout.data.length = 0;
            if (selectedGridLayout['module_name'] === 'institutions') {
                const prependColumns = environment.institutionsPrependDisplayedColumns;
                selectedGridLayout.data.push(...prependColumns);
            } else if (selectedGridLayout['module_name'] === 'people') {
                const prependColumns = environment.peoplePrependDisplayedColumns;
                selectedGridLayout.data.push(...prependColumns);
            }
            for (let i = 0; i < frontendArray.length; i++) {
                selectedGridLayout.data.push(frontendArray[i]['id']);
            }
            if (selectedGridLayout['module_name'] === 'institutions') {
                const appendColumns = environment.institutionsAppendDisplayedColumns;
                selectedGridLayout.data.push(...appendColumns);
            } else if (selectedGridLayout['module_name'] === 'people') {
                const appendColumns = environment.peopleAppendDisplayedColumns;
                selectedGridLayout.data.push(...appendColumns);
            }
            // Layout an verbundenes Grid weitergeben
            this.gridConnection.gridDisplayedColumns = selectedGridLayout.data;
            // ColumnsPanel informieren, damit dort die korrekten Spalten angezeigt werden
            this.gridService.columnsChanged(this.gridId, frontendArray, allowDeletion);
            // Liste informieren, damit aktuelles Layout in UserSettings gespeichert wird
            this.gridService.layoutChanged(this.gridId, this.currentLayout);
        }
    }

    /**
     * Funktion mit der Überprüft werden soll, ob sich die Ausprägungen von Kennzeichenspalten
     * geändert haben.
     * @details Es wird zunächst sichergestellt, dass alle Kennzeichengruppen im Arbeitsspeicher vorliegen (lazy loading aus dem Storage).
     *          Sobald alle Gruppen geladen wurden, wird die Vorbereitung des Grid-Layouts übergeben.
     * @param   any[]   frontendArray    Array mit den Spaltendefinitionen
     * @param frontendArray
     * @param parameters
     * @param firstCall
     * @param   any     parameters       Parameter-Objekt, das an changeGridLayoutFrontend weitergereicht werden soll
     * @param   boolean firstCall        Bool-Wert, ob es sich um den ersten Aufruf handelt
     * @author Michael Schiffner <m.schiffner@pharmakon.software>
     * @author Tristan Krakau <t.krakau@pharmakon.software>
     * @todo  Die ganze Spaltenaufbereitung in separate Klassen/Services auslagern, da das letztendlich das Laden der Daten im Grid steuert und auslöst.
     */
    checkChangedCharacteristics(frontendArray: any[], parameters: any, firstCall = true) {
        // Aktuell verwendete eindeutige Gruppen ermitteln und alle benötigten Kennzeichengruppen zunächst in den Speicher laden, falls noch nicht vorhanden
        const loadColumnGroupsPromises: Promise<any>[] = frontendArray
            .filter((columnDefinition) => columnDefinition.target === 'characteristics')
            .map((columnDefinition) => columnDefinition.columnGroup)
            .filter((n, i, columnGroupArray) => columnGroupArray.indexOf(n) === i) // distinct values
            .filter(
                (columnGroup) => typeof this.characteristicGroups[columnGroup] === 'undefined' ||
                this.characteristicGroups[columnGroup] === null,
            ) // noch nicht geladene Groups
            .map((columnGroup) => this.storageService
                .getItem('characteristicsForGroup|' + columnGroup)
                .then((val) => (this.characteristicGroups[columnGroup] = val))); // Groups laden

        /*
         * Es wird nach Abschluss aller Promises weiter gemacht mit der Vorbereitung der Layout-Spalten, damit kein mehrfacher Aufruf des Reload verursacht wird.
         * Das funktioniert auch, wenn alle Gruppen schon geladen sind, dann wird auf 0 Promises gewartet, also gleich then() aufgerufen.
         */
        Promise.all(loadColumnGroupsPromises)
            .then((val) => this.preparGridLayoutFrontend(
                parameters.selectedEntry,
                parameters.selectedGridLayout,
                parameters.dataReloaded,
                frontendArray,
                parameters.allowDeletion,
            ))
            .catch((error) => console.error('Fehler beim Laden der Kennzeichengruppen aus dem Storage'));
    }

    /**
     * Bereitet Kennzeichenspalten für die Anmzeige vor, aktualisiert Spaltenüberschriften etc.
     * @param selectedEntry
     * @param selectedGridLayout
     * @param dataReloaded
     * @param frontendArray
     * @param allowDeletion
     */
    preparGridLayoutFrontend(
        selectedEntry: number,
        selectedGridLayout: any,
        // eslint-disable-next-line @typescript-eslint/default-param-last
        dataReloaded = false,
        frontendArray: any[],
        allowDeletion = false,
    ) {
        // Definiert, ob die changeGridLayoutFrontend-Funktion aufgerufen werden darf.
        let allowLayoutChange = true;

        // Gehe jede Spalte durch
        for (const columnDefinition of frontendArray) {
            // Wenn die Spalte kein Kennzeichen aufruft ignoriere Sie
            if (columnDefinition.target !== 'characteristics') {
                continue;
            }
            // Sonst prüfe, ob die Daten für die Zugehörige Kennzeichengruppe schon aus der IndexedDB geladen wurden
            if (
                typeof this.characteristicGroups[columnDefinition.columnGroup] !== 'undefined' &&
                this.characteristicGroups[columnDefinition.columnGroup] !== null
            ) {
                const characteristicGroup: any[] = this.characteristicGroups[columnDefinition.columnGroup];

                // Wenn die Kennzeichengruppe gelöscht wurde / nicht mehr existiert, zeige dies dem Nutzer an
                if (typeof characteristicGroup === 'undefined') {
                    continue;
                }

                // Wenn Ja suche die Daten für das entsprechende Kennzeichen
                const singleCharacteristic = characteristicGroup.find(
                    (characteristic) => characteristic.id === columnDefinition.characteristic_id,
                );

                // Wenn das Kennzeichen gelöscht wurde / nicht mehr existiert, zeige dies dem Nutzer an
                if (typeof singleCharacteristic === 'undefined') {
                    // Überschreibe den Kennzeichennamen
                    columnDefinition.label = 'Das Kennzeichen "' + columnDefinition.label + '" wurde gelöscht';
                    continue;
                }

                // Überschreibe den Kennzeichennamen
                columnDefinition.label = singleCharacteristic.label;
                // Überschreibe die Ausprägung falls existent
                if (columnDefinition.value_type === 'options') {
                    columnDefinition.options = singleCharacteristic.options;
                }
            } else {
                // This "should" never happen...
                console.warn(
                    'Still missing characteristics of group ' +
                    columnDefinition.columnGroup +
                    ' - for characteristic ' +
                    columnDefinition.id,
                );

                // Verhindere dass die changeGridLayoutFrontend-Funktion aufgerufen wird
                allowLayoutChange = false;

                break;
            }
        }

        // Wenn alle Spalten erfolgreich überprüft wurden
        if (allowLayoutChange) {
            // ... leite das Frontendarray weiter an die changeGridLayoutFrontend-Funktion
            this.changeGridLayoutFrontend(
                selectedEntry,
                selectedGridLayout,
                dataReloaded,
                frontendArray,
                allowDeletion,
            );
        }
    }

    onDeleteGridLayout() {
        // Den aktuell gewählten Eintrag finden
        const index = this.gridLayouts.findIndex((source) => source['id'] == this.currentLayout);
        let layout: any;
        if (index !== -1) {
            layout = this.gridLayouts[index];
        }
        // Wenn dieser existiert ...
        if (layout !== undefined) {
            // ... erstelle die postParameter für die Löschfunktion
            const postObject = {
                id: layout.id,
                label: layout.label,
                // das 'List' aus der gridId wird replaced, damit man 'institutions' oder 'people' etc. bekommt
                module: this.gridId.replace('List', ''),
                // Initial wird davon ausgegangen, dass eine nicht freigegebene Selektion gelöscht wird.
                deleteSingleLayout: true,
            };
            // Default-Nachricht für das Löschpopup
            let message = 'SHARED.GRID.COLUMNS.POPUP.DELETIONTEXT';

            // Wenn das Layout freigegeben wurde ...
            if (typeof layout['cleared_layout'] !== 'undefined' && layout['cleared_layout'] == '1') {
                // ... informiere das Backend, dass die freigegebenen Layouts gelöscht werden müssen
                postObject.deleteSingleLayout = false;
                // Andere Nachricht, wenn das Layout für freigegebne Layouts gelöscht werden soll
                message = 'SHARED.GRID.COLUMNS.POPUP.DELETIONCLEAREDLAYOUTTEXT';
            }

            // Dialog konfigurieren und öffnen
            const dialogRef = this.dialog.open(PopupConfirmationComponent, {
                width: '350px',
                data: {
                    title: this.translateService.instant('SHARED.GRID.COLUMNS.POPUP.DELETIONHEADER'),
                    message: this.translateService.instant(message),
                },
            });

            // Auf das Schließen des Dialogs reagieren
            dialogRef.afterClosed().subscribe((result) => {
                this.deleteLayout(result, index, postObject);
            });
        }
    }

    /**
     * Lösche das Layout
     * @param result
     * @param index
     * @param postObject
     */
    deleteLayout(result: any, index: number, postObject: any) {
        if (result.answer !== 'yes') {
            return;
        }

        // Lösche die Daten
        const backendRequest$ = this.gridService.deleteLayout(postObject);
        backendRequest$.subscribe((result: any) => {
            if (result.success) {
                // Aus den gridLayouts (Selektionsmöglichkeiten) entfernen
                this.gridLayouts.splice(index, 1);
                /**
                 * Layout auf den ersten nicht gelöschten Filter ändern
                 */
                this.currentLayout = this.gridLayouts[0]['id'];
                this.changeGridLayout(this.currentLayout);
            } else {
                // Dialog konfigurieren und öffnen
                this.dialog.open(PopupMessageComponent, {
                    width: '350px',
                    data: {
                        title: this.translateService.instant('SHARED.GRID.COLUMNS.POPUP.DELETIONFAILED'),
                        message: this.translateService.instant('SHARED.GRID.COLUMNS.POPUP.DELETIONFAILED'),
                    },
                });
            }
        });
    }
}
