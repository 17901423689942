// single-icon-renderer.component.ts

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IconType} from '@shared/icon-type';

/**
 * @description
 * Komponente zur Darstellung von Icons basierend auf dem Icon-Typ
 * @see IconType
 */
@Component({
    selector: 'phscw-single-icon-renderer',
    templateUrl: './single-icon-renderer.component.html',
    styleUrls: ['./single-icon-renderer.component.scss'],
})
export class SingleIconRendererComponent {
    /**
     * Der Name des icons
     * Wird bei Iconfont-Icons als Klasse benutzt
     */
    @Input({required: true}) icon: string;

    /**
     * Der Tooltip-Text, der beim Hover über das icon angezeigt wird.
     */
    @Input() tooltip: string;

    /**
     * Zusätzliche CSS-Klassen für das icon
     */
    @Input() iconClass: string = '';

    /**
     * Der Typ des icons
     * Bestimmt, wie das Icon gerendert wird
     */
    @Input() iconType: IconType = IconType.FontAwesome;

    /**
     * Benutzerdefinierter Pfad zum SVG-Icon. Überschreibt den Standardpfad
     * Hier nur den Ordner angeben, in dem die SVG-Datei liegt
     */
    @Input() customIconPathAssets: string = '';

    /**
     * @description
     * Event-Emitter, der ausgelöst wird, wenn das Icon angeklickt wird.
     */
    @Output() iconClicked: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Für die Verwendung im template
     */
    readonly IconType = IconType;

    /**
     * Der Standardpfad zu den SVG-Icons
     */
    defaultAssetsIconPath: string = 'assets/img/icons/';

    /**
     * Konstruktiert den Pfad zum SVG-Icon
     * Wenn ein eigener Pfad mitgegeben wird, wird dieser benutzt
     * @returns {string} Pfad zum SVG-Icon
     */
    get iconPath(): string {
        if (this.customIconPathAssets) {
            return `${this.customIconPathAssets}${this.icon}.svg`;
        }
        return `${this.defaultAssetsIconPath}${this.icon}.svg`;
    }

    /**
     * Entfernt ggf. den 'fa-'-Praefix aus dem FontAwesome Icon-Name
     * @returns {string} Icon-Name
     */
    get faIcon(): string {
        if (!this.icon.startsWith('fa-')) {
            return this.icon;
        }

        return this.icon.replace('fa-', '');
    }

    /**
     * Event auslösen, wenn das Icon geklickt wird
     */
    handleClick(): void {
        this.iconClicked.emit();
    }
}
