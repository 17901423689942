/* eslint-disable no-console */
/**
 * @description   Globaler Service als Abstraktionsschicht der Authentifizierung.
 *                Verwendet BackendService zum Senden von HTTP-Requests.
 *          Token wird über TokenStorageService verwaltet.
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {Injectable} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// Globale Services
import {BackendService} from './backend.service';
import {InitService} from './init.service';
import {TokenStorageService} from './token-storage.service';
import {UserPermissionsService} from './user-permissions.service';
import {UserSettingsService} from './user-settings.service';
import {takeUntil} from 'rxjs/operators';
// Moment.js
import {hasOwn} from '@shared/utils';
import * as _moment from 'moment';
import {TitleService} from './title.service';

const moment = _moment;

@Injectable({
    // Root-Injector (app.module.ts) ist verantwortlich für das Instanziieren dieses globalen Services
    providedIn: 'root',
})
export class AuthService {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    #componentDestroyed$ = new Subject<void>();

    // Aktuelle Sprache
    languageKey = '';

    // URL speichern um nach Login weiterleiten zu können
    redirectUrl: string;
    get isLoggedIn(): boolean {
        // Token und TokenExpiresAt vorhanden? Token noch gültig?
        if (this.tokenStorageService.token && this.tokenStorageService.refreshToken) {
            // User ist eingeloggt
            return true;
        }

        // Standard (Nicht eingeloggt, kein gültiges oder abgelaufenes Token)
        return false;
    }

    // Konstruktor (inkl. dependency injection)
    constructor(
        private router: Router,
        private translate: TranslateService,
        private backendService: BackendService,
        private initService: InitService,
        private tokenStorageService: TokenStorageService,
        private userPermissionsService: UserPermissionsService,
        private userSettingsService: UserSettingsService,
        private route: ActivatedRoute,
        private titleService: TitleService,
    ) {
        this.languageKey = this.translate.currentLang;
    }

    /**
     * @param {object} credentials - Benutzername und Passwort
     * @description   Login des Benutzers
     *  Erhält credentials und sendet diese über BackendService als POST-Request.
     *  Reagiert selbst auf das Ergebnis des POST-Requests, leitet
     *  diesen aber auch zur Auswertung an die aufrufende Komponente weiter.
     * @returns {Observable<any>} - Observable mit Ergebnis des POST-Requests
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    login(credentials: object): Observable<any> {
        // POST-Request mit Login-Credentials über BackendService senden
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const postRequest$: Observable<any> = this.backendService.postRequest('users/login', credentials);
        // AuthService subscribed den definierten Post-Request, wodurch dieser ausgelöst wird.
        postRequest$.subscribe(
            // onNext
            (result) => {
                // Login erfolgreich?
                if (result['success']) {
                    // LOGIN ERFOLGREICH
                    this.onSuccessfulLogin(result);
                }
            },
            // onError
            (error) => {
                // Keine Antwort vom Backend erhalten (Servererror)
                console.error('AuthService#login.subscription - Server-Error!');
                console.error(error);
            },
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @param {any} code - ?
     * @description Weiterreichen des Token ans Backend um zu prüfen ob Microsoft es ausgestellt hat und welche Email
     * @author  Nick Mühlberger <n.muehlberger@pharmakon.software> Eric Haeussel <e.haeusel@pharmakon.software>
     * @returns {Observable<any>} - Observable mit Ergebnis des POST-Requests
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ssoLogin(code: any): Observable<any> {
        const codeObject = {code};
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const authServiceLogin$: Observable<any> = this.backendService.postRequest('users/loginSSO', codeObject);
        authServiceLogin$.subscribe(
            // onNext
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (result: any) => {
                // Login fehlgeschlagen?
                if (result.success) {
                    this.onSuccessfulLogin(result, true);
                } else {
                    // Zur Loginseite wechseln
                    this.router.navigate(['/login']);
                }
            },
            // onError
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (error: any) => {
                // Keine Antwort vom Backend erhalten (Servererror)
                console.error('AuthService#ssoLogin.subscription - Server-Error!');
                console.error(error);
            },
        );
        return authServiceLogin$;
    }

    /**
     * @param {any} result - Ergebnis des Login-Requests
     * @param {boolean} skipRedirect - Soll nach Login weitergeleitet werden?
     * @description   Initialisierungen nach erfolgreichem Login
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccessfulLogin(result: any, skipRedirect: boolean = false) {
        // Authentifizierungs-Token setzen
        this.tokenStorageService.token = result['data']['token']['tokenId'];
        this.tokenStorageService.refreshToken = result['data']['refreshToken']['tokenId'];
        this.tokenStorageService.username = result['data']['userData']['username'];

        // Benutzer-Einstellungen speichern (über userSettingsService)
        this.userSettingsService.storeManySettings(result['data']['userSettings']);
        // Benutzer-Berechtigungen in AppCore ablegen
        this.userPermissionsService.storeManyPermissions(result['data']['userPermissions']);

        // Sprache setzen
        if (hasOwn(result['data']['userData'], 'language_key') && result['data']['userData']['language_key'] !== null) {
            // Sprache des Benutzers einstellen --> NGX-Translate
            this.translate.use(result['data']['userData']['language_key']);
            // Sprache des Benutzers einstellen --> moment.js
            moment.locale(result['data']['userData']['language_key'].substring(0, 2));
        } else {
            this.translate.use('deu');
            moment.locale('de');
        }

        // Initialisierungs-Service aufrufen (zum asynchronen Laden der Core-Daten)
        this.initService.initializeAll();
        this.redirectOnSuccess(skipRedirect);

        // Seiten-Titel setzen
        this.titleService.setWindowTitle();
    }

    // Im Erfolgsfall ins system redirecten
    public redirectOnSuccess(skipRedirect) {
        const redirect = this.route.snapshot.queryParams['redirect'];

        // ... und nach Prüfung der Berechtigung...
        const presetInitModule: string = this.userPermissionsService.getPermissionValue('presetInitModule');
        if (redirect && !skipRedirect) {
            this.router.navigate([redirect]);
        } else if (presetInitModule && presetInitModule.length > 0) {
            // ...wird auf "presetInitModule" weitergeleitet
            this.router.navigate([presetInitModule.toLowerCase()]);
        } else {
            // ...wird auf "lastModule" weitergeleitet
            const lastModule: string = this.userSettingsService.getValue('lastModule');
            this.router.navigate([lastModule]);
        }
    }

    /**
     * @description Logout des Benutzers
     *  Leert Local-Storage (und dadurch auch JWT)
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    logout(): void {
        this.tokenStorageService.token = null;
        this.tokenStorageService.refreshToken = null;
        this.tokenStorageService.username = null;
        // LocalStorage komplett leeren
        localStorage.clear();
        // Session-Storage komplett leeren
        window.sessionStorage.clear();
        window.localStorage.clear();

        // Seiten-Titel zurücksetzen
        this.titleService.resetWindowTitle();
    }

    /**
     * Marks refresh Token as used in a logout
     */
    invalidateRefreshToken() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const authServiceLogout$: Observable<any> = this.backendService.postRequest(
            'users/invalidateRefreshToken',
            {refreshToken: this.tokenStorageService.refreshToken},
        );

        authServiceLogout$
            .pipe(takeUntil(this.#componentDestroyed$))
            .subscribe(() => {});
    }
}
