import {
    ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild,
} from '@angular/core';
import {LooseObject} from '@shared/loose-object';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Service von Shared Modules
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// Eigener Service
import {OrderFormService} from '../order-form.service';
// Interfaces für Structured Objects einbinden
import {MatDialog} from '@angular/material/dialog';
import {UserDataService} from '@global/services/user-data.service';
import {OrderDto} from '@modules/institutions/institutions-orders2/institutions-orders2.model';
import {TranslateService} from '@ngx-translate/core';
import {CWEvent} from '@shared/cw-event';
import {OrderFormFooterComponent} from '@shared/order-form/order-form/order-form-footer/order-form-footer.component';
import {OrderFormHeaderComponent} from '@shared/order-form/order-form/order-form-header/order-form-header.component';
import {OrderFormItemsComponent} from '@shared/order-form/order-form/order-form-items/order-form-items.component';
import {PopupConfirmationComponent} from '@shared/popups/popup-confirmation/popup-confirmation.component';
import {PopupMessageComponent} from '@shared/popups/popup-message/popup-message.component';
import {User} from '@shared/user';
import {hasOwn, notEmpty, notEmptyObject, toSum} from '@shared/utils';

@Component({
    selector: 'phscw-order-form',
    templateUrl: './order-form.component.html',
    styleUrls: ['./order-form.component.scss'],
})
export class OrderFormComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // ID der aktuell ausgewählten Einrichtung
    institutionId: number;
    // ID des aktuell ausgewählten Auftrags
    orderId: number;

    @Input() set institutionAndOrderId(value) {
        if (typeof value !== 'undefined') {
            this.orderId = value['orderId'];
            this.institutionId = value['institutionId'];
            if (this.orderId > 0) {
                // wenn Order aus Grid gewählt wurde, ist eine ID vorhanden
                this.loadDetails();
            } else {
                // einen neuen Auftrag erstellen
                this.createOrder();
            }
        }
    }

    @Input() editMode = false;

    // Flag definiert ob gerade geladen wird
    loading = false;
    // Flag definiert ob gerade gespeichert wird
    saving = false;

    @Input() showClose = true;

    // Event-Emitter der die Übergeordnete Komponente benachrichtigt wenn der DetailsMode verlassen werden soll
    @Output() changeShowOrderDetailsMode = new EventEmitter<boolean>();

    // Zusätzlicher Event-Emitter, der die übergeordnete Komponente benachrichtigt, wenn das Formular submitted wird.
    @Output() submitOrder = new EventEmitter<boolean>();

    // Ausgewählte Aufträge der Einrichtung
    dataItem: LooseObject = {};
    // Kopie zum resetten des dataItems;
    dataItemCopy: LooseObject = {};

    // Referenzen auf die Sub-Formulare halten, um beim Speichern die Daten einzusammeln
    @ViewChild(OrderFormHeaderComponent) headerForm: OrderFormHeaderComponent;
    @ViewChild(OrderFormItemsComponent) itemsForm: OrderFormItemsComponent;
    @ViewChild(OrderFormFooterComponent) footerForm: OrderFormFooterComponent;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param toolbarService
     * @param orderFormService
     * @param userDataService
     * @param dialog
     * @param translateService
     * @param changeDetectorRef
     */
    constructor(
        private toolbarService: ToolbarService,
        public orderFormService: OrderFormService,
        private userDataService: UserDataService,
        private dialog: MatDialog,
        private translateService: TranslateService,
        protected changeDetectorRef: ChangeDetectorRef,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Ordertyp-abhängige Konfiguration laden
        this.orderFormService.getConfiguration().subscribe((result) => {
            this.orderFormService.orderAccessRights = result['orderAccessRights'];
            this.orderFormService.allMaxDeliveryDates = result['maxDeliveryDates'];
            // falls schon ein Auftrag geöffnet ist (Config kommt asynchron vom Backend), aktualisiere die Anzahl an Lieferdaten
            if (notEmptyObject(this.dataItem.deliveryDates)) {
                this.orderFormService.setMaxDeliveryDates(this.dataItem.order_type, this.dataItem.deliveryDates.length);
            }
            // Neuer Auftrag bekommt standard werte, falls schon ein Auftrag geladen ist, Rechte entsprechend setzen
            this.orderFormService.setOrderAccessRightsExplicit(
                hasOwn(this.dataItem, 'order_type') && notEmpty(this.dataItem['order_type']) ?
                    this.dataItem['order_type'] :
                    null,
            );
            this.changeDetectorRef.detectChanges();
        });

        // Neuer Auftrag bekommt standard werte, zunächst zur Initialisierung der Defaults setzen
        this.orderFormService.setOrderAccessRightsExplicit(null);

        // Events subscriben
        this.initializeEventSubscriptions();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Event wenn auf der Toolbar auf "Ansicht schließen" geklickt wird
        this.toolbarService.eventCloseComponent
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                if (result.target !== 'order-form') {
                    return;
                }
                // Falls auf "Ansicht schließen" geklickt wird während des EditModes, schließe den EditMode (wie Abbrechen-Button)
                if (this.editMode) {
                    this.clickCancel();
                }
                // Teile der übergeordneten Komponente mit, dass zur Gridansicht gewechselt werden soll
                this.changeShowOrderDetailsMode.emit(false);
            });
    }

    /**
     * Einzelne Aufträge laden
     */
    loadDetails(): void {
        this.loading = true;
        this.editMode = false;

        const serviceRequest$ = this.orderFormService.loadDetails(this.orderId, this.institutionId);
        serviceRequest$.subscribe((result: OrderDto) => {
            this.dataItem = result; // der Service hat die Daten schon extrahiert und aufbereitet
            // Flag "loading" deaktivieren
            this.loading = false;
            this.changeDetectorRef.detectChanges();
        });
    }

    /**
     * Neuen Auftrag erstellen
     */
    createOrder(): void {
        // Employee-Daten holen, wegen Zugriff auf Storage asynchron
        this.userDataService.loadUserData().then((user: User) => {
            // ohne Backend-Request ein neues Order-Objekt mit Defaultwerten anlegen
            this.dataItem = this.orderFormService.initOrder(this.institutionId, user);
            this.changeDetectorRef.detectChanges();
        });
    }

    /**
     * Formular absenden und Order speichern
     * @param setStatus
     */
    clickSubmit(setStatus = false): void {
        // Daten aus den Subformularen einsammeln
        const headerData = this.headerForm.orderHeaderFormGroup.value;

        if (setStatus) {
            // nach Klick auf "Senden" Button wird der Status auf "Freigegeben" (=an ERP zu senden) gesetzt
            headerData.order_status = this.orderFormService.getOrderStatusSend();
        }

        // die Lieferdaten liegen bereits als Date-Array im Header vor, verwende diese statt der Moment-Objekte aus den Form-Controls
        headerData.delivery_dates = this.headerForm.deliveryDates;

        // Produkte
        const orderItems = this.itemsForm.orderItemFormGroup.value.productsFormArray ?? [];

        // Summen aus Footer
        const footerData = this.footerForm.orderFooterFormGroup.value;

        /*
         * Prüfen, ob es Rabatt-Überschreitungen gibt, dann zuerst einen Warn-Dialog anzeigen
         * amount enthält bereits die Gesamtmenge (über alle Lieferdaten) eines Items
         */
        if (orderItems.filter((p) => p.amount > 0 && p.discount > headerData.order_discount).length > 0) {
            // Standard-Bestätigungsdialog konfigurieren und öffnen
            const dialogRef = this.dialog.open(PopupConfirmationComponent, {
                width: '350px',
                data: {
                    title: this.translateService.instant('SHARED.ORDERTRACKING.DISCOUNTWARNINGTITLE'),
                    message: this.translateService.instant('SHARED.ORDERTRACKING.DISCOUNTWARNINGMESSAGE'),
                },
            });
            // Auf das Schließen des Dialogs reagieren
            dialogRef.afterClosed().subscribe((result) => {
                if (result.answer === 'yes') {
                    // nach User-OK den Auftrag speichern
                    this.saveOrder(headerData, orderItems, footerData);
                }
            });
        } else {
            // ohne Dialog direkt speichern
            this.saveOrder(headerData, orderItems, footerData);
        }
    }

    /**
     * @brief Sende Auftragsdaten zum Speichern an den Service und werte Ergebnis aus
     * @param headerData
     * @param orderItems
     * @param footerData
     * @private
     */
    private saveOrder(headerData, orderItems, footerData) {
        orderItems = this.cleanUpOrderItems(orderItems);
        // Der Service bereitet die Daten auf und gibt sie an das Backend weiter
        this.orderFormService.saveOrder(headerData.id, headerData, orderItems, footerData).subscribe(
            (result) => {
                if (hasOwn(result, 'error')) {
                    this.dialog.open(PopupMessageComponent, {
                        width: '350px',
                        data: {
                            title: 'Auftrag konnte nicht gespeichert werden',
                            message: result['error'],
                        },
                    });

                    // Hier bleibt die Bestellung bearbeitbar
                    return;
                }
                // EditMode verlassen
                this.editMode = false;

                // Gespeicherte Auftrag neuladen
                this.loadDetails();
                // Details-Mode schließen
                this.changeShowOrderDetailsMode.emit(false);
                // Submit-Event auslösen, damit z.B. die Liste neu geladen wird
                this.submitOrder.emit(true);
            },
            (error) => {
                // Remove the specific substring from the error message
                const errorMessage = error.error.message.replace('Exception in handleRequest: ', '');

                // You can display an error message or handle it in any other way
                this.dialog.open(PopupMessageComponent, {
                    width: '350px',
                    data: {
                        title: 'Fehler beim Speichern des Auftrags',
                        message: errorMessage + '<br/>Bitte versuchen Sie es erneut.',
                    },
                });
            },
        );
    }

    /**
     * Eingabe abbrechen
     */
    clickCancel(): void {
        // EditMode verlassen
        this.editMode = false;
        // Untergeordneten Komponenten mitteilen, dass auf Abbrechen geklickt worden ist
        this.orderFormService.cancelForm();
    }

    /**
     * @brief Auf Änderungen an den Items reagieren und die Gesamtsummen im Footer aktualisieren
     * @param data
     */
    onItemsValueChanges(data: any) {
        if (!this.editMode) {
            // Footer-Summen nicht aus Items berechnen, wenn nur im Anzeigemodus, dann werden die Daten direkt aus der Order geholt
            return;
        }
        // Werte berechnen und an Footer-Form übergeben
        const orderSum = data.productsFormArray.map((p) => p.amount * p.unit_price).reduce(toSum, 0);
        const orderTotal = data.productsFormArray.map((p) => p.total_price).reduce(toSum, 0);

        this.footerForm.orderFooterFormGroup.patchValue({
            order_amount_total: data.productsFormArray.map((p) => p.amount ?? 0).reduce(toSum, 0),
            order_sum: orderSum,
            order_total: orderTotal,
            order_discount_mean: (orderSum - orderTotal) / orderSum,
            order_discount_sum: orderSum - orderTotal,
        });
    }

    areAllFormsValid() {
        if (!this.headerForm && !this.loading) {
            return false;
        }
        const headerFormValid = this.headerForm && !this.headerForm.orderHeaderFormGroup.invalid;
        const itemsFormValid = this.itemsForm && !this.itemsForm.orderItemFormGroup.invalid;
        const footerFormValid = this.footerForm && !this.footerForm.orderFooterFormGroup.invalid;
        return headerFormValid && itemsFormValid && footerFormValid;
    }

    /**
     * @brief Finde alle Felder (im Header) welche invalid sind
     * @details Wird nicht aktiv verwendet, kann zum debuggen oder zukünftig für etwas anderes verwenden
     * @returns {Array}
     */
    private findInvalidControls() {
        const invalid = [];
        const controls = this.headerForm.orderHeaderFormGroup.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        return invalid;
    }

    /**
     * @brief Räumt order Items auf
     * @param {Array} orderItems
     * @returns {Array}
     */
    private cleanUpOrderItems(orderItems) {
        for (const orderItem of orderItems) {
            if (!this.orderFormService.orderAccessRightsExplicit['order_item_discount']['ngIf']) {
                orderItem.discount = 0;
            }
        }
        return orderItems;
    }
}
