/**
 * @brief   Globaler Service zum Abfangen von HTTP-Requests um z.B.
 *          das Authentifizierungstoken im Header anzuhängen.
 * @details Ergänzt bei jedem HTTP-Request das JWT Access-Token im HTTP-Header
 *          im Authorization-Feld als Bearer-Token.
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Globale Services
import {TokenStorageService} from './token-storage.service';

@Injectable({
    // Root-Injector (app.module.ts) ist verantwortlich für das Instanziieren dieses globalen Services
    providedIn: 'root',
})
export class RequestInterceptorService implements HttpInterceptor {
    // Debug-Informationen ausgeben <true> oder nicht <false>
    private debug = false;

    // Konstruktor (inkl. dependency injection)
    constructor(private tokenStorageService: TokenStorageService) {}

    // HTTP-Request abfangen und Authorization-Header setzen
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Authorization-Token vorhanden?
        if (this.tokenStorageService.token) {
            // Authorization-Header setzen
            request = request.clone({setHeaders: {Authorization: 'Bearer ' + this.tokenStorageService.token}});
        }

        // Request durchführen
        return next.handle(request);
    }
}
