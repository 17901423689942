/**
 * @brief   Root-Komponente der Anwendung
 * @details Beinhaltet unnamed <router-outlet> für Hauptmodule, sowie
 *          <router-outlet name="popup"> für die übergeordnete Anzeige von
 *          Popups.
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {Component, OnDestroy, OnInit} from '@angular/core';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// Globale Services einbinden
import {StorageService} from './global/services/storage.service';
import {VersionCheckService} from './global/services/version-check.service';
// Environment einbinden
import {environment} from '@environment';
// Moment.js
import * as _moment from 'moment';

const moment = _moment;

@Component({
    selector: 'phscw-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    // Konstruktor
    constructor(
        private storageService: StorageService,
        private translate: TranslateService,
        private versionCheckService: VersionCheckService,
    ) {
        // Standardsprache falls gewählte Sprache nicht gefunden wird
        this.translate.setDefaultLang('deu');
        this.translate.use('deu');
    }

    // Initialisierungen
    ngOnInit() {
        // Prüfe ob Änderungen an C-World vorgenommen wurden um den Nutzer ggf. zu benachrichtigen
        if (environment.versionCheckURL !== '') {
            this.versionCheckService.initVersionCheck(environment.versionCheckURL);
        }

        /*
         * Prüfe ob es sich um ein Apple Device handelt und setzte eine Klasse für iOS-spezfische Regeln
         * ebenfalls Macintosh abfragen - siehe https://forums.developer.apple.com/thread/119186 (ziemlich beschissene Lösung - ändern sobald man korrekt zwischen iPad und Macintosh unterscheiden kann)
         */
        if (window.navigator && window.navigator.userAgent.match(/iPhone|iPad|iPod|Macintosh|MacIntel/i)) {
            document.body.classList.add('ios-device');
        }

        // Storage Service initialisieren
        this.storageService.initialize();

        // Sprache global setzen für moment.js
        moment.locale('de');
    }

    // Aufräumen
    ngOnDestroy() {}
}
