// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/**
 * Allgemein
 * =========
 *
 * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
 */
:host .cw-characteristic-groups-list {
  width: 100%;
}

:host .cw-flex-row.cw-button-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}

:host .cw-button-wrapper:not(:last-of-type) ~ .cw-button-wrapper button:only-child {
  margin-right: 4px;
}

/* Abstand entfernen, wenn keine Buttons angezeigt werden */
:host .cw-button-wrapper {
  margin-bottom: 0;
  margin-top: 0;
  line-height: 0;
}

/* Kontakttyp-Wechsel-Button wieder korrekt anordnen */
:host .cw-button-wrapper.cw-contact-type-switch {
  line-height: 1;
}

/* Besätigungs text des Sammelkontakts*/
.cw-green-collective-entry-key-info {
  color: green;
  color: var(--color-tour-minor);
  font-weight: bolder;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/contacts/contacts-form/contacts-form-contact/contacts-form-contact.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;;;;;EAAA;AAOA;EACI,WAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,yBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA,2DAAA;AACA;EACI,gBAAA;EACA,aAAA;EACA,cAAA;AACJ;;AAEA,sDAAA;AACA;EACI,cAAA;AACJ;;AAEA,uCAAA;AACA;EACI,YAAA;EACA,8BAAA;EACA,mBAAA;AACJ","sourcesContent":["/**\n * Allgemein\n * =========\n *\n * @author  Tobias Hannemann <t.hannemann@pharmakon.software>\n */\n\n:host .cw-characteristic-groups-list {\n    width: 100%;\n}\n\n:host .cw-flex-row.cw-button-wrapper {\n    display: flex;\n    flex-flow: row wrap;\n    justify-content: flex-end;\n}\n\n:host .cw-button-wrapper:not(:last-of-type) ~ .cw-button-wrapper button:only-child {\n    margin-right: 4px;\n}\n\n/* Abstand entfernen, wenn keine Buttons angezeigt werden */\n:host .cw-button-wrapper {\n    margin-bottom: 0;\n    margin-top: 0;\n    line-height: 0;\n}\n\n/* Kontakttyp-Wechsel-Button wieder korrekt anordnen */\n:host .cw-button-wrapper.cw-contact-type-switch {\n    line-height: 1;\n}\n\n/* Besätigungs text des Sammelkontakts*/\n.cw-green-collective-entry-key-info {\n    color: green;\n    color: var(--color-tour-minor);\n    font-weight: bolder;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
