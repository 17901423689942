// Angular-Module

import {Component, Input, OnDestroy, OnInit, SimpleChanges, OnChanges} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
// Angular-Material
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// Services
import {GlobalSearchService} from '@global/components/global-search/global-search.service';
import {AppCoreService} from '@global/services/app-core.service';
import {StorageService} from '@global/services/storage.service';
import {UserPermissionsService} from '@global/services/user-permissions.service';
// GridComponent
import {GridComponent} from './../grid/grid.component';
// Service dieses Shared-Moduls
import {GridService} from './../grid.service';
// Shared Services
import {GridSelectionService} from '@shared/grid/grid-selection/grid-selection.service';
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {LooseObject} from '@shared/loose-object';
import {SelectData} from '@shared/select-data';
// Komponenten für Dialog und Snackbar
import {PopupConfirmationComponent} from '@shared/popups/popup-confirmation/popup-confirmation.component';
import {PopupMessageComponent} from '@shared/popups/popup-message/popup-message.component';
import {SnackbarDefaultComponent} from '@shared/snackbars/snackbar-default/snackbar-default.component';
// Environment
import {environment} from '@environment';

/**
 * *****************************************************************************
 * Die vier Standard-Datenquellen haben fest definierte Keys
 *****************************************************************************
 */
const SOURCE_ALL = 10;
const SOURCE_SEARCH = 20;
const SOURCE_SELECTION = 30;
const SOURCE_PICK = 40;
const SOURCE_FAVORITE = 50;

@Component({
    selector: 'phscw-grid-source',
    templateUrl: './grid-source.component.html',
    styleUrls: ['./grid-source.component.scss'],
})
export class GridSourceComponent implements OnInit, OnDestroy, OnChanges {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    /**
     * *************************************************************************
     * Parameter, welche beim Einbinden der Komponente gesetzt werden
     *************************************************************************
     */
    // Daten für Selectbox
    private _gridSources: SelectData[];
    @Input() set gridSources(value: any) {
        this._gridSources = value;
        // Snackbar öffnen, um aktuellen Filter anzuzeigen
        this.openCurrentFilterSnackbar();
    }

    get gridSources() {
        return this._gridSources;
    }

    // Referenz auf verbundene Grid-Komponente, da Layout-Selectbox nicht ohne ein verbundenes Grid funktionieren kann
    @Input() gridConnection: GridComponent;
    /*
     * 2019-02-25, PhS(TH): Name muss der URL des Moduls entsprechen nach entfernen der Schrägstriche
     *                      Beispiel - InstitutionsList für /institutions
     *                               - AdminUsersList für /admin/users
     */
    // ID des (verbundenen) Grids
    @Input() gridId = '';
    // Ausgewählte Datenquelle
    @Input() currentSource: number = SOURCE_ALL;
    // Vorherige Datenquelle
    oldSource: number = null;
    // Falls existent speichere auch die list_data der Source als Object
    currentSourceData: object = {};
    oldTarget = '';
    // Darf die aktuelle Selektion gelöscht werden
    allowDelete = false;

    // Berechtigung, ob Snackbar angezeigt werden soll
    enableSnackbar = true;
    // Pfad an dem die Komponente initialisiert wurde
    initialUrl: string = null;
    // Referenz auf geöffnete Snackbar
    currentSourceSnackbarRef: MatSnackBarRef<SnackbarDefaultComponent> = null;
    // Flag definiert, ob die Reihenfolge der Besprechungsthemen angezeigt werden soll
    showSelectionPopup = true;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param router
     * @param translateService
     * @param dialog
     * @param snackBar
     * @param appCore
     * @param storageService
     * @param globalSearchService
     * @param userPermissionsService
     * @param gridService
     * @param gridSelectionService
     * @param toolbarService
     */
    constructor(
        private router: Router,
        private translateService: TranslateService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private appCore: AppCoreService,
        private storageService: StorageService,
        private globalSearchService: GlobalSearchService,
        private userPermissionsService: UserPermissionsService,
        private gridService: GridService,
        private gridSelectionService: GridSelectionService,
        private toolbarService: ToolbarService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Prüfe Anforderungen
        this.checkRequirements();
        // Berechtigungen prüfen
        this.checkPermissions();
        // Events subscriben
        this.initializeEventSubscriptions();
        // Initiale Auswahl in Selectbox
        this.changeGridSource(this.currentSource);

        /*
         * Initiale Route zwischenspeichern, um die Snackbar
         * nur zu öffnen, wenn man an der richtigen URL ist
         */
        this.initialUrl = this.router.url;
        if (Object.prototype.hasOwnProperty.call(environment, 'showGlobalSelectionPopup')) {
            this.showSelectionPopup = environment.showGlobalSelectionPopup;
        }
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        // Auf Änderung von gridSources durch parent component reagieren
        if (changes.gridSources && !changes.gridSources.firstChange) {
            this.updateSelectedOption();
        }
    }

    /**
     * Prüfe Anforderungen
     */
    checkRequirements(): void {
        // Grid-Layout benötigt gridLayouts
        if (typeof this.gridSources === 'undefined') {
            console.error('Pharmakon - GridSourceComponent bekam keine gridSources zugewiesen!');
        }
        // Grid-Layout benötigt ein verbundenes Grid
        if (typeof this.gridConnection === 'undefined') {
            console.error(
                'Pharmakon - GridSourceComponent verfügt über kein verbundenes Grid und kann deshalb nicht funktionieren. Bitte beim Einbinden der Komponente die [gridConnection] setzen!',
            );
        }
    }

    /**
     * Prüfe Berechtigungen
     */
    checkPermissions(): void {
        const permissionEnableGridSourceNotification: boolean =
            this.userPermissionsService.getPermissionValue('enableGridSourceNotification');
        this.enableSnackbar = permissionEnableGridSourceNotification;
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Auf Routenänderungen reagieren
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntil(this._componentDestroyed$),
            )
            .subscribe((route: NavigationEnd) => {
                if (this.currentSourceSnackbarRef !== null && this.router.url !== this.initialUrl) {
                    this.currentSourceSnackbarRef.dismiss();
                } else {
                    this.openCurrentFilterSnackbar();
                }
            });

        // Event "eventSearchComplete" von "globalSearchService"
        this.globalSearchService.eventSearchComplete
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((event: CWEvent) => {
                if (event.sender !== 'globalSearch') {
                    return;
                }
                this.onEventSearchComplete(event.target);
            });

        // Event "eventStartSelection" von "gridSelectionService"
        this.gridSelectionService.eventStartSelection
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((event: CWEvent) => {
                if (event.target !== this.gridId) {
                    return;
                }
                this.onEventSelectionComplete(event.data);
            });

        // Event "changeGridSource" vom AppCore erhalten
        this.appCore.changeGridSource.pipe(takeUntil(this._componentDestroyed$)).subscribe((event: CWEvent) => {
            if (event.target !== this.gridId) {
                return;
            }
            this.onChangeGridSource(event);
        });

        /*
         * Hört auf den Toolbarbutton zum Toggle der Selektion. Wird benötigt um Fehler bei Einloggen auf Selektion zu vermeiden.
         * @todo: Keine Schöne Lösung, keine bessere gefunden.
         */
        this.toolbarService.eventGridExtensionToggle
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((event: CWEvent) => {
                if (event.target === this.gridId && event.data['extension'] === 'selection') {
                    const key = this.gridSources.findIndex((source) => source['id'] == this.currentSource);
                    if (
                        key !== -1 &&
                        typeof this.gridSources[key]['data'] !== 'undefined' &&
                        this.gridSources[key]['data'] !== null &&
                        typeof this.gridSources[key]['data']['frontend_array'] !== 'undefined' &&
                        this.gridSources[key]['data']['frontend_array'] !== ''
                    ) {
                        this.currentSourceData = {
                            frontend_array: JSON.parse(this.gridSources[key]['data']['frontend_array']),
                            allow_deletion:
                                !!(typeof this.gridSources[key]['data']['allow_deletion'] !== 'undefined' &&
                                this.gridSources[key]['data']['allow_deletion'] == '1'),
                        };
                        this.gridSelectionService.openSelection(this.currentSourceData);
                    }
                }
            });

        // Hört darauf, ob die aktuell gewählte Selektion gelöscht werden soll
        this.gridService.eventGridSelectionDeleted
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((event: CWEvent) => {
                if (event.target !== this.gridId) {
                    return;
                }
                this.onDeleteSelection();
            });
    }

    /**
     * Auf Event "changeGridSource" vom AppCore reagieren
     * @param resultEvent
     */
    onChangeGridSource(resultEvent: CWEvent): void {
        // Datenquelle wechseln
        if (resultEvent.data['listKey']) {
            // Zur gewünschten Liste wechseln
            this.currentSource = resultEvent.data['listKey'];
        } else {
            // DEFAULT: Auf Datenquelle "Auswahl" wechseln
            this.currentSource = SOURCE_PICK;
        }
        this.changeGridSource(this.currentSource);

        // Soll nach Änderung der GridSource etwas ausgewählt werden?
        if (resultEvent.data && resultEvent.data['selectRowAfterFinished']) {
            switch (resultEvent.data['selectRowAfterFinished']) {
                case 'first':
                    // Erste Datenzeile auswählen
                    this.gridService.selectFirstRow(this.gridId);
                    break;
                default:
                    break;
            }
        }

        // Splitter der Eichtungsliste halbiert öffnen
        if (resultEvent.data && resultEvent.data['toggleFullscreenInstitutionsDetails']) {
            this.gridService.toggleFullscreen('institutionsDetails', 50);
        }
    }

    /**
     * Auswahl in Selectbox wird geändert
     * @param selectedItem
     */
    changeGridSource(selectedItem: number): void {
        const key = this.gridSources.findIndex((source) => source['id'] == selectedItem);
        if (
            key !== -1 &&
            typeof this.gridSources[key]['data'] !== 'undefined' &&
            this.gridSources[key]['data'] !== null &&
            typeof this.gridSources[key]['data']['frontend_array'] !== 'undefined' &&
            this.gridSources[key]['data']['frontend_array'] !== ''
        ) {
            if (
                typeof this.gridSources[key]['data']['allow_deletion'] !== 'undefined' &&
                this.gridSources[key]['data']['allow_deletion'] == '1'
            ) {
                this.allowDelete = true;
            } else {
                this.allowDelete = false;
            }

            this.currentSourceData = {
                frontend_array: JSON.parse(this.gridSources[key]['data']['frontend_array']),
                allow_deletion: this.allowDelete,
            };
            this.gridSelectionService.openSelection(this.currentSourceData);
        } else {
            // Flag setzen
            this.allowDelete = false;

            // GridService informieren (damit dort Event ausgelöst werden kann)
            this.gridSelectionService.resetSelection();
        }

        // Quelle ändern
        this.gridConnection.selectedSource = selectedItem;
        this.gridService.sourceChanged(this.gridId, this.currentSource);

        // Snackbar öffnen, um aktuellen Filter anzuzeigen
        this.openCurrentFilterSnackbar();
    }

    /**
     * Anzeigen, ob die vollständige Liste angezeigt wird, oder ein bestimmter Filter angewendet wurde
     */
    openCurrentFilterSnackbar(): void {
        // Prüfen, ob Snackbar geöffnet werden soll
        if (this.enableSnackbar === false) {
            return;
        }

        // Snackbar schließen
        if (this.currentSourceSnackbarRef !== null) {
            this.currentSourceSnackbarRef.dismiss();
        }

        // Snackbar öffnen, um aktuellen Filter anzuzeigen
        if (this.currentSource != SOURCE_ALL && this.showSelectionPopup) {
            // Bezeichnung der Quelle finden
            const currentSourceObject: LooseObject = this.gridSources.find(
                (source: SelectData) => source['id'] == this.currentSource,
            );
            if (currentSourceObject === undefined) {
                return;
            }

            // Label zusammensetzen
            let label: string = this.translateService.instant('SHARED.GRID.SOURCE.SOURCEACTIVE', {source: currentSourceObject.label});

            // Wenn in der Einrichtungs- oder Personenliste in Selektion / Favoriten / Auswahl / eigene Selektionsliste gesucht wird, label überschreiben
            if (
                this.currentSource === SOURCE_SEARCH &&
                (this.oldSource === SOURCE_SELECTION ||
                this.oldSource === SOURCE_FAVORITE ||
                this.oldSource === SOURCE_PICK ||
                this.oldSource >= 1000) &&
                (this.gridId === 'peopleList' || this.gridId === 'institutionsList')
            ) {
                // Den Namen der Quelle holen, die VOR der Suche ausgewählt war
                const oldSourceObject: LooseObject = this.gridSources.find(
                    (source: SelectData) => source['id'] == this.oldSource,
                );
                label = this.translateService.instant('SHARED.GRID.SOURCE.SELECTIONSEARCH', {source: oldSourceObject.label});
            }

            // Referenz speichern
            this.currentSourceSnackbarRef = this.snackBar.openFromComponent(SnackbarDefaultComponent, {
                panelClass: 'cw-grid-source-snackbar',
                data: {label},
                duration: 5000,
            });
        }
    }

    /**
     * Auf Abschluss einer Suche reagieren
     * @param searchEventTarget
     */
    onEventSearchComplete(searchEventTarget: string): void {
        // Falls das Ziel der Suche nicht zur ausgewählten Liste gehört...
        if (
            !this.gridId.toLowerCase().includes(searchEventTarget.replace(/[/-]/g, '')) &&
            !(this.gridId == 'peopleList' && searchEventTarget == '/b2c')
        ) {
            // ...verlasse die Funktion
            return;
        }

        // Vorherige Datenquelle als Zahl zwischenspeichern
        this.oldSource = +this.currentSource;

        // Auf Datenquelle "Suche" wechseln
        this.currentSource = SOURCE_SEARCH;
        this.changeGridSource(this.currentSource);

        // Erste Datenzeile des Suchergebnisses auswählen
        this.gridService.selectFirstRow(this.gridId);
    }

    /**
     * Auf Abschluss einer Selektion reagieren
     * @param key
     */
    onEventSelectionComplete(key: any = undefined): void {
        if (typeof key === 'undefined' || key === 1000) {
            // Auf Datenquelle "Selektion" wechseln
            this.currentSource = SOURCE_SELECTION;
        } else {
            this.currentSource = key;
        }
        this.gridConnection.selectedSource = this.currentSource;

        // Datenquelle im Grid geändert, Event über Service auslösen
        this.gridService.sourceChanged(this.gridId, this.currentSource);

        // Snackbar öffnen, um aktuellen Filter anzuzeigen
        this.openCurrentFilterSnackbar();
    }

    /**
     * Auf das Event zum Löschen eines Elements (einer Selektion) reagieren
     */
    onDeleteSelection(): void {
        // Den aktuell gewählten Eintrag finden
        const index = this.gridSources.findIndex((source) => source['id'] == this.currentSource);
        let source;
        if (index !== -1) {
            source = this.gridSources[index];
        }

        // Wenn dieser existiert ...
        if (source !== undefined && typeof source['data'] !== 'undefined') {
            // ... erstelle die postParameter für die Löschfunktion
            const postObject = {
                id: source.id,
                title: source.label,
                // das 'List' aus der gridId wird replaced, damit man 'institutions' oder 'people' etc. bekommt
                module: this.gridId.replace('List', ''),
                // Initial wird davon ausgegangen, dass eine nicht freigegebene Selektion gelöscht wird.
                deleteSingleSelection: true,
            };

            // Nachricht des Popups
            let message = 'SHARED.GRID.SELECTION.POPUP.DELETIONTEXT';

            // Wenn die Selektion freigegeben wurde ...
            if (typeof source.data.cleared_selection !== 'undefined' && source.data.cleared_selection == '1') {
                // ... informiere das Backend, dass die freigegebene Selektion(en) gelöscht werden müssen
                postObject.deleteSingleSelection = false;
                // Andere Nachricht für freigegebene Selektionen
                message = 'SHARED.GRID.SELECTION.POPUP.DELETIONCLEAREDFILTERTEXT';
            }

            // Dialog konfigurieren und öffnen
            const dialogRef = this.dialog.open(PopupConfirmationComponent, {
                width: '350px',
                data: {
                    title: this.translateService.instant('SHARED.GRID.SELECTION.POPUP.DELETIONHEADER'),
                    message: this.translateService.instant(message),
                },
            });

            // Auf das Schließen des Dialogs reagieren
            dialogRef.afterClosed().subscribe((result) => {
                this.deleteSelection(result, index, postObject);
            });
        }
    }

    /**
     *  ???
     * @param result
     * @param index
     * @param postObject
     */
    deleteSelection(result: any, index: number, postObject: any): void {
        // Bei Ablehnung abbrechen
        if (result.answer !== 'yes') {
            return;
        }

        // Lösche die Daten
        const backendRequest$ = this.gridService.deleteSelection(postObject);
        backendRequest$.subscribe((result: any) => {
            if (result.success) {
                // Aus den gridSources (Selektionsmöglichkeiten) entfernen
                this.gridSources.splice(index, 1);
                // Aus der IndexedDB entfernen damit, beim refresh der Seite die Selektion nicht plötzlich wieder auftaucht
                this.storageService.getItem(this.gridId + 'SavedSelections').then((listEntry: any) => {
                    const key = listEntry.findIndex((source: LooseObject) => source['list_key'] == this.currentSource);

                    if (key !== -1) {
                        listEntry.splice(key, 1);
                    }
                    this.storageService.setItem(this.gridId + 'SavedSelections', listEntry);

                    /**
                     * Selektion auf 'Alle' ändern
                     * Dafür muss auch currentSource geändert werden, da dies nicht von changeGridSource gemacht wird. (Wird normalerweise vom Template erledigt)
                     */
                    this.currentSource = SOURCE_ALL;
                    this.changeGridSource(SOURCE_ALL);

                    // Snackbar öffnen, um aktuellen Filter anzuzeigen
                    this.openCurrentFilterSnackbar();
                });
            } else {
                // Nutzer benachrichtigen
                this.allowDelete = false;

                // Dialog konfigurieren und öffnen
                this.dialog.open(PopupMessageComponent, {
                    width: '350px',
                    data: {
                        title: this.translateService.instant('SHARED.GRID.SELECTION.POPUP.DELETIONFAILED'),
                        message: this.translateService.instant('SHARED.GRID.SELECTION.POPUP.DELETIONFAILED'),
                    },
                });
            }
        });
    }

    // Aktuell ausgewählte grid source aktualisieren
    updateSelectedOption() {
        if (this.gridSources && this.gridSources.length > 0) {
            const selectedOption = this.gridSources.find((source) => source.id == this.currentSource);
            if (selectedOption) {
                this.currentSource = selectedOption.id;
            }
        }
    }
}
