/**
 * @brief   Globaler Service als Abstraktionsschicht der Authentifizierung.
 * @details Verwendet BackendService zum Senden von HTTP-Requests.
 *          Token wird über TokenStorageService verwaltet.
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */

// Angular-Module
import {Injectable} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
// ReactiveX for JavaScript
import {Observable} from 'rxjs';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// Globale Services
import {BackendService} from './backend.service';
import {InitService} from './init.service';
import {TokenStorageService} from './token-storage.service';
import {UserPermissionsService} from './user-permissions.service';
import {UserSettingsService} from './user-settings.service';
// Moment.js
import {hasOwn} from '@shared/utils';
import * as _moment from 'moment';

const moment = _moment;

@Injectable({
    // Root-Injector (app.module.ts) ist verantwortlich für das Instanziieren dieses globalen Services
    providedIn: 'root',
})
export class AuthService {
    // Aktuelle Sprache
    languageKey = '';

    // GET - Flag, ob User eingeloggt ist (Token vorhanden und nicht abgelaufen)
    get isLoggedIn(): boolean {
        // Token und TokenExpiresAt vorhanden? Token noch gültig?
        if (this.tokenStorageService.token && this.tokenStorageService.tokenExpiresAt && !this.isTokenExpired) {
            // User ist eingeloggt
            return true;
        }

        // Standard (Nicht eingeloggt, kein gültiges oder abgelaufenes Token)
        return false;
    }

    // GET - Information, ob das Token abgelaufen ist
    get isTokenExpired(): boolean {
        // Aktuellen Zeitstempel (durch 1000 teilen, da JS mit Millisekunden arbeitet und PHP mit Sekunden)
        const now: Date = new Date();
        const nowTime: number = now.getTime() / 1000;
        // Prüfe, ob Token noch gültig ist
        if (nowTime > this.tokenStorageService.tokenExpiresAt) {
            return true;
        }
        // Standard (Token ist noch gültig)
        return false;
    }

    // URL speichern um nach Login weiterleiten zu können
    redirectUrl: string;

    // Konstruktor (inkl. dependency injection)
    constructor(
        private router: Router,
        private translate: TranslateService,
        private backendService: BackendService,
        private initService: InitService,
        private tokenStorageService: TokenStorageService,
        private userPermissionsService: UserPermissionsService,
        private userSettingsService: UserSettingsService,
        private route: ActivatedRoute,
    ) {
        this.languageKey = this.translate.currentLang;
    }

    /**
     * @param credentials
     * @brief   Login des Benutzers
     * @details Erhält credentials und sendet diese über BackendService
     *          als POST-Request.
     *          Reagiert selbst auf das Ergebnis des POST-Requests, leitet
     *          diesen aber auch zur Auswertung an die aufrufende Komponente
     *          weiter.
     * @returns  Observable<any>
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    login(credentials: object): Observable<any> {
        // POST-Request mit Login-Credentials über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('users/login', credentials);
        // AuthService subscribed den definierten Post-Request, wodurch dieser ausgelöst wird.
        postRequest$.subscribe(
            // onNext
            (result) => {
                // Login erfolgreich?
                if (result['success']) {
                    // LOGIN ERFOLGREICH
                    this.onSuccessfulLogin(result);
                }
            },
            // onError
            (error) => {
                // Keine Antwort vom Backend erhalten (Servererror)
                console.error('AuthService#login.subscription - Server-Error!');
                console.error(error);
            },
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @brief   Weiterreichen des Token ans Backend um zu prüfen ob Microsoft es ausgestellt hat und welche Email
     * @author  Nick Mühlberger <n.muehlberger@pharmakon.software> Eric Haeussel <e.haeusel@pharmakon.software>
     */
    ssoLogin(code) {
        const codeObject = {code};

        const authServiceLogin$: Observable<any> = this.backendService.postRequest('users/loginSSO', codeObject);
        authServiceLogin$.subscribe(
            // onNext
            (result: any) => {
                // Login fehlgeschlagen?
                if (result.success) {
                    this.onSuccessfulLogin(result, true);
                } else {
                    // Zur Loginseite wechseln
                    this.router.navigate(['/login']);
                }
            },
            // onError
            (error: any) => {
                // Keine Antwort vom Backend erhalten (Servererror)
                console.error('AuthService#ssoLogin.subscription - Server-Error!');
                console.error(error);
            },
        );
        return authServiceLogin$;
    }

    /**
     * @param result
     * @brief   Initialisierungen nach erfolgreichem Login
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    onSuccessfulLogin(result: any, skipRedirect = false) {
        // Authentifizierungs-Token setzen
        this.tokenStorageService.tokenExpiresAt = result['data']['token']['expiresAt'];
        this.tokenStorageService.token = result['data']['token']['tokenId'];

        // Benutzer-Einstellungen speichern (über userSettingsService)
        this.userSettingsService.storeManySettings(result['data']['userSettings']);
        // Benutzer-Berechtigungen in AppCore ablegen
        this.userPermissionsService.storeManyPermissions(result['data']['userPermissions']);

        // Sprache setzen
        if (hasOwn(result['data']['userData'], 'language_key') && result['data']['userData']['language_key'] !== null) {
            // Sprache des Benutzers einstellen --> NGX-Translate
            this.translate.use(result['data']['userData']['language_key']);
            // Sprache des Benutzers einstellen --> moment.js
            moment.locale(result['data']['userData']['language_key'].substring(0, 2));
        } else {
            this.translate.use('deu');
            moment.locale('de');
        }

        // Initialisierungs-Service aufrufen (zum asynchronen Laden der Core-Daten)
        this.initService.initializeAll();
        const redirect = this.route.snapshot.queryParams['redirect'];

        // ... und nach Prüfung der Berechtigung...
        const presetInitModule: string = this.userPermissionsService.getPermissionValue('presetInitModule');
        if (redirect && !skipRedirect) {
            this.router.navigate([redirect]);
        } else if (presetInitModule && presetInitModule.length > 0) {
            // ...wird auf "presetInitModule" weitergeleitet
            this.router.navigate([presetInitModule.toLowerCase()]);
        } else {
            // ...wird auf "lastModule" weitergeleitet
            const lastModule: string = this.userSettingsService.getValue('lastModule');
            this.router.navigate([lastModule]);
        }
    }

    /**
     * @brief   Logout des Benutzers
     * @details Leert Local-Storage (und dadurch auch JWT)
     * @returns  void
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    logout(): void {
        // LocalStorage komplett leeren
        localStorage.clear();
        // Session-Storage komplett leeren
        window.sessionStorage.clear();
        /*
         * Indexed DB löschen
         * this.dbService.dropDatabase();
         */
    }
}
