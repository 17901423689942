// Angular-Module
import {
    Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, OnChanges,
} from '@angular/core';
import {UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {hasOwn} from '@shared/utils';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// Environment einbinden
import {environment} from '@environment';

/*
 * Default-Regulärer Ausdruck für E-Mail-Validierung. Sonderzeichen müssen mit \\ escaped werden
 * Özlem hat gesagt umlaute in E-Mail-Adressen sind doof, deswegen öüäéèàâê rausgenommen
 */
const REGEX_MAIL = new RegExp(
    '^[0-9a-zA-Z\\-_\\+&]([.]{0,1}[0-9a-zA-Z\\-_\\+&])*@([0-9a-zA-Z][-\\w]*[0-9a-zA-Z]\\.)+[a-zA-Z]{2,9}$',
);
const REGEX_MAIL_MESSAGE = 'E-Mail-Adresse ist nicht gültig!';
// Default-Regulärer Ausdruck für Telefon- und Faxnummern
const REGEX_PHONE = new RegExp('^[\\+0-9\\/\\-\\s]{2,}$');
const REGEX_PHONE_MESSAGE = 'Telefon und Faxnummern dürfen nur Ziffern, sowie die Zeichen "+" "/" "-" und " " enhalten';

/*
 * Default-Regulärer Ausdruck für Language Key
 * const REGEX_LANGKEY: RegExp = new RegExp('eng|deu');
 * const REGEX_LANGKEY_MESSAGE: string = 'Bitte geben Sie "eng" oder "deu" an.';
 * Einfacher Regulärer Ausdruck für URL. Es gib vermutlich bessere
 */
const REGEX_URL = new RegExp(
    '^(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]+$',
);
const REGEX_URL_MESSAGE = 'URL ist nicht gültig!';

// Regex der Überprüfen soll, ob die URL mit http(s)?:// beginnt
const REGEX_URL_CHECK = new RegExp('^(http(s)?:\\/\\/).+');

// Custom Validator für E-Mails
/**
 *
 * @param message
 */
export function createEmailValidator(message = '') {
    return function validateEmail(c: UntypedFormControl) {
        if (c.value === null || c.value === '') {
            return false;
        }
        let error;
        if (!hasOwn(environment, 'mailValidationMessage')) {
            error = {
                validationError: {
                    given: c.value,
                    message: message || REGEX_MAIL_MESSAGE,
                },
            };
        } else {
            error = {
                validationError: {
                    given: c.value,
                    // Achtung Environment-Properties müssen in der Eckigen-Klammer-Schreibweise aufgerufen werden, da sonst Typ-Script Probleme macht
                    message: environment['mailValidationMessage'],
                },
            };
        }
        if (!hasOwn(environment, 'mailValidationRegex')) {
            return REGEX_MAIL.test(c.value) ? null : error;
        }
        const regexString = new RegExp(environment['mailValidationRegex']);
        // Achtung Environment-Properties müssen in der Eckigen-Klammer-Schreibweise aufgerufen werden, da sonst Typ-Script Probleme macht
        return regexString.test(c.value) ? null : error;
    };
}

/*
 * Custom validator für Telefon und Fax
 *
 * Achtung:
 * Environment-Variablen dürfen nicht vom Typ RegExp sein, da diese nicht korrekt
 * aus der environment geladen werden. Stattdessen als string speichern und umwandeln.
 *
 * In der Komponente selbst können Variablen aber vom Typ RegExp sein.
 */
/**
 *
 * @param message
 */
export function createPhoneValidator(message = '') {
    const phoneValidationMessage = environment['phoneValidationMessage'] || message || REGEX_PHONE_MESSAGE;
    const phoneValidationRegex = environment['phoneValidationRegex'] || REGEX_PHONE;
    const regex = new RegExp(phoneValidationRegex);

    return function validatePhone(c: UntypedFormControl) {
        if (c.value === '' || c.value === null) {
            return null;
        }

        // Fehler-Objekt erstellen
        const error = {
            validationError: {
                given: c.value,
                message: phoneValidationMessage,
            },
        };

        // Überprüfen, ob der Wert dem Regex entspricht, sonst FormControl als invalid markieren
        return regex.test(c.value) ? null : error;
    };
}

// Custom Validator für URLs
/**
 *
 * @param message
 */
export function createUrlValidator(message = '') {
    return function validateUrl(c: UntypedFormControl) {
        if (c.value === null || c.value === '') {
            return false;
        }
        let error;
        if (!hasOwn(environment, 'urlValidationMessage')) {
            error = {
                validationError: {
                    given: c.value,
                    message: message || REGEX_URL_MESSAGE,
                },
            };
        } else {
            error = {
                validationError: {
                    given: c.value,
                    // Achtung Environment-Properties müssen in der Eckigen-Klammer-Schreibweise aufgerufen werden, da sonst Typ-Script Probleme macht
                    message: environment['urlValidationMessage'],
                },
            };
        }
        if (!hasOwn(environment, 'urlValidationRegex')) {
            return REGEX_URL.test(c.value) ? null : error;
        }
        const regexString = new RegExp(environment['urlValidationRegex']);
        // Achtung Environment-Properties müssen in der Eckigen-Klammer-Schreibweise aufgerufen werden, da sonst Typ-Script Probleme macht
        return regexString.test(c.value) ? null : error;
    };
}

// CustomValidator für Postleitzahlen
/**
 *
 * @param message
 */
export function createZipcodeValidator(message = '') {
    return function validateZipcode(c: UntypedFormControl) {
        if (c.value === null || c.value === '') {
            return false;
        }
        const error = {
            validationError: {
                given: c.value,
                message: message || 'Postleitzahlen dürfen nur Ziffern und nicht mehr als 10 Zeichen enthalten',
            },
        };
        // Überprüfe, ob der Wert numerisch ist. Er darf kein Minus am Anfang enthalten und auch kein Dezimal-Punkt
        if (isNaN(c.value) || c.value < 0 || c.value.includes('.') || c.value.length > 10) {
            return error;
        }
        return null;
    };
}

@Component({
    selector: 'phscw-input-text',
    templateUrl: './input-text.component.html',
    styleUrls: ['./input-text.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputTextComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => InputTextComponent),
            multi: true,
        },
    ],
})
export class InputTextComponent implements OnInit, OnDestroy, OnChanges {
    // EditMode aktiv?
    @Input() editMode = false;
    // ID des Inputfelds (wird für "id" und "name" verwendet)
    @Input() inputId: string;
    // Bezeichnung (Text, welcher dem Anwender angezeigt wird)
    @Input() label: string;
    // Model "myValue" mit GETTER & SETTER
    _myValue = '';
    @Input() set myValue(value) {
        this._myValue = value;
        this.propagateChange(this._myValue);
    }

    get myValue() {
        return this._myValue;
    }

    // Funktion, die aufgerufen wird, wenn eine Änderung auftritt
    propagateChange = (_: any) => {};
    // Attribut: required = Pflichtfeld (ja / nein)
    @Input() required = false;
    // Attribut: disabled = Gesperrt (ja / nein)
    @Input() disabled = false;
    // Attribut: maxlength
    @Input() maxlength: number = null;
    // Platzhalter Textfeld
    @Input() placeholder = '';
    // Spezieller Decorater für Werte (z.B. "mail" oder "internet" zur Darstellung externer Links)
    @Input() valueDecorator = '';
    // Input-Type (diese Komponente kann z.B. "text", aber auch "email" darstellen
    @Input() inputType = 'text';
    // Soll ein Löschbutton angezeigt werden.
    @Input() showDeleteButton = false;
    // In Ansichtsmodus verstecken, wenn leer?
    @Input() hideEmpty = true;
    /*
     * Gibt es einen Validierungsfehler?
     * @todo: Ein bisschen hin und her gereiche. Evtl. noch verbesserungswürdig
     */
    @Input() error: any;
    validateFn: any;

    // Currency-Symbol (wird in NgOnInit ggf. über Environment gesetzt / überschrieben)
    currency = '€';

    // Event-Emitter, falls der Wert gelöscht wurde
    @Output() deleteClicked = new EventEmitter<any>();

    /**
     * Konstruktor (inkl. dependency injection)
     * @param translateService
     */
    constructor(private translateService: TranslateService) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        // Währung setzen
        if (typeof environment.defaultCurrency !== 'undefined') {
            this.currency = environment.defaultCurrency;
        }
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {}

    /**
     * Interface ControlValueAccessor: writeValue
     * @param value
     */
    writeValue(value: any) {
        if (value !== undefined) {
            this.myValue = value;
        }
    }

    /**
     * Ändern
     * @param changes
     */
    ngOnChanges(changes: any) {
        if (changes.valueDecorator && changes.valueDecorator.currentValue === 'mail') {
            this.validateFn = createEmailValidator(
                this.translateService.instant('SHARED.INPUT.TEXT.DEFAULTMAILVALIDATORERROR'),
            );
        } else if (changes.valueDecorator && changes.valueDecorator.currentValue === 'phone') {
            this.validateFn = createPhoneValidator(
                this.translateService.instant('SHARED.INPUT.TEXT.DEFAULTPHONEVALIDATORERROR'),
            );
        } else if (changes.valueDecorator && changes.valueDecorator.currentValue === 'url') {
            this.validateFn = createUrlValidator(
                this.translateService.instant('SHARED.INPUT.TEXT.DEFAULTURLVALIDATORERROR'),
            );
        } else if (changes.valueDecorator && changes.valueDecorator.currentValue === 'zipcode') {
            this.validateFn = createZipcodeValidator(
                this.translateService.instant('SHARED.INPUT.TEXT.DEFAULTZIPCODEVALIDATORERROR'),
            );
        }
    }

    /**
     * Validieren
     * @param c
     */
    validate(c: UntypedFormControl) {
        if (typeof this.validateFn !== 'undefined') {
            return this.validateFn(c);
        }
    }

    /**
     * Interface ControlValueAccessor: registerOnChange
     * @param fn
     */
    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    /**
     * Interface ControlValueAccessor: registerOnTouched
     */
    registerOnTouched() {}

    // Lösche den Text
    deleteValue() {
        this.myValue = null;
        this.deleteClicked.emit();
    }

    /**
     * @param phoneNumber
     * @brief   Funktion formatiert Telefonnummern
     * @details Funktion, die Telefonnummern so formatiert, wie sie IVF- benötigt.
     *          Wird nur eingesetzt, wenn die autocorrectPhone-Property im Environment gesetzt ist.
     *          Alternativ kann die Custom-Regex:
     *          /^(\+[0-9]{2,3}\s)[0-9]{2}(?:(?<=(\b[0-9]{2}\b))(\s[0-9]{3}|((\s[0-9]{1,3}){0,1}$)))(?:(?=(\s[0-9]{2}))(\s[0-9]{2})|(\s[0-9]{1,2})$)*$/
     *          zur Validierung genutzt werden. Die fand Özlem aber bei ersten Vorführungen
     *          zu restriktiv.
     *          03.02.2020: Anpassung die Telefonnummer wird jetzt nicht mehr bei der Eingabe automatisch korrigiert sondern erst beim Verlassen des Feldes / OnChange
     *          OTRS-Ticket#8000748
     * @author  Michael Schiffner <m.schiffner@pharmakon.software>
     */
    autocorrectPhone(phoneNumber: string): string {
        /*
         * Wenn es sich nicht um eine Telefonnummer handelt oder der Wert nicht gesetz ist,
         * oder die Environment-Variable gessetzt ist, oder die Validierung fehlschlug => return
         */
        if (
            !(
                typeof phoneNumber !== 'undefined' &&
                phoneNumber !== null &&
                hasOwn(environment, 'autocorrectPhone') &&
                environment['autocorrectPhone'] &&
                !this.error &&
                this.valueDecorator === 'phone'
            )
        ) {
            return;
        }

        // Early-Return, falls der die Nummer kein String ist, oder leer ist.
        if (phoneNumber === null || typeof phoneNumber !== 'string' || phoneNumber === '') {
            this.myValue = '';
        }
        // Leerzeichen und Sonderzeichen (außer + am Anfang) aus String entfernen
        const dirtyString = phoneNumber.replace(/[\s\-/]/gi, '');
        // Rückgabe-String initialisieren
        let cleanPhoneString = '';
        // Den bereinigten String mit den Leerzeichen an den richtigen Stellen neu erstellen
        for (let i = 0; i < dirtyString.length; i++) {
            cleanPhoneString += dirtyString.substr(i, 1);
            if (
                i === 2 ||
                i === 4 ||
                (i === 7 && i !== dirtyString.length - 1) ||
                (i > 8 && i % 2 === 1 && i !== dirtyString.length - 1)
            ) {
                cleanPhoneString += ' ';
            }
        }

        this.myValue = cleanPhoneString;
    }

    /**
     * Prüft, ob ein URL-Value schon das 'http(s)' im Wert hat
     * @param value
     */
    checkUrlValue(value: any): boolean {
        return REGEX_URL_CHECK.test(value);
    }
}
