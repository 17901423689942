// Angular-Module
import {DatePipe} from '@angular/common';
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Service des übergeordneten Feature-Moduls
import {InstitutionsService} from '../institutions.service';
// Globale Services
import {InitService} from '@global/services/init.service';
import {StorageService} from '@global/services/storage.service';
import {UserPermissionsService} from '@global/services/user-permissions.service';
import {UserSettingsService} from '@global/services/user-settings.service';
// Eigener Service
import {InstitutionsPeopleService} from '../institutions-people/institutions-people.service';
import {InstitutionsListService} from './institutions-list.service';
// Service des globalen Regionsfilters
import {GlobalRegionsfilterService} from '@global/components/global-regionsfilter/global-regionsfilter.service';
// Service von Shared Modules
import {ClearingDuplicatesPanelService} from '@shared/clearing/clearing-duplicates-panel/clearing-duplicates-panel.service';
import {ClearingService as ClearingSharedService} from '@shared/clearing/clearing.service';
import {FutureMonitor} from '@shared/future-monitor';
import {GridColumnsService} from '@shared/grid/grid-columns-panel/grid-columns.service';
import {GridSelectionService} from '@shared/grid/grid-selection/grid-selection.service';
import {GridService} from '@shared/grid/grid.service';
import {ToolbarService} from '@shared/toolbar/toolbar.service';
// Interfaces für Structured Objects einbinden
import {Characteristic} from '@shared/characteristic';
import {CWEvent} from '@shared/cw-event';
import {CWResult} from '@shared/cw-result';
import {FilterData} from '@shared/filter-data';
import {Institution} from '@shared/institution';
import {LooseObject} from '@shared/loose-object';
import {SelectData} from '@shared/select-data';
// Shared Komponenten
import {CharacteristicGroupsListPopupComponent} from '@shared/characteristics/characteristic-groups-list-popup/characteristic-groups-list-popup.component';
import {ClearingMergePopupComponent} from '@shared/clearing/clearing-merge-popup/clearing-merge-popup.component';
import {PopupConfirmationComponent} from '@shared/popups/popup-confirmation/popup-confirmation.component';
import {PopupMessageComponent} from '@shared/popups/popup-message/popup-message.component';
import {PopupSchedulingComponent} from '@shared/popups/popup-scheduling/popup-scheduling.component';
// Environment einbinden
import {environment} from '@environment';
import {Router} from '@angular/router';

// Import Moment-Modul zur Datumsformatierung
import * as _moment from 'moment';
import {hasOwn} from '@shared/utils';
import {GridColumn} from '@shared/grid-column';

const moment = _moment;

@Component({
    selector: 'phscw-institutions-list',
    templateUrl: './institutions-list.component.html',
    styleUrls: ['./institutions-list.component.scss'],
    providers: [DatePipe],
})
export class InstitutionsListComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    /*
     * ID des Grids
     * 2019-02-25, PhS(TH): Name muss der URL des Moduls entsprechen nach entfernen der Schrägstriche für Zuordnung in GlobalSearch
     */
    gridId = 'institutionsList';

    /*
     *  wechselt auf true, sobald alle Initialisierungen der Liste (Listentries, Kennzeichen, Layout-Infos, ...)
     * fertig sind, wird an das Grid weitergegeben
     */
    initialized = false;

    // Aktuelles Datum wird für die Bezeichnung von Spalten benötigt
    private today: Date = new Date();

    /*
     * Spaltendefinitionen für Grid
     * "institution-name" ersetzt "name1-2", bei IVF aber noch "name1-2" im Einsatz
     * "institution-communication" ersetzt "communication", bei IVF aber noch "communication" im Einsatz
     * "institution-erp-number" ersetzt "erp-number", bei IVF aber noch "erp-Number" im Einsatz
     */
    gridColumns: GridColumn[] = [
        {
            columnDef: 'id',
            header: 'ID',
            cell: (element: Institution) => `${element.id}`,
            formatWidth: '65px',
        },
        {
            columnDef: 'external-customer-id1',
            header: 'Extra-Nr.',
            cell: (element: Institution) => `${element.external_customer_id1 || ''}`,
            formatWidth: '65px',
        },
        {
            columnDef: 'external-customer-id2',
            header: 'Extra-Nr. 2',
            cell: (element: Institution) => `${element.external_customer_id2}`,
            formatWidth: '65px',
        },
        {
            columnDef: 'name1',
            header: 'Name',
            cell: (element: Institution) => `${element.name1 || ''}`,
            formatWidth: '65px',
        },
        {
            columnDef: 'name2',
            header: 'Zusatzbez. 1',
            cell: (element: Institution) => `${element.name2 || ''}`,
            formatWidth: '65px',
        },
        {
            columnDef: 'name1-2',
            header: 'Einrichtung',
            cell: (element: Institution) => {
                if (element.name2) {
                    return `<strong>${element.name1 || ''}</strong><br />${element.name2 || ''}`;
                }
                return `<strong>${element.name1 || ''}</strong>`;
            },
            formatWidth: '250px',
        },
        {
            columnDef: 'name3',
            header: 'Zusatzbez. 2',
            cell: (element: Institution) => `${element.name3 || ''}`,
            formatWidth: '65px',
        },
        {
            columnDef: 'name4',
            header: 'Zusatzbez. 3',
            cell: (element: Institution) => `${element.name4 || ''}`,
            formatWidth: '65px',
        },
        {
            columnDef: 'type1',
            columnField: 'type1',
            header: 'Typ',
            formatTemplate: 'listentries',
            listentry: ['institutionType1'],
            formatWidth: '65px',
        },
        {
            columnDef: 'type2',
            columnField: 'type2',
            header: 'Fachabteilung',
            formatTemplate: 'listentries',
            listentry: ['institutionType2'],
            formatWidth: '65px',
        },
        {
            columnDef: 'institution-address',
            header: 'Adresse',
            cell: (element: Institution) => `${element.street || ''} ${element.street_number_from || ''} ${element.street_number_to || ''}<br>${element.zipcode || ''} ${
                element.city || ''
            }`,
            formatWidth: '200px',
        },
        {
            columnDef: 'district',
            header: 'Ortsteil',
            cell: (element: Institution) => `${element.district || ''}`,
            formatWidth: '65px',
        },
        {
            columnDef: 'country',
            header: 'Land',
            cell: (element: Institution) => `${element.country || ''}`,
            formatWidth: '65px',
        },
        {
            columnDef: 'institution-communication',
            header: 'Telefon',
            headerSecondRow: 'E-Mail',
            cell: (element: Institution) => `${element.phone1 || ''}<br>${element.mail || ''}`,
            formatWidth: '200px',
        },
        {
            columnDef: 'institution-phone-address',
            header: 'Telefon',
            headerSecondRow: 'Anschrift',
            cell: (element: Institution) => `${element.phone1 || ''}<br>${element.street || ''} ${element.street_number_from || ''} ${element.street_number_to || ''}<br>${
                element.zipcode || ''
            } ${element.city || ''}`,
            formatWidth: '200px',
        },
        {
            columnDef: 'phone2',
            header: 'Sonst. Telefonnummer',
            cell: (element: Institution) => `${element.phone2 || ''}`,
            formatWidth: '65px',
        },
        {
            columnDef: 'phone3',
            header: 'Handy',
            cell: (element: Institution) => `${element.phone3 || ''}`,
            formatWidth: '65px',
        },
        {
            columnDef: 'fax',
            header: 'Fax',
            cell: (element: Institution) => `${element.fax || ''}`,
            formatWidth: '65px',
        },
        {
            columnDef: 'internet',
            header: 'Internet-Adresse',
            cell: (element: Institution) => `${element.internet || ''}`,
            formatWidth: '65px',
        },
        {
            columnDef: 'erp-number',
            header: 'Kundennummer',
            cell: (element: Institution) => `${element.erp_number || ''}`,
            formatWidth: '65px',
        },
        {
            columnDef: 'institution-suppliers',
            header: 'Versorger',
            cell: (element: Institution) => `${element.suppliers || ''}`,
            formatWidth: '65px',
            noSort: true,
        },
        {
            columnDef: 'segment_name1',
            header: 'Segment',
            cell: (element: Institution) => `${element.segment_name1 || ''}`,
            formatWidth: '65px',
            tooltip: true,
        },
        {
            columnDef: 'segment_key1',
            header: 'Segment Nr.',
            cell: (element: any) => `${element.segment_key1 || ''}`,
            formatWidth: '65px',
            tooltip: true,
        },
        /*
         * OneKey und Bga nummer werden dynamisch hinzugefügt (Siehe Environment showOneKeyNumber showBgaNumber)
         * {columnDef: 'onekey_number', header: 'OneKey-Nummer', cell: (element: Institution) => `${element.onekey_number || ""}`,
         * formatWidth: '65px'},
         * {columnDef: 'bga_number', header: 'BGA-Nummer', cell: (element: Institution) => `${element.bga_number || ""}`, formatWidth: '65px'},
         */
        {
            columnDef: 'institution-erp-number',
            header: 'Kundennummer',
            cell: (element: Institution) => `${element.erp_number || ''}`,
            formatWidth: '65px',
        },
        /*
         * Eigener Umsatz-Spalte wird dynamisch hinzugefügt. Falls das Environment dies anzeigt. (Siehe checkTurnoverColumn)
         * {columnDef: 'turnover', header: 'Umsatz ' + this.today.getFullYear() + ' <br />Umsatz ' + (this.today.getFullYear() - 1),
         * cell: (element: Institution) => `${""}<br />${""}`, formatWidth: '65px', formatTemplate: 'currency'},
         */
        {
            columnDef: 'last_contact_number_contacts',
            header: this.translateService.instant('GENERAL.LASTCONTACTNUMBERCONTACTS'),
            headerSecondRow: this.translateService.instant('GENERAL.LASTCONTACTNUMBERCONTACTSFIRSTROW', {currentYear: this.today.getFullYear()}),
            headerThirdRow: this.translateService.instant('GENERAL.LASTCONTACTNUMBERCONTACTSSECONDROW', {lastYear: this.today.getFullYear() - 1}),
            cell: (element: Institution) => {
                if (element.last_contact !== undefined && element.last_contact !== null) {
                    /*
                     * Manche Browser wie Safari haben eine sehr strikte Datums-Implementation,
                     * deswegen das Datum über Moment.js zu ISO-String konvertieren, bevor es an die Date-Pipe übergeben wird.
                     */
                    return `${this.datePipe.transform(moment(element.last_contact).toDate().toISOString(), 'dd.MM.yyyy') || ''}<br>${
                        element.number_contacts || 0
                    }<br>${element.number_contacts_last_year || 0}`;
                }
                return `${''}<br>${element.number_contacts || 0}<br>${element.number_contacts_last_year || 0}`;
            },
            formatWidth: '65px',
        },
        {
            columnDef: 'last_contact_contact_method',
            header: 'Letzter Besuch',
            headerSecondRow: 'Letzte Kontaktmethode',
            cell: (element: Institution) => {
                if (element.last_contact !== undefined && element.last_contact !== null) {
                    /*
                     * Manche Browser wie Safari haben eine sehr strikte Datums-Implementation,
                     * deswegen das Datum über Moment.js zu ISO-String konvertieren, bevor es an die Date-Pipe übergeben wird.
                     */
                    return `${this.datePipe.transform(moment(element.last_contact).toDate().toISOString(), 'dd.MM.yyyy') || ''}<br>${
                        element.last_contact_method || ''
                    }`;
                }
                return `${''}`;
            },
            formatWidth: '65px',
        },
        {
            columnDef: 'parentInstitution',
            header: 'Übergeordnete Einrichtung',
            cell: (element: Institution) => {
                if (hasOwn(element, 'parent') && element.parent[0]) {
                    const parentInst = element.parent[0];
                    return `${parentInst.name1 || ''}<br>${parentInst.zipcode || ''} ${parentInst.city || ''}`;
                }
                return `${''}`;
            },
            formatWidth: '65px',
        },
        {
            columnDef: 'regionName',
            header: 'Gebietszuordnung',
            cell: (element: any) => `${element.name || ''}`,
            formatWidth: '65px',
        },
        // Wird als Platzhalterpalte benötigt, damit auf die gridColumns auch später noch Spalten mit der property options gepusht werden können
        {
            columnDef: 'characteristic_empty',
            header: '',
            cell: () => `${''}`,
            formatTemplate: 'characteristics',
            formatWidth: '65px',
            options: null,
        },
    ];

    // Verfügbare Spalten
    gridAllColumns: any[] = [
        {
            id: 'availableBaseDataColumns',
            label: 'Stammdaten',
            expanded: false,
            columns: [
                {
                    id: 'name1',
                    label: 'Name',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                {
                    id: 'name2',
                    label: 'Zusatzbez. 1',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                /*
                 * Nur noch von IVF in Verwendung - Auskommentiert wegen doppeltem Label
                 * {id: 'name1-2', 'label': 'Einrichtung', 'columnGroup': 'availableBaseDataColumns', 'target': 'institutions'},
                 */
                {
                    id: 'institution-name',
                    label: 'Einrichtung',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                {
                    id: 'name3',
                    label: 'Zusatzbez. 2',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                {
                    id: 'name4',
                    label: 'Zusatzbez. 3',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                {
                    id: 'id',
                    label: 'ID',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                {
                    id: 'external-customer-id1',
                    label: 'Extra-Nr.',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                {
                    id: 'type1',
                    label: 'Typ',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                    template: 'listentries',
                    listentry: 'institutionType1',
                },
                {
                    id: 'type2',
                    label: 'Fachbereich',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                    template: 'listentries',
                    listentry: 'institutionType2',
                },
                {
                    id: 'institution-name-child-name',
                    label: 'Einrichtung / Abteilung',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                {
                    id: 'institution-address',
                    label: 'Adresse',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                {
                    id: 'district',
                    label: 'Ortsteil',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                {
                    id: 'country',
                    label: 'Land',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                {
                    id: 'institution-communication',
                    label: 'Telefon / E-Mail',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                {
                    id: 'phone2',
                    label: 'Sonst. Telefonnummer',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                {
                    id: 'phone3',
                    label: 'Handy',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                {
                    id: 'fax',
                    label: 'Fax',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                {
                    id: 'internet',
                    label: 'Internet-Adresse',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                {
                    id: 'institution-suppliers',
                    label: 'Versorger',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                {
                    id: 'institution-phone-address',
                    label: 'Telefon / Anschrift',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                {
                    id: 'erp-number',
                    label: 'Kundennummer',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                {
                    id: 'active-erp-numbers',
                    label: 'Kundennummer',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                {
                    id: 'segment_name1',
                    label: 'Segment',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                {
                    id: 'segment_key1',
                    label: 'Segment-Nr.',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                {
                    id: 'parentInstitution',
                    label: 'Übergeordnete Einrichtung',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'institutions',
                },
                /*
                 * OneKey und Bga nummer werden dynamisch hinzugefügt (Siehe Environment showOneKeyNumber showBgaNumber)
                 * {id: 'onekey_number', 'label': 'OneKey-Nummer', 'columnGroup': 'availableBaseDataColumns', 'target': 'institutions'},
                 * {id: 'bga_number', 'label': 'BGA-Nummer', 'columnGroup': 'availableBaseDataColumns', 'target': 'institutions'},
                 */
                {
                    id: 'regionName',
                    label: 'Gebietszuordnung',
                    columnGroup: 'availableBaseDataColumns',
                    target: 'regions',
                    table: 'regions',
                    column: 'name',
                    alias: 'regionName',
                    template: '',
                },
                /*
                 * Eigener Umsatz-Spalte wird dynamisch hinzugefügt. Falls das Environment dies anzeigt. (Siehe checkTurnoverColumn)
                 * {id: 'turnover', 'label': 'Umsatz ' + (this.today.getFullYear()) + ' / Umsatz ' +
                 * (this.today.getFullYear() - 1), 'columnGroup': 'availableBaseDataColumns', 'target': 'institutions'}
                 */
            ],
        },
        {
            id: 'contacts',
            label: 'Kontakte',
            expanded: false,
            columns: [
                {
                    id: 'last_contact',
                    label: 'Letzter Besuch',
                    columnGroup: 'contacts',
                    target: 'contacts',
                    table: 'institution_last_contact',
                    column: 'last_contact',
                    template: 'date',
                },
                {
                    id: 'last_own_contact',
                    label: 'Letzter eigener Besuch',
                    columnGroup: 'contacts',
                    target: 'contacts',
                    table: 'institution_last_own_contact',
                    column: 'last_own_contact',
                    additionalConditions: [{
                        column: 'region_id',
                        value: 0,
                    }],
                    template: 'date',
                },
                {
                    id: 'next_appointment',
                    label: 'Nächster Termin',
                    columnGroup: 'contacts',
                    target: 'contacts',
                    table: 'next_appointments',
                    column: 'next_appointment',
                    template: 'date',
                },
                {
                    id: 'contacts_frequency',
                    label: 'Besuchsfrequenz',
                    columnGroup: 'contacts',
                    target: 'contacts',
                    table: 'institution_contacts_frequency',
                    column: 'contacts_frequency',
                    template: '',
                },
                {
                    id: 'number_contacts',
                    label: 'Besuche ' + this.today.getFullYear(),
                    columnGroup: 'contacts',
                    target: 'contacts',
                    table: 'institution_contacts',
                    column: 'number_contacts',
                    template: '',
                },
                {
                    id: 'number_contacts_last_year',
                    label: 'Besuche ' + (this.today.getFullYear() - 1),
                    columnGroup: 'contacts',
                    target: 'contacts',
                    table: 'institution_contacts_last_year',
                    column: 'number_contacts_last_year',
                    template: '',
                },
                {
                    id: 'last_contact_number_contacts',
                    label:
                        'Letzer Besuch / Besuche ' +
                        this.today.getFullYear() +
                        ' / Besuche ' +
                        (this.today.getFullYear() - 1),
                    columnGroup: 'contacts',
                    target: 'contacts',
                    table: ['institution_last_contact', 'institution_contacts', 'institution_contacts_last_year'],
                    column: ['last_contact', 'number_contacts', 'number_contacts_last_year'],
                    template: '',
                    overwrite: false,
                },
            ],
        },
    ];

    // Anzuzeigende Spalten für Grid
    gridDisplayedColumns = [
        'select',
        'institution-name',
        'institution-address',
        'institution-communication',
        'erp-number',
    ];

    // Mögliche Layouts für Grid
    gridLayouts: SelectData[] = [];

    /*
     * Mögliche Datenquellen für Grid
     * ------------------------------
     * Hier werden die Standard-Listen vorgegeben.
     * Durch Initialisierung werden diese durch Listentries überschrieben.
     */
    gridSources: SelectData[] = [
        {
            id: '10',
            label: 'Alle',
        },
        {
            id: '20',
            label: 'Suche',
        },
        {
            id: '30',
            label: 'Selektion',
        },
    ];

    // Grid-Filter
    gridRegionsFilter: any = {};

    gridPage = 1;

    // Initiale (Benutzer-)Einstellungen für Grid-Selectboxen "Source" & "Layout"
    initialSource: number;
    initialLayout: number;

    // Definiert, ob über die Toolbar die Terminplanung geöffnet werden darf
    enableScheduling = false;

    // Definiert, ob über die Toolbar neue Einrichtungen hinzugefügt werden dürfen
    permissionAllowNewInstitution = false;
    permissionAllowNewShadowInstitution = false;
    allowNewInstitution = false;

    // Definiert, ob über die Toolbar Einrichtungen gelöscht werden dürfen
    allowDeleteInstitution = false;

    // Definiert ob Einrichtungen zusammengeführt werden dürfen
    allowMergeEntities = false;
    allowEditInstitution = false;
    // Definiert, ob Kennzeichen-Popup benutzt werden kann
    characteristicsPopupEnabled: boolean = environment.characteristicsPopupEnabled;

    // Definiert, ob der Button "Hierarchie anzeigen / ausblenden" in der Toolbar sichtbar ist
    allowChangeHierarchy = false;
    // Flag ob die Hierachie der Einrichtungsliste ignoriert werden soll
    ignoreHierarchy = false;

    // definiert ob button alles laden angezegit wird
    allowLoadAll = false;

    // Mit Checkbox ausgewählte Einrichtungen
    checkedInstitutions: Institution[] = [];

    // Definiert, ob Abteilungen in der Einrichtungsliste auch exportiert werden sollen
    excelExportDepartments = true;

    /*
     * **************************** GRID-EXTENSIONS *****************************
     * Sichtbarkeit der Grid-Extensions
     */
    gridExtensionsVisibility: any = {
        sort: false,
        filter: false,
        columns: false,
        selection: false,
    };

    /*
     * Sortierbare Felder für E-Liste definieren
     * 'active': true, 'direction': 'ASC'
     */
    gridSortFields: FilterData[] = [
        {
            label: 'Name',
            key: 'name',
        },
        {
            label: 'Ort',
            key: 'city',
        },
        {
            label: 'PLZ',
            key: 'zipcode',
        },
        {
            label: 'Ort / Straße',
            key: 'street',
        },
        {
            label: 'PLZ / Straße',
            key: 'zipcode_street',
        },
        {
            label: 'Kundennummer',
            key: 'erp_number',
        },
    ];

    // Für Filterung benötigte Daten
    gridFrontendArray: any[] = [];
    gridTransferArray: any[] = [];
    // **************************************************************************

    // Counter für Retries zum Holen von SavedUserSelections
    savedSelectionsRetryCounter = 0;

    // Flag ob alles geladen werden soll
    loadAll = false;

    /**
     * Konstruktor (inkl. dependency injection)
     * @param datePipe
     * @param gridService
     * @param storageService
     * @param initService
     * @param institutionsService
     * @param institutionsPeopleService
     * @param toolbarService
     * @param dialog
     * @param regionsfilterService
     * @param userPermissions
     * @param userSettingsService
     * @param institutionsListService
     * @param gridSelectionService
     * @param translateService
     * @param gridColumnsService
     * @param clearingSharedService
     * @param ClearingDuplicatesPanelService
     * @param router
     * @param changeDetector
     */
    constructor(
        private datePipe: DatePipe,
        private gridService: GridService,
        private storageService: StorageService,
        private initService: InitService,
        private institutionsService: InstitutionsService,
        private institutionsPeopleService: InstitutionsPeopleService,
        private toolbarService: ToolbarService,
        private dialog: MatDialog,
        private regionsfilterService: GlobalRegionsfilterService,
        private userPermissions: UserPermissionsService,
        private userSettingsService: UserSettingsService,
        private institutionsListService: InstitutionsListService,
        private gridSelectionService: GridSelectionService,
        private translateService: TranslateService,
        private gridColumnsService: GridColumnsService,
        public clearingSharedService: ClearingSharedService,
        private ClearingDuplicatesPanelService: ClearingDuplicatesPanelService,
        private router: Router,
        private changeDetector: ChangeDetectorRef,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        /*
         * Erlaubnis zur Neuanlage initial prüfen für Total (falls der Regionsfilter bereits
         * das Event geschickt hat, bevor Subscriptions in der Liste vorhanden waren)
         */
        this.checkAllowNewInstitution(0, 0, 0);

        // Prüfe aktivierte variable Spalten
        this.enableColumns();

        // Zwischenlösung um zu entscheiden, ob die "Eigener Umsatz"-Spalte auswählbar sein soll.
        this.checkTurnoverColumn();
        // Zwischenlösung um zu entscheiden, ob die "OneKey"-Spalte auswählbar sein soll.
        this.checkOneKeyNumber();
        // Zwischenlösung um zu entscheiden, ob die "Bga"-Spalte auswählbar sein soll.
        this.checkBgaNumber();
        // Zwischenlösung um zu entscheiden, ob die "Zusatz 2"-Spalte auswählbar sein soll.
        this.checkAdditional2Column();
        // Zwischenlösung um zu entscheiden, ob die "Zusatz 3"-Spalte auswählbar sein soll.
        this.checkAdditional3Column();
        // Zwischenlösung um zu entscheiden, ob die "Extra Nr."-Spalte auswählbar sein soll.
        this.checkExternalCustomerId1Column();
        // Zwischenlösung um zu entscheiden, ob die "Nächster Termin"-Spalte auswählbar sein soll.
        this.checkNextAppointmentColumn();
        // Zwischenlösung um zu entscheiden, welche "ERP-Nummer"-Spalte auswählbar sein soll.
        this.checkActiveErpNumbersColumn();

        // Übersetzungen subscriben
        this.initializeTranslateSubscriptions();
        // Events subscriben
        this.initializeEventSubscriptions();

        // Benutzer-Einstellungen für Grid-Selectboxen "Source" & "Layout"
        this.initialSource = this.userSettingsService.getValue('institutionsListSource');
        this.initialLayout = this.userSettingsService.getValue('institutionsListLayout');
        // Kundeneinstellungen für Custom-Sortierung laden;
        this.checkCustomSort();
        // Benutzer-Einstellung für gewünschte Sortierung
        this.initSort(this.userSettingsService.getValue('institutionsListSort'));
        // Prüfe, ob das Nutzen der Terminplanung erlaubt ist oder nicht

        this.checkEnableScheduling();
        // Prüfen ob die Alle Laden Funktion aktiv sein soll
        this.checkEnableLoadAll();
        // Prüfe, ob das Einrichtung-Löschen erlaubt ist oder nicht
        this.checkAllowDeleteInstitution();
        // Berechtigung "allowMergeEntities" prüfen
        this.checkAllowMergeEntities();
        this.checkAllowEditInstitution();

        // Prüft, ob beim Excel Export die Abteilungen exportiert werden sollen
        this.checkAllowExcelExportDeparments();

        // Berechtigung "allowMergeEntities" prüfen
        if (this.clearingSharedService.allowMergeEntities === null) {
            this.clearingSharedService.checkAllowMergeEntities();
        }

        // Prüft, ob das ein-/ausblenden der Hierarchie erlaubt ist
        this.checkAllowChangeHierarchy();

        if (this.initService.initialized) {
            // Initialisierungen die abhängig davon sind, ob der InitService alles fertig geladen hat
            this.onAllInitialized();
        } else {
            /*
             * Nur wenn der Init-Service jetzt noch nicht fertig ist, subscribe das Event
             * Alle Daten beim Login wurden in den Storage geladen
             */
            this.initService.allInitialized.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: boolean) => {
                if (result) {
                    this.onAllInitialized();
                }
            });
        }
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * Führt die Initialisierungen durch, die erst gemacht werden können, wenn der InitService seine Arbeit beendet hat
     * @todo Wenn sich dieser Mechanismus bewährt hat, prüfen, ob das für alle Haupt-Listen zentral gemacht werden kann,
     *  um redundanten Code zu minimieren
     */
    onAllInitialized() {
        if (this.initialized) {
            /*
             * Tue nichts, wenn bereits initialisiert, sollte wider Erwarten die Funktion zweimal ausgelöst werden
             * console.warn("Institution List ist bereits initialisiert, ignoriere Aufruf onAllInitialized.");
             */
            return;
        }

        // nutze den FutureMonitor, um alle Observables und Promises "einzufangen"
        const callback = new Subject<boolean>();
        const futureMonitor = new FutureMonitor(callback);
        futureMonitor.name = this.gridId;

        callback.subscribe(() => {
            // Laden der Layout-Daten ganz am Schluss, da dies das Laden der Listendaten auslöst
            this.loadLayoutData();
        });

        futureMonitor.addPromises(this.initializeGridSources());
        futureMonitor.addPromises(this.initializeCharacteristics());
        futureMonitor.addPromise(this.updateLastOwnContactColumn());

        // Monitor wird aktiviert, erst dann benachrichtigt er die Subscriber, wenn alle Requests und Promises beendet sind
        futureMonitor.startWatching();
    }

    /**
     * Zwischenlösung bis die Spaltenkonfiguration ins Backend ausgelagert wurde.
     */
    checkTurnoverColumn(): void {
        let showOwnColumn = false;
        if (typeof environment.showOwnTurnoverColumn !== 'undefined') {
            showOwnColumn = environment.showOwnTurnoverColumn;
        }
        if (showOwnColumn) {
            this.gridColumns.push({
                columnDef: 'turnover_own',
                header: 'Umsatz ' + this.today.getFullYear(),
                headerSecondRow: 'Umsatz ' + (this.today.getFullYear() - 1),
                cell: (element: any) => {
                    if (element['turnover_own_current'] || element['turnover_own_last_year']) {
                        return `${element['turnover_own_current'] || '0'}<br />${element['turnover_own_last_year'] || '0'}`;
                    }
                    return '';
                },
                formatWidth: '65px',
                formatTemplate: 'currency',
            });
            const allColumnsBaseColumns: any[] = this.gridAllColumns[0]['columns'];
            allColumnsBaseColumns.push({
                id: 'turnover_own',
                label: 'Umsatz ' + this.today.getFullYear() + ' / Umsatz ' + (this.today.getFullYear() - 1),
                columnGroup: 'availableBaseDataColumns',
                target: 'institutions',
            });
        } else {
            this.gridColumns.push({
                columnDef: 'turnover',
                header: 'Umsatz ' + this.today.getFullYear(),
                headerSecondRow: 'Umsatz ' + (this.today.getFullYear() - 1),
                cell: (element: any) => {
                    if (element['invoices_sum'] || element['invoices_sum_last_year']) {
                        return `${element['invoices_sum'] || '0'}<br />${element['invoices_sum_last_year'] || '0'}`;
                    }
                    return '';
                },
                formatWidth: '65px',
                formatTemplate: 'currency',
            });
            const allColumnsBaseColumns: any[] = this.gridAllColumns[0]['columns'];
            allColumnsBaseColumns.push({
                id: 'turnover',
                label: 'Umsatz ' + this.today.getFullYear() + ' / Umsatz ' + (this.today.getFullYear() - 1),
                columnGroup: 'availableBaseDataColumns',
                target: 'contacts',
                table: ['institution_sales', 'institution_sales_last_year'],
                column: ['invoices_sum', 'invoices_sum_last_year'],
                template: '',
                overwrite: false,
            });
        }
    }

    /**
     * Variable Spalten aktivieren oder deaktivieren
     */
    enableColumns() {
        // Favoriten Spalte aktivieren
        if (
            Object.prototype.hasOwnProperty.call(environment, 'institutionsFavoriteColumnEnabled') &&
            environment.institutionsFavoriteColumnEnabled === true
        ) {
            const index = this.gridAllColumns.findIndex((column: any) => column.id === 'availableBaseDataColumns');
            const allColumnsBaseColumns: any[] = this.gridAllColumns[index]['columns'];
            allColumnsBaseColumns.push({
                id: 'user-favorite',
                label: 'Favorit',
                columnGroup: 'availableBaseDataColumns',
                target: 'institutions',
            });
        }
    }

    /**
     * Zwischenlösung bis die Spaltenkonfiguration ins Backend ausgelagert wurde.
     */
    checkOneKeyNumber(): void {
        let hideColumn = false;
        if (Object.prototype.hasOwnProperty.call(environment, 'showOneKeyNumberColumn')) {
            hideColumn = environment.showOneKeyNumberColumn;
        }
        if (hideColumn) {
            this.gridColumns.push({
                columnDef: 'onekey_number',
                header: 'OneKey-Nummer',
                cell: (element: Institution) => `${element.onekey_number || ''}`,
                formatWidth: '65px',
            });
            const allColumnsBaseColumns: any[] = this.gridAllColumns[0]['columns'];
            allColumnsBaseColumns.push({
                id: 'onekey_number',
                label: 'OneKey-Nummer',
                columnGroup: 'availableBaseDataColumns',
                target: 'institutions',
            });
        }
    }

    /**
     * Zwischenlösung bis die Spaltenkonfiguration ins Backend ausgelagert wurde.
     */
    checkBgaNumber(): void {
        let hideColumn = false;
        if (Object.prototype.hasOwnProperty.call(environment, 'showBgaNumberColumn')) {
            hideColumn = environment.showBgaNumberColumn;
        }
        if (hideColumn) {
            this.gridColumns.push({
                columnDef: 'bga_number',
                header: 'BGA-Nummer',
                cell: (element: Institution) => `${element.bga_number || ''}`,
                formatWidth: '65px',
            });
            const allColumnsBaseColumns: any[] = this.gridAllColumns[0]['columns'];
            allColumnsBaseColumns.push({
                id: 'bga_number',
                label: 'BGA-Nummer',
                columnGroup: 'availableBaseDataColumns',
                target: 'institutions',
            });
        }
    }

    /**
     * Zwischenlösung bis die Spaltenkonfiguration ins Backend ausgelagert wurde.
     */
    checkAdditional2Column(): void {
        let hideColumn = false;
        if (Object.prototype.hasOwnProperty.call(environment, 'hideAdditional2Column')) {
            hideColumn = environment.hideAdditional2Column;
        }
        if (hideColumn) {
            const allColumnsBaseColumns: any[] = this.gridAllColumns[0]['columns'];
            const IndexAdditional2 = allColumnsBaseColumns.findIndex((columns) => columns['id'] == 'name3');
            allColumnsBaseColumns.splice(IndexAdditional2, 1);
        }
    }

    /**
     * Zwischenlösung bis die Spaltenkonfiguration ins Backend ausgelagert wurde.
     */
    checkAdditional3Column(): void {
        let hideColumn = false;
        if (Object.prototype.hasOwnProperty.call(environment, 'hideAdditional3Column')) {
            hideColumn = environment.hideAdditional3Column;
        }
        if (hideColumn) {
            const allColumnsBaseColumns: any[] = this.gridAllColumns[0]['columns'];
            const IndexAdditional3 = allColumnsBaseColumns.findIndex((columns) => columns['id'] == 'name4');
            allColumnsBaseColumns.splice(IndexAdditional3, 1);
        }
    }

    /**
     * Zwischenlösung bis die Spaltenkonfiguration ins Backend ausgelagert wurde.
     */
    checkExternalCustomerId1Column(): void {
        let hideColumn = false;
        if (Object.prototype.hasOwnProperty.call(environment, 'hideExternalCustomerId1Column')) {
            hideColumn = environment.hideExternalCustomerId1Column;
        }
        if (hideColumn) {
            const allColumnsBaseColumns: any[] = this.gridAllColumns[0]['columns'];
            const IndexExternalCustomerId1Column = allColumnsBaseColumns.findIndex(
                (columns) => columns['id'] == 'external-customer-id1',
            );
            allColumnsBaseColumns.splice(IndexExternalCustomerId1Column, 1);
        }
    }

    /**
     * Zwischenlösung bis die Spaltenkonfiguration ins Backend ausgelagert wurde.
     */
    checkNextAppointmentColumn(): void {
        let hideColumn = false;
        if (Object.prototype.hasOwnProperty.call(environment, 'hideNextAppointmentColumn')) {
            hideColumn = environment.hideNextAppointmentColumn;
        }
        if (hideColumn) {
            const allColumnsBaseColumns: any[] = this.gridAllColumns[1]['columns'];
            const IndexNextAppointmentColumn = allColumnsBaseColumns.findIndex(
                (columns) => columns['id'] == 'next_appointment',
            );
            allColumnsBaseColumns.splice(IndexNextAppointmentColumn, 1);
        }
    }

    /**
     * Zwischenlösung bis die Spaltenkonfiguration ins Backend ausgelagert wurde.
     */
    checkActiveErpNumbersColumn(): void {
        // Konfiguration laden
        const datafieldConfiguration: LooseObject = environment.institutionsDatafields;

        // Allgemeine Spaltengruppe finden
        const defaultColumnGroup = this.gridAllColumns.find(
            (groups: LooseObject) => groups.id === 'availableBaseDataColumns',
        );

        // ERP-Spalte ausblenden
        if (datafieldConfiguration.erp_number.visible === false) {
            // Spalte "ERP-Nummer" finden
            const erpColumnIndex = defaultColumnGroup.columns.findIndex(
                (column: LooseObject) => column.id === 'erp-number',
            );
            defaultColumnGroup.columns.splice(erpColumnIndex, 1);
        }

        // neue Spalte hinzufügen
        if (datafieldConfiguration.supplementary_numbers.visible === false) {
            // Spalte "aktive ERP-Nummern" finden
            const activeErpColumnIndex = defaultColumnGroup.columns.findIndex(
                (column: LooseObject) => column.id === 'active-erp-numbers',
            );
            defaultColumnGroup.columns.splice(activeErpColumnIndex, 1);
        }
    }

    /**
     * Mögliche Datenquellen (gridSource) initialisieren
     * @param {any}  key
     * @returns {Promise<any>[]}
     */
    initializeGridSources(key: any = undefined): Promise<any>[] {
        // Daten über Service anfordern, zuerst die Listentry-Daten laden, danach die gespeicherten Selektionen
        const promise = this.storageService
            .getItem('listentries|institutionsSelection')
            .then((val) => this.onGetListentriesGridSources(val))
            .then(() => this.storageService.getItem('institutionsListSavedSelections'))
            .then((val) => this.onGetSavedSelectionsGridSources(val, key));

        // gib die Promises zurück, damit die Initialisierung auf die Fertigstellung warten kann
        return [promise];
    }

    /**
     * Mögliche Datenquellen (gridSource) aus Listentries empfangenf
     * @param {any}  value
     */
    onGetListentriesGridSources(value: any): void {
        // Falls Eintrag aus "listentries" vorhanden ist
        if (value) {
            // Reset
            const currentGridSources: SelectData[] = [];

            // Listentries durchlaufen
            for (const source of value) {
                const newSourceItem: SelectData = {
                    id: '' + source['list_key'],
                    label: source['list_value'],
                    data: source['list_data'],
                };
                currentGridSources.push(newSourceItem);
            }

            // Referenz des Array aktualisieren, damit die Änderung in der GridSources-Komponente im Setter erkannt wird
            this.gridSources = [].concat(currentGridSources);
        }
    }

    /**
     * Versucht gespeicherte Selektionen aus der IndexedDB zu holen und sie an die gridSources anzuhängen
     * @param {any}  value
     * @param {any}  key
     */
    onGetSavedSelectionsGridSources(value: any, key: any = undefined): void {
        // Init
        const currentGridSources: SelectData[] = this.gridSources;

        // Falls es einen Eintrag in der IndexedDB dazu gibt
        if (value) {
            for (const source of value) {
                // Sonderfall für letzte durchgeführte Selektion
                if (source['list_value'] === 'lastSelection') {
                    const selectionKey = currentGridSources.findIndex((obj) => obj.id == 30);
                    currentGridSources[selectionKey]['data'] = source['list_data'];
                } else {
                    const newSourceItem: SelectData = {
                        id: '' + source['list_key'],
                        label: source['list_value'],
                        data: source['list_data'],
                    };
                    currentGridSources.push(newSourceItem);
                }
            }

            // Referenz des Array aktualisieren, damit die Änderung in der GridSources-Komponente im Setter erkannt wird
            this.gridSources = [].concat(currentGridSources);
        }

        /*
         * Wenn Selektionen gespeichert werden muss das gleiche Vorgehen,
         * wie beim Ausführen von Selektionen gestartet werden. Dies kann
         * aber erst getan werden, nachdem die Selektionen aus der Indexed-DB in
         * die Gridsources geladen wurden (Key 1000 /'30' ist der key für die
         * Selektion, bei diesen soll die service-Funktion nicht aufgerufen
         * werden, da es hier schon eine separate Logik für gibt)
         */
        if (typeof key !== 'undefined' && key.value !== 'lastSelection') {
            this.gridSelectionService.startSelection('institutionsList', key.key);
        }
    }

    /**
     * @brief   Übersetzungen subscriben
     * @details Subscribe auf Stream bekommt Änderung der Sprache mit
     *          und lädt Übersetzungen neu statt nur bei Initialisierung
     * @todo    Keys für stream() in Variable auslagern sobald von ngx-translate unterstützt wird
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeTranslateSubscriptions(): void {
        this.translateService
            .stream(
                [
                    'GENERAL.NAME',
                    'GENERAL.CITY',
                    'GENERAL.ZIPCODE',
                    'GENERAL.STREET',
                    'GENERAL.ERP',
                    'GENERAL.INSTITUTION',
                    'MODULES.INSTITUTIONS.DATA.ADDITIONAL1',
                    'MODULES.INSTITUTIONS.DATA.ADDITIONAL2',
                    'MODULES.INSTITUTIONS.DATA.ADDITIONAL3',
                    'GENERAL.TYPE',
                    'GENERAL.DEPARTMENT',
                    'GENERAL.ADDRESS',
                    'GENERAL.DISTRICT',
                    'GENERAL.COUNTRY',
                    'GENERAL.ADDRESS',
                    'GENERAL.PHONE',
                    'GENERAL.MAIL',
                    'MODULES.INSTITUTIONS.DATA.PHONE2',
                    'MODULES.INSTITUTIONS.DATA.PHONE3',
                    'GENERAL.FAX',
                    'GENERAL.REGIONASSIGNMENT',
                    'MODULES.INSTITUTIONS.DATA.INTERNET',
                    'SHARED.POPUP.SCHEDULING.LASTCONTACT',
                    'GENERAL.EXTERNALCUSTOMERNUMBER1',
                    'GENERAL.EXTERNALCUSTOMERNUMBER2',
                    'GENERAL.SEGMENT',
                    'GENERAL.SEGMENTNUMBER',
                    'GENERAL.ONEKEY',
                ],
            )
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((translation: any) => {
                this.gridSortFields.find((field: any) => field.key === 'name').label = translation['GENERAL.NAME'];
                this.gridSortFields.find((field: any) => field.key === 'city').label = translation['GENERAL.CITY'];
                this.gridSortFields.find((field: any) => field.key === 'zipcode').label =
                    translation['GENERAL.ZIPCODE'];
                this.gridSortFields.find((field: any) => field.key === 'street').label =
                    translation['GENERAL.CITY'] + ' / ' + translation['GENERAL.STREET'];
                this.gridSortFields.find((field: any) => field.key === 'zipcode_street').label =
                    translation['GENERAL.ZIPCODE'] + ' / ' + translation['GENERAL.STREET'];
                this.gridSortFields.find((field: any) => field.key === 'erp_number').label = translation['GENERAL.ERP'];

                // Erp-Nummer-Spalten umbenennen
                const erpColumnDef1 = this.gridColumns.find((obj) => obj.columnDef === 'institution-erp-number');
                const erpColumnDef2 = this.gridColumns.find((obj) => obj.columnDef === 'erp-number');
                if (erpColumnDef1 !== undefined) {
                    erpColumnDef1.header = translation['GENERAL.ERP'];
                }
                if (erpColumnDef2 !== undefined) {
                    erpColumnDef2.header = translation['GENERAL.ERP'];
                }
                // Erp-Nummern-Spalte in this.gridAllColumns umbenennen. Achtung: Statisch
                const allColumns: any[] = this.gridAllColumns[0]['columns'];
                const erpColumnDef3 = allColumns.find((obj) => obj.id === 'erp-number');
                if (erpColumnDef3 !== undefined) {
                    erpColumnDef3.label = translation['GENERAL.ERP'];
                }
                // ERP-Nummern-Spalte in Spaltenauswahl umbenennen
                const defaultColumnGroup = this.gridAllColumns.find(
                    (groups: LooseObject) => groups.id === 'availableBaseDataColumns',
                );
                const activeErpNumberColumn = defaultColumnGroup.columns.find(
                    (column: LooseObject) => column.id === 'active-erp-numbers',
                );
                if (activeErpNumberColumn !== undefined) {
                    activeErpNumberColumn.label = translation['GENERAL.ERP'];
                }

                // Segment-Spalte in Spaltenauswahl umbenennen
                const activeSegmentColumn = defaultColumnGroup.columns.find(
                    (column: LooseObject) => column.id === 'segment_name1',
                );
                if (activeSegmentColumn !== undefined) {
                    activeSegmentColumn.label = translation['GENERAL.SEGMENT'];
                }

                // Segment-Nr-Spalte in Spaltenauswahl umbenennen
                const activeSegmentNumberColumn = defaultColumnGroup.columns.find(
                    (column: LooseObject) => column.id === 'segment_key1',
                );
                if (activeSegmentNumberColumn !== undefined) {
                    activeSegmentNumberColumn.label = translation['GENERAL.SEGMENTNUMBER'];
                }

                const activeOneKeyNumberColumn = (defaultColumnGroup.columns as LooseObject[]).find(
                    (column: LooseObject) => column.id === 'onekey_number',
                );
                if (activeOneKeyNumberColumn !== undefined) {
                    activeOneKeyNumberColumn.label = translation['GENERAL.ONEKEY'];
                }

                const oneKeyNumberColumnDef = this.gridColumns.find((obj) => obj.columnDef === 'onekey_number');
                if (oneKeyNumberColumnDef !== undefined) {
                    oneKeyNumberColumnDef.header = translation['GENERAL.ONEKEY'];
                }

                const segmentColumnDef = this.gridColumns.find((obj) => obj.columnDef === 'segment_name1');
                if (segmentColumnDef !== undefined) {
                    segmentColumnDef.header = translation['GENERAL.SEGMENT'];
                }

                const segmentNumberColumnDef = this.gridColumns.find((obj) => obj.columnDef === 'segment_key1');
                if (segmentNumberColumnDef !== undefined) {
                    segmentNumberColumnDef.header = translation['GENERAL.SEGMENTNUMBER'];
                }

                this.gridColumns.find((column: any) => column.columnDef === 'name1').header =
                    translation['GENERAL.NAME'];
                this.gridColumns.find((column: any) => column.columnDef === 'name2').header =
                    translation['MODULES.INSTITUTIONS.DATA.ADDITIONAL1'];
                this.gridColumns.find((column: any) => column.columnDef === 'name1-2').header =
                    translation['GENERAL.INSTITUTION'];
                this.gridColumns.find((column: any) => column.columnDef === 'name3').header =
                    translation['MODULES.INSTITUTIONS.DATA.ADDITIONAL2'];
                this.gridColumns.find((column: any) => column.columnDef === 'name4').header =
                    translation['MODULES.INSTITUTIONS.DATA.ADDITIONAL3'];
                this.gridColumns.find((column: any) => column.columnDef === 'type1').header =
                    translation['GENERAL.TYPE'];
                this.gridColumns.find((column: any) => column.columnDef === 'type2').header =
                    translation['GENERAL.DEPARTMENT'];
                this.gridColumns.find((column: any) => column.columnDef === 'institution-address').header =
                    translation['GENERAL.ADDRESS'];
                this.gridColumns.find((column: any) => column.columnDef === 'district').header =
                    translation['GENERAL.DISTRICT'];
                this.gridColumns.find((column: any) => column.columnDef === 'country').header =
                    translation['GENERAL.COUNTRY'];
                this.gridColumns.find((column: any) => column.columnDef === 'institution-communication').header =
                    translation['GENERAL.PHONE'];
                this.gridColumns.find(
                    (column: any) => column.columnDef === 'institution-communication',
                ).headerSecondRow = translation['GENERAL.MAIL'];

                this.gridColumns.find((column: any) => column.columnDef === 'institution-phone-address').header =
                    translation['GENERAL.PHONE'];
                this.gridColumns.find(
                    (column: any) => column.columnDef === 'institution-phone-address',
                ).headerSecondRow = translation['GENERAL.ADDRESS'];

                this.gridColumns.find((column: any) => column.columnDef === 'phone2').header =
                    translation['MODULES.INSTITUTIONS.DATA.PHONE2'];
                this.gridColumns.find((column: any) => column.columnDef === 'phone3').header =
                    translation['MODULES.INSTITUTIONS.DATA.PHONE3'];
                this.gridColumns.find((column: any) => column.columnDef === 'fax').header = translation['GENERAL.FAX'];
                this.gridColumns.find((column: any) => column.columnDef === 'internet').header =
                    translation['MODULES.INSTITUTIONS.DATA.INTERNET'];
                this.gridColumns.find((column: any) => column.columnDef === 'erp-number').header =
                    translation['GENERAL.ERP'];
                this.gridColumns.find((column: any) => column.columnDef === 'institution-erp-number').header =
                    translation['GENERAL.ERP'];
                this.gridColumns.find((column: any) => column.columnDef === 'regionName').header =
                    translation['GENERAL.REGIONASSIGNMENT'];
                this.gridColumns.find((column: any) => column.columnDef === 'external-customer-id1').header =
                    translation['GENERAL.EXTERNALCUSTOMERNUMBER1'];
                this.gridColumns.find((column: any) => column.columnDef === 'external-customer-id2').header =
                    translation['GENERAL.EXTERNALCUSTOMERNUMBER2'];

                /*
                 *  Spalten erst zwischenspeichern, da sonst ein Compiler-Error auftritt,
                 * weil eine Typsignatur im Objekt fehlt (@todo: Definition korrigieren)
                 */
                const tempColumnGroup: any[] = this.gridAllColumns[0]['columns'];
                const externalCustomerId1Column = tempColumnGroup.find(
                    (column: any) => column.id === 'external-customer-id1',
                );
                if (externalCustomerId1Column !== undefined) {
                    externalCustomerId1Column.label = translation['GENERAL.EXTERNALCUSTOMERNUMBER1'];
                }
            });
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Event "eventGridSelectionChanged" von "gridService"
        this.gridService.eventGridSelectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht vom eigenen Grid (E-Liste) kam
                if (event.sender != 'institutionsList') {
                    return;
                }
                this.onEventGridSelectionChanged(event);
            });

        // Event "eventGridEmptySelection" von "gridService"
        this.gridService.eventGridEmptySelection
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls diese Komponente nicht Ziel des Events ist
                if (event.target != 'institutionsList') {
                    return;
                }
                this.onEventGridEmptySelection();
            });

        // Wenn Regionsfilter geändert wird
        this.regionsfilterService.eventRegionsfilterChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls Regionsfilter nicht für Einrichtungen gilt
                if (event.target != 'institutions') {
                    return;
                }
                this.onEventRegionsfilterChanged(event);
            });

        // Andere Liste wurde ausgewählt, speichere Daten der Selection-Komponente
        this.gridSelectionService.eventSaveData
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                if (result['data']['oldList'] === 'institutions') {
                    this.gridFrontendArray = result['data']['frontend'];
                    this.gridTransferArray = result['data']['transfer'];
                }
            });

        // Die gespeicherten Selektionen wurden verändert. Neu Laden der Daten aus dme Storage
        this.gridSelectionService.eventReloadSavedSelections
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls diese Komponente nicht Ziel des Events ist
                if (event.target != 'institutionsList') {
                    return;
                }
                this.initializeGridSources(event.data);
            });

        // Spaltenauswahl
        this.gridService.eventGridColumnChoiceChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Ziel des Events prüfen
                const event: CWEvent = result;
                // Abbruch, falls diese Komponente nicht Ziel des Events ist
                if (event.target != 'institutionsList') {
                    return;
                }
                this.onEventGridColumnChoiceChanged(result);
            });

        // Layouts ggf. neu laden
        this.gridColumnsService.eventGridReloadLayouts
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls diese Komponente nicht Ziel des Events ist
                if (event.target != 'institutionsList') {
                    return;
                }
                this.loadLayoutData(event['data']);
            });

        // Checkbox im Grid wurde angeklickt...
        this.gridService.eventGridCheckboxClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls das Event nicht vom eigenen Grid kam
                if (event.sender != 'institutionsList') {
                    return;
                }
                this.onEventGridCheckboxClicked(event);
            });

        // Auf Klick der Favoriten-Spalte reagieren
        this.gridService.eventGridFavoriteIconClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls diese Komponente nicht Ziel des Events ist
                if (event.sender !== 'institutionsList') {
                    return;
                }
                this.onEventGridFavoriteIconClicked(event);
            });

        // Event der Toolbar zum Ein-/Ausblenden einer Grid-Extension
        this.toolbarService.eventGridExtensionToggle
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Ziel des Events prüfen
                const event: CWEvent = result;
                // Abbruch, falls diese Komponente nicht Ziel des Events ist
                if (event.target != 'institutionsList') {
                    return;
                }
                this.onEventGridExtensionToggle(event);
            });

        // Event der Toolbar zum Anlegen einer neuen Einrichtung
        this.toolbarService.eventAddItem.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: CWEvent) => {
            // Event-Daten
            const event: CWEvent = result;
            // Abbruch, falls diese Komponente nicht Ziel des Events ist
            if (event.target != 'institutionsList' && event.target != 'institutionsDetails') {
                return;
            }
            this.onEventAddItem(event);
        });

        // Event der Toolbar zum Löschen einer Einrichtung
        this.toolbarService.eventDeleteItem.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: CWEvent) => {
            // Event-Daten
            const event: CWEvent = result;
            // Abbruch, falls diese Komponente nicht Ziel des Events ist
            if (event.target != 'institutionsList') {
                return;
            }
            this.onEventDeleteItem();
        });

        // Excel Export Button wurde in Toolbar geklickt
        this.toolbarService.eventExcelExport.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: CWEvent) => {
            // Event-Daten
            const event: CWEvent = result;
            // Abbruch, falls diese Komponente nicht Ziel des Events ist
            if (
                event.sender === 'toolbar' &&
                'data' in event &&
                'gridId' in event.data &&
                event.data.gridId === this.gridId
            ) {
                this.onEventExcelExport();
            }
        });

        // Event der Toolbar zum Anlegen einer Änderungsanfrage für Einrichtung
        this.toolbarService.eventAddShadowItem
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls diese Komponente nicht Ziel des Events ist
                if (event.target != 'institutionsList' && event.target != 'institutionsDetails') {
                    return;
                }
                this.onEventAddItem(event, true);
            });

        // Button in Toolbar wurde angeklickt...
        this.toolbarService.eventToolbarButtonClicked
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls diese Komponente nicht Ziel des Events ist
                if (event.target != 'institutionsList') {
                    return;
                }
                this.onEventToolbarButtonClicked(event);
            });

        // Button in Checkboxen wurden zurückgesetzt
        this.gridService.eventUnsetGridCheckboxes
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls diese Komponente nicht Ziel des Events ist
                if (event.target != 'institutionsList') {
                    return;
                }
                this.checkedInstitutions = [];
            });

        // Zusammenführen wurde in Duplikat-Panel ausgelöst...
        this.ClearingDuplicatesPanelService.eventMergeSelectedDuplicate
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: CWEvent) => {
                // Event-Daten
                const event: CWEvent = result;
                // Abbruch, falls diese Komponente nicht Ziel des Events ist
                if (
                    event.sender !== 'clearing-duplicates-panel' ||
                    event.data.entityType !== 'institutions' ||
                    typeof event.data.selectedEntities === 'undefined'
                ) {
                    return;
                }
                // Dialog öffnen
                this.openMergeDialog(event.data.selectedEntities);
            });
    }

    /**
     * Kennzeichendaten laden
     * @returns {Promise<any>[]}
     */
    initializeCharacteristics(): Promise<any>[] {
        const promises: Promise<any>[] = [];
        let characteristicGroups: any = [];

        const promiseCharacteristicGroups = this.storageService.getItem('characteristicGroups').then((result: any) => {
            // Wenn die Kennzeichendaten noch nicht in der IndexedDB sind: early-return.
            if (result === null) {
                return;
            }
            characteristicGroups = result;
            for (let i = 0; i < characteristicGroups.length; i++) {
                characteristicGroups[i]['columns'] = [];
            }
            // Kennzeichen der einzelnen Gruppen laden
            for (let i = 0; i < characteristicGroups.length; i++) {
                const promise = this.storageService
                    .getItem('characteristicsForGroup|' + characteristicGroups[i]['id'])
                    .then((characteristics: any) => {
                        characteristicGroups[i]['columns'] = characteristics;

                        for (let j = 0; j < characteristicGroups[i]['columns'].length; j++) {
                            characteristicGroups[i]['columns'][j]['columnGroup'] = characteristicGroups[i]['id'];
                            characteristicGroups[i]['columns'][j]['target'] = 'characteristics';
                            characteristicGroups[i]['columns'][j]['characteristic_id'] =
                                characteristicGroups[i]['columns'][j]['id'];
                            characteristicGroups[i]['columns'][j]['id'] =
                                'characteristic_' + characteristicGroups[i]['columns'][j]['id'];
                        }
                    });
                // wichtig: es muss das Promise _nach_ dem then beobachtet werden
                promises.push(promise); // Promise beobachten
            }

            for (let i = 0; i < characteristicGroups.length; i++) {
                if (characteristicGroups[i]['characteristic_group_type'] === 'institution') {
                    this.gridAllColumns.push(characteristicGroups[i]);
                }
            }
        });

        promises.push(promiseCharacteristicGroups);

        // gib die Promises zurück, damit die Initialisierung auf die Fertigstellung warten kann
        return promises;
    }

    /**
     * Auf Event "eventGridSelectionChanged" von "gridService" reagieren
     * @param {CWEvent} event
     */
    onEventGridSelectionChanged(event: CWEvent): void {
        // Router-Link zusammenbauen
        const routerLink: string = '/institutions/' + event.data['selectedRow'].id;
        // Route-Navigation ausführen
        this.router.navigate([routerLink]);

        // Change-Detection manuell auslösen
        this.changeDetector.detectChanges();
    }

    /**
     * Auf Event "eventGridEmptySelection" von "gridService" reagieren
     */
    onEventGridEmptySelection(): void {
        // An eigenen Service weiterleiten
        this.institutionsService.selectEmptyInstitution();
    }

    /**
     * Auf Event "eventRegionsfilterChanged" des globalen Regionsfilters reagieren
     * @param {CWEvent} event
     */
    onEventRegionsfilterChanged(event: CWEvent): void {
        // Grid-Filter setzen (über Binding wird geänderter Filter direkt an Grid weitergegeben)
        if (event.data['refreshGrid'] === true) {
            this.gridRegionsFilter = {
                division: event.data['division'],
                region: event.data['region'],
            };
        }
        // Prüfe, ob das Hinzufügen einer Einrichtung nun erlaubt ist oder nicht
        this.checkAllowNewInstitution(event.data['division'], event.data['region'], event.data['regionLevel']);
    }

    /**
     * Initialisiere Spaltenauswahl (UserSettings)
     * @param {CWEvent} event
     */
    onEventGridColumnChoiceChanged(event: CWEvent): void {
        // Init
        const prependColumns = environment.institutionsPrependDisplayedColumns;
        const appendColumns = environment.institutionsAppendDisplayedColumns;
        const chosenColumns = event.data;
        const displayedColumns = Object.assign([], prependColumns);

        // Gewählte Spalten setzen
        for (let i = 0; i < chosenColumns.length; i++) {
            // Spalte finden
            let index = 0;
            const column = this.gridColumns.find((column: any, columnIndex: number) => {
                if (column['columnDef'] === chosenColumns[i]['id']) {
                    index = columnIndex;
                    return true;
                }
                return false;
            });

            // Zwischenvariable zum Speichern der ID der Spalte, wird benötigt, damit es nicht später zu Referenzfehlern kommt.
            const name: string = chosenColumns[i]['id'];

            // Wenn die Spalten neu hinzugefügt werden sollen eine Spaltendefinition auf die gridColumns pushen
            if (chosenColumns[i]['target'] === 'characteristics' && typeof column === 'undefined') {
                const characteristicDefinition: Characteristic = JSON.parse(JSON.stringify(chosenColumns[i]));
                // Unterscheidung, ob es sich um einen einfachen Wert handelt, oder um eine Ausprägungen (Eine ID oder ein Array von AusprägungsIDs)
                if (chosenColumns[i]['value_type'] === 'options') {
                    // Die cell-Funktion unterscheidet hier, ob der Wert ein Array ist und übergibt entweder dieses oder den Wert als String
                    this.gridColumns.push({
                        columnDef: name,
                        header: chosenColumns[i]['label'],
                        cell: (element: any) => (Array.isArray(element[name]) ? element[name] : `${element[name]}`),
                        formatWidth: '65px',
                        formatTemplate: 'characteristics',
                        options: chosenColumns[i]['options'],
                        characteristicDefinition,
                    });
                } else if (chosenColumns[i]['value_type'] === 'date') {
                    this.gridColumns.push({
                        columnDef: name,
                        header: chosenColumns[i]['label'],
                        cell: (element: any) => `${element[name] === null ? '' : element[name]}`,
                        formatTemplate: 'datetime',
                        formatWidth: '65px',
                        characteristicDefinition,
                    });
                } else if (chosenColumns[i]['value_type'] === 'decimal') {
                    this.gridColumns.push({
                        columnDef: name,
                        header: chosenColumns[i]['label'],
                        cell: (element: any) => `${element[name] === null ? '' : element[name]}`,
                        formatTemplate: 'characteristic_decimal',
                        formatWidth: '65px',
                        options: {formatType: chosenColumns[i]['format_type']},
                        characteristicDefinition,
                    });
                } else {
                    this.gridColumns.push({
                        columnDef: name,
                        header: chosenColumns[i]['label'],
                        cell: (element: any) => `${element[name] === null ? '' : element[name]}`,
                        formatWidth: '65px',
                        characteristicDefinition,
                    });
                }
            } else if (chosenColumns[i]['target'] === 'characteristics') {
                const characteristicDefinition: Characteristic = JSON.parse(JSON.stringify(chosenColumns[i]));
                /*
                 * ... sonst wenn die Spalten schon existieren die Spaltendefinition am entsprechenden Index in den gridColumns überschreiben
                 * Unterscheidung, ob es sich um einen einfachen Wert handelt, oder um eine Ausprägungen (Eine ID oder ein Array von AusprägungsIDs)
                 */
                if (chosenColumns[i]['value_type'] === 'options') {
                    // Die cell-Funktion unterscheidet hier, ob der Wert ein Array ist und übergibt entweder dieses oder den Wert als String
                    this.gridColumns[index] = {
                        columnDef: name,
                        header: chosenColumns[i]['label'],
                        cell: (element) => (Array.isArray(element[name]) ? element[name] : `${element[name]}`),
                        formatWidth: '65px',
                        formatTemplate: 'characteristics',
                        options: chosenColumns[i]['options'],
                        characteristicDefinition,
                    };
                } else if (chosenColumns[i]['value_type'] === 'date') {
                    this.gridColumns[index] = {
                        columnDef: name,
                        header: chosenColumns[i]['label'],
                        cell: (element: any) => `${element[name] === null ? '' : element[name]}`,
                        formatTemplate: 'datetime',
                        formatWidth: '65px',
                        characteristicDefinition,
                    };
                } else if (
                    chosenColumns[i]['value_type'] === 'decimal' &&
                    chosenColumns[i]['format_type'] !== null &&
                    typeof chosenColumns[i]['format_type'] !== 'undefined'
                ) {
                    this.gridColumns[index] = {
                        columnDef: name,
                        header: chosenColumns[i]['label'],
                        cell: (element: any) => `${element[name] === null ? '' : element[name]}`,
                        formatTemplate: 'characteristic_decimal',
                        formatWidth: '65px',
                        options: {formatType: chosenColumns[i]['format_type']},
                        characteristicDefinition,
                    };
                } else {
                    this.gridColumns[index] = {
                        columnDef: name,
                        header: chosenColumns[i]['label'],
                        cell: (element) => `${element[name] === null ? '' : element[name]}`,
                        formatWidth: '65px',
                        characteristicDefinition,
                    };
                }
            }

            // Hier ein häßlicher Hack, weil bestimmte Spalten die als Target contacts haben. Bei denen darf cell nicht überschrieben werden.
            if (
                chosenColumns[i]['target'] === 'contacts' &&
                typeof column === 'undefined' &&
                (typeof chosenColumns[i]['overwrite'] === 'undefined' || chosenColumns[i]['overwrite'] === true)
            ) {
                this.gridColumns.push({
                    columnDef: name,
                    header: chosenColumns[i]['label'],
                    cell: (element: any) => `${element[name] === null ? '' : element[name]}`,
                    formatTemplate: chosenColumns[i]['template'],
                    formatWidth: '65px',
                });
            } else if (
                chosenColumns[i]['target'] === 'contacts' &&
                (typeof chosenColumns[i]['overwrite'] === 'undefined' || chosenColumns[i]['overwrite'] === true)
            ) {
                this.gridColumns[index] = {
                    columnDef: name,
                    header: chosenColumns[i]['label'],
                    cell: (element: any) => `${element[name] === null ? '' : element[name]}`,
                    formatTemplate: chosenColumns[i]['template'],
                    formatWidth: '65px',
                };
            }

            // @todo: Bitte prüfen, ob nötig und wenn möglich optimieren
            if (chosenColumns[i]['target'] === 'regions' && typeof column === 'undefined') {
                this.gridColumns.push({
                    columnDef: name,
                    header: chosenColumns[i]['label'],
                    cell: (element: any) => (Array.isArray(element[name]) ? element[name] : `${element[name]}`),
                    formatTemplate: chosenColumns[i]['template'],
                    formatWidth: '120px',
                });
            } else if (chosenColumns[i]['target'] === 'regions') {
                this.gridColumns[index] = {
                    columnDef: name,
                    header: chosenColumns[i]['label'],
                    cell: (element: any) => (Array.isArray(element[name]) ? element[name] : `${element[name]}`),
                    formatTemplate: chosenColumns[i]['template'],
                    formatWidth: '120px',
                };
            }

            // Spalte zwischenspeichern
            displayedColumns.push(chosenColumns[i]['id']);
        }

        // Die empty-Spalte und die 'institution-icon'-Spalte sollen immer angezeigt werden.
        displayedColumns.push(...appendColumns);
        // Die gridDisplayedColumns überschreiben
        this.gridDisplayedColumns = Object.assign([], displayedColumns);
    }

    /**
     * Layoutdaten vom Backend laden
     * @param {number} layoutId
     */
    loadLayoutData(layoutId = 0): void {
        const serviceRequest$ = this.institutionsListService.getLayoutData();
        serviceRequest$.subscribe(
            // Funktion wird ausgeführt, sobald Daten fertig geladen sind
            (result: CWResult) => {
                if (layoutId !== 0) {
                    this.gridService.changeLayout(this.gridId, layoutId); // setzt currentLayout der LayoutComponent
                }
                // jetzt erst ist alles fertig geladen, per Binding an Grid-Input listInitialized das Grid aktivieren
                this.initialized = true;
                /*
                 * setter an Input der Layout-Component, löst reload aus(!)
                 * @todo Refactoring der Kontrollstruktur für direktere Steuerung des Grid?
                 */
                this.gridLayouts = result['data'];
            },
        );
    }

    /**
     * Auf Event "eventGridCheckboxClicked" von "gridService" reagieren
     * @param {CWEvent} event
     */
    onEventGridCheckboxClicked(event: CWEvent): void {
        // Die Angehakten Reihen in Variable speichern.
        const alreadySelectedIndex: number = this.checkedInstitutions.findIndex(
            (checked: any) => event.data.clickedRow !== null && checked.id === event.data.clickedRow.id,
        );
        if (alreadySelectedIndex > -1) {
            this.checkedInstitutions.splice(alreadySelectedIndex, 1);
        } else if (event.data.clickedRow !== null) {
            this.checkedInstitutions.push(event.data.clickedRow);
        } else {
            this.checkedInstitutions = event.data.selection;
        }
    }

    /**
     * @brief   Auf Klick der Favorit-Spalte reagieren
     * @param {CWEvent} event
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onEventGridFavoriteIconClicked(event: CWEvent) {
        // Init
        const id = event.data.id;
        const newValue = event.data.newFavoriteValue;

        // Backend-Request absenden zum Speichern
        const serviceRequest$ = this.institutionsListService.toggleFavorite(id, newValue);
        serviceRequest$.subscribe((result: CWResult) => {
            // Falls Änderungen gespeichert werden konnten
            if (result['success']) {
                // Event auslösen
                this.gridService.toggleFavoriteIcon(this.gridId, id, newValue);
            } else {
                /*
                 * Fehlernachricht anzeigen
                 * @todo: Fehleranzeige programmieren
                 */
            }
        });
    }

    /**
     * Auf Event "eventGridExtensionToggle" von "toolbarService" reagieren
     * @param {CWEvent} event
     */
    onEventGridExtensionToggle(event: CWEvent): void {
        // Sichtbarkeit anderer Grid-Extensions auf "false" setzen
        Object.keys(this.gridExtensionsVisibility).forEach((key) => {
            if (key != event.data['extension']) {
                this.gridExtensionsVisibility[key] = false;
            }
        });
        // Sichtbarkeit der gewünschten Grid-Extension wechseln
        this.gridExtensionsVisibility[event.data['extension']] =
            !this.gridExtensionsVisibility[event.data['extension']];
    }

    /**
     * Auf Event "eventAddItem" von "toolbarService" reagieren
     * @param {CWEvent} event
     * @param isShadow
     */
    onEventAddItem(event: CWEvent, isShadow = false): void {
        // Neue Einrichtung
        const newInstitution: Institution = new Institution(); // = { id: 0, name1: 'Neue Einrichtung anlegen' };

        if (event.sender == 'toolbar-add-child') {
            newInstitution.parent_id = this.institutionsService.selectedInstitution.id;
            newInstitution.street = this.institutionsService.selectedInstitution.street;
            newInstitution.zipcode = this.institutionsService.selectedInstitution.zipcode;
            newInstitution.city = this.institutionsService.selectedInstitution.city;
        }

        // Neue Einrichtung auswählen
        this.institutionsService.selectInstitution(newInstitution, isShadow);
    }

    /**
     * Auf Event "eventDeleteItem" von "toolbarService" reagieren
     */
    onEventDeleteItem(): void {
        // Bestägigungsdialog öffnen
        this.openDeleteDialog();
    }

    /**
     * @brief   Dialog öffnen
     */
    openDeleteDialog(): void {
        // Dialog-Überschrift
        let dialogTitle: string;
        // Dialog-Text
        let dialogMessage: string;
        // Kommentar Löschgrund Pflichtfeld? Standardmäßig true
        let textAreaRequired: boolean;

        const serviceRequest$ = this.institutionsPeopleService.loadData(
            this.checkedInstitutions[0]['id'],
            this.gridPage,
        );
        serviceRequest$.subscribe((result: CWResult) => {
            if (result['data']['people'].length > 0) {
                /**
                 * Prüfe, ob die Daten des eintreffenden Requests auch
                 * zur aktuell ausgewählten Einrichtung passen. Durch
                 * asynchrone Abfragen kann es nämlich passieren, dass
                 * zwischenzeitlich bereits die Einrichtung gewechselt wurde
                 * und die Antwort eines Requests verspätet eintrifft und
                 * dadurch die korrekten Daten wieder überschreibt.
                 */
                if (this.institutionsService.selectedInstitution && result['data']) {
                    if (this.institutionsService.selectedInstitution.id != result['data']['id']) {
                        return;
                    }
                }

                // Dialog konfigurieren und öffnen
                const dialogRef = this.dialog.open(PopupMessageComponent, {
                    width: '350px',
                    data: {
                        title: this.translateService.instant('MODULES.INSTITUTIONS.LIST.DELETEINSTITUTIONS'),
                        message: this.translateService.instant(
                            'MODULES.INSTITUTIONS.LIST.DELETEINSTITUTIONSCONNECTEDPERSONS',
                            {count: result['data']['people'].length},
                        ),
                    },
                });
                // Auf das Schließen des Dialogs reagieren
                dialogRef.afterClosed().subscribe();
            } else {
                if (this.checkedInstitutions.length === 0) {
                    // Dialog konfigurieren und öffnen
                    const dialogRef = this.dialog.open(PopupMessageComponent, {
                        width: '350px',
                        data: {
                            title: this.translateService.instant('MODULES.INSTITUTIONS.LIST.DELETEINSTITUTIONS'),
                            message: this.translateService.instant(
                                'MODULES.INSTITUTIONS.LIST.SELECTINSTITUTIONSTODELETE',
                            ),
                        },
                    });
                    // Auf das Schließen des Dialogs reagieren
                    dialogRef.afterClosed().subscribe();

                    return;

                    // Dialog-Überschrift und Text in plural oder singular setzen
                } if (this.checkedInstitutions.length === 1) {
                    dialogTitle = this.translateService.instant('MODULES.INSTITUTIONS.LIST.DELETEINSTITUTION');
                    dialogMessage = this.translateService.instant(
                        'MODULES.INSTITUTIONS.LIST.DELETEINSTITUTIONQUESTION',
                    );
                } else if (this.checkedInstitutions.length > 1) {
                    dialogTitle = this.translateService.instant('MODULES.INSTITUTIONS.LIST.DELETEINSTITUTIONS');
                    dialogMessage = this.translateService.instant(
                        'MODULES.INSTITUTIONS.LIST.DELETEINSTITUTIONSQUESTION',
                    );
                }

                if (Object.prototype.hasOwnProperty.call(environment, 'textAreaRequired')) {
                    textAreaRequired = environment.textAreaRequired;
                } else {
                    textAreaRequired = true;
                }
                let deleteDefaultReason: any;
                if (Object.prototype.hasOwnProperty.call(environment, 'institutionDeleteDefaultReason')) {
                    deleteDefaultReason = environment.institutionDeleteDefaultReason;
                } else {
                    deleteDefaultReason = 0;
                }

                // Dialog konfigurieren und öffnen
                const dialogRef = this.dialog.open(PopupConfirmationComponent, {
                    width: '550px',
                    data: {
                        title: dialogTitle,
                        message: dialogMessage,
                        listName: 'institutionDeleteReason',
                        listKey: deleteDefaultReason,
                        textArea: true,
                        textAreaRequired,
                    },
                });
                // Auf das Schließen des Dialogs reagieren
                dialogRef.afterClosed().subscribe((result) => {
                    this.deleteInstitutions(result);
                });
            }
        });
    }

    /**
     * @brief Einrichtungen löschen
     * @param {any} result
     */
    deleteInstitutions(result: any): void {
        // Wenn in Confirm-Dialog Button "Ja" geklickt wurde
        if (result.answer == 'yes') {
            const formData: any = {
                institutionsToDelete: this.checkedInstitutions,
                deleteReason: result.listKey,
                deleteComment: result.deleteComment,
            };
            // Submit der Formular-Daten über institutionsListService
            const serviceRequest$ = this.institutionsListService.deleteInstitutions(formData);
            serviceRequest$.subscribe(() => {
                // Zurücksetzen
                this.checkedInstitutions = [];

                // Neu laden
                this.gridService.reloadGridData('institutionsList');
            });
        }
    }

    /**
     * Auf Event "eventExcelExport" von "toolbarService" reagieren
     */
    onEventExcelExport(): void {
        // Prüfe ob Einrichtungen ausgewählt wurden
        if (this.checkedInstitutions.length > 0) {
            this.openExportDialog();
        } else {
            this.confirmExcelExport();
        }
    }

    /**
     * Exportdialog öffnen
     */
    openExportDialog(): void {
        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(PopupConfirmationComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant('MODULES.INSTITUTIONS.LIST.EXPORTINSTITUTIONS'),
                message: this.translateService.instant('MODULES.INSTITUTIONS.LIST.EXPORTINSTITUTIONSQUESTION'),
            },
        });
        // Auf das Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe((result) => {
            this.confirmExcelExport(result.answer);
        });
    }

    /**
     * Event auslösen um Daten zu exportieren
     * @param {string} answer
     */
    confirmExcelExport(answer = 'no'): void {
        if (answer == 'yes') {
            // ID's sammeln
            const selectedIds = this.checkedInstitutions.map((institution) => institution.id);

            // Event auslösen und ID's übergeben
            this.toolbarService.excelExportValidated('institutionsList', this.gridId, selectedIds);
        } else {
            // Event auslösen
            this.toolbarService.excelExportValidated('institutionsList', this.gridId);
        }
    }

    /**
     * @brief   Auf Klick in der Toolbar reagieren
     * @param {CWEvent} event
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    onEventToolbarButtonClicked(event: CWEvent): void {
        switch (event.sender) {
            case 'toolbar-merge-items':
                // Dialog öffnen
                this.openMergeDialog();
                break;
            case 'toolbar-scheduling':
                // Dialog öffnen
                this.openSchedulingDialog();
                break;
            case 'toolbar-characteristics':
                // Dialog öffnen
                this.openCharacteristicsDialog();
                break;
            case 'toolbar-hierarchy':
                this.onChangeIgnoreHierarchy();
                break;
            case 'toolbar-loadAll':
                this.onChangeLoadAll();
                break;
            default:
                break;
        }
    }

    /**
     * @brief   Dialog öffnen für Merge
     * @param   {Institution[]}    selectedEntities    übergebene Einrichtungsdaten (optional)
     * @todo    Übersetzung
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    openMergeDialog(selectedEntities: Institution[] = null): void {
        // Init
        const dialogConfig = {
            disableClose: true,
            height: 'auto',
            maxHeight: 'calc(100vh - 125px)',
            width: '900px',
            data: {
                title: 'Einrichtungen zusammenführen',
                selectedEntities: this.checkedInstitutions,
                comparableColumnsConfig: environment.institutionsDatafields,
                entityType: 'Institutions',
                entityGridId: this.gridId,
            },
        };

        // Übergebene Personen einfügen
        if (selectedEntities !== null && selectedEntities.length > 0) {
            dialogConfig.data.selectedEntities = selectedEntities;
        }

        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(ClearingMergePopupComponent, dialogConfig);

        // Auf das Schließen des Dialogs reagieren  // TODO reload liste
        dialogRef.afterClosed().subscribe(() => {
            this.gridService.reloadGridData('institutionsList');
        });
    }

    /**
     * @brief   Terminplanung öffnen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    openSchedulingDialog(): void {
        // Initialisiere Dialog Daten
        const dialogConfig = {
            height: '500px',
            width: '990px',
            data: {
                entity: 'institution',
                backendController: 'InstitutionsContacts',
                displayedColumns: ['institution-name', 'institution-address'],
                gridData: this.checkedInstitutions,
            },
        };

        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(PopupSchedulingComponent, dialogConfig);
        // Auf das Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe(() => undefined);
    }

    /**
     * @brief   Dialog öffnen für Mehrfachkennzeichen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    openCharacteristicsDialog(): void {
        // Dialog konfigurieren und öffnen
        const dialogRef = this.dialog.open(CharacteristicGroupsListPopupComponent, {
            height: 'auto',
            maxHeight: 'calc(100vh - 125px)',
            width: '900px',
            data: {
                selectedEntities: this.checkedInstitutions,
                characteristicType: 'institution',
                multi: true,
                showReadonly: false,
                showConfirmationDialog: true,
                showDeleteButton: true,
            },
        });

        // Auf das Schließen des Dialogs reagieren
        dialogRef.afterClosed().subscribe(() => undefined);
    }

    /**
     * Initialisiere Sortierung (UserSettings)
     * @param {string} userSettingSort
     */
    initSort(userSettingSort: string): void {
        // Richtung der Sortierung (Standard = asc)
        let direction = 'asc';
        const splittedUserSettingSort = userSettingSort.split('|');
        if (splittedUserSettingSort.length > 1 && splittedUserSettingSort[1].toLowerCase() == 'desc') {
            direction = 'desc';
        }

        // Sortierfeld setzen
        for (const sortfield of this.gridSortFields) {
            // Handelt es sich um den Key?
            if (sortfield.key == splittedUserSettingSort[0]) {
                sortfield.active = true;
                sortfield.direction = direction;
            }
        }
    }

    /**
     * Zwischenlösung für die Custom-Sortierung
     */
    checkCustomSort(): void {
        let showCustomSort = false;
        if (Object.prototype.hasOwnProperty.call(environment, 'showCustomSort')) {
            showCustomSort = environment.showCustomSort;
        }
        if (showCustomSort) {
            if (Object.prototype.hasOwnProperty.call(environment, 'customSortLabel')) {
                const customSort: FilterData = {
                    label: environment.customSortLabel,
                    key: 'custom',
                };
                if (Object.prototype.hasOwnProperty.call(environment, 'customSortLockedDirection')) {
                    customSort.lockedDirection = environment.customSortLockedDirection;
                }
                if (Object.prototype.hasOwnProperty.call(environment, 'customSortInitialDirection')) {
                    customSort.initialDirection = environment.customSortInitialDirection;
                }
                this.gridSortFields.push(customSort);
            }
        }
    }

    /**
     * Prüfe, ob das Nutzen der Terminplanung erlaubt ist oder nicht
     */
    checkEnableScheduling(): void {
        const permissionEnableScheduling: boolean =
            this.userPermissions.getPermissionValue('enableInstitutionsScheduling');
        this.enableScheduling = permissionEnableScheduling;
    }

    /**
     * Prüfe, ob der alle Laden funktion erlaubt ist oder nicht
     */
    checkEnableLoadAll(): void {
        const permissionEnableScheduling: boolean = this.userPermissions.getPermissionValue('enableListLoadAll');
        this.allowLoadAll = permissionEnableScheduling;
    }

    /**
     * Prüfe, ob das Hinzufügen einer Einrichtung (abhängig von Regionsfilter erlaubt ist oder nicht)
     * @param {number} division
     * @param {number} region
     * @param {number} regionLevel
     */
    checkAllowNewInstitution(division = 0, region = 0, regionLevel = 0): void {
        // Berechtigung prüfen
        this.permissionAllowNewInstitution = this.userPermissions.getPermissionValue('allowNewInstitution');
        this.permissionAllowNewShadowInstitution = this.userPermissions.getPermissionValue('allowNewShadowInstitution');

        // Konfiguration prüfen - Segmente werden im Frontend und Backend aktiviert
        this.allowNewInstitution = this.institutionsService.checkNewEntityAllowed(
            this.permissionAllowNewInstitution,
            division,
            region,
            regionLevel,
        );
    }

    /**
     * Berechtigung "allowDeleteInstitution" prüfen
     */
    checkAllowDeleteInstitution(): void {
        const permissionAllowDeleteInstitution: boolean =
            this.userPermissions.getPermissionValue('allowDeleteInstitution');
        this.allowDeleteInstitution = permissionAllowDeleteInstitution;
    }

    /**
     * @brief   Berechtigung "allowMergeEntities" prüfen
     */
    checkAllowMergeEntities(): void {
        const permissionAllowMergeEntities: boolean = this.userPermissions.getPermissionValue('allowMergeEntities');
        this.allowMergeEntities = permissionAllowMergeEntities && environment.enableClearingMergeInstitutions;
    }

    /**
     * @brief   Berechtigung "allowEditInstitution" prüfen
     */
    checkAllowEditInstitution(): void {
        const permissionAllowEditInstitution: boolean = this.userPermissions.getPermissionValue('allowEditInstitution');
        this.allowEditInstitution = permissionAllowEditInstitution;
    }

    checkAllowExcelExportDeparments(): void {
        // Environment-Definition, ob Abteilungen beim Excel Export berücksichtigt werden
        if (typeof environment.institutionsListExportDepartments !== 'undefined') {
            this.excelExportDepartments = environment.institutionsListExportDepartments;
        }
    }

    /**
     * Environment-Definition, ob der Button "Hierarchie anzeigen / ausblenden" in der Toolbar angezeigt werden soll
     */
    checkAllowChangeHierarchy(): void {
        if (typeof environment.allowChangeHierarchy !== 'undefined') {
            this.allowChangeHierarchy = environment.allowChangeHierarchy;
        }
    }

    /**
     * Spalte zur Anzeige des letzten eigenen Kontakts anpassen, damit die
     * Query mit Join auf die zugeordnete Gebietsnummer aufgebaut werden kann.
     * @returns {Promise<any>}
     */
    private updateLastOwnContactColumn(): Promise<any> {
        // gib das Promise zurück, damit die Initialisierung auf die Fertigstellung warten kann
        return this.storageService.getItem('ownUser').then((val) => {
            // Gebiet zwischenspeichern
            let regionId = 0;
            if (val && val.region_id) {
                regionId = val.region_id;
            }

            // Spalten-Gruppe finden
            const contactColumns: LooseObject | undefined = this.gridAllColumns.find(
                (columnGroups: LooseObject) => columnGroups.id === 'contacts',
            );
            if (contactColumns !== undefined) {
                // Spalten-Definition finden
                const lastOwnContactColumn: LooseObject | undefined = contactColumns.columns.find(
                    (column: LooseObject) => column.id === 'last_own_contact',
                );
                if (lastOwnContactColumn !== undefined) {
                    // Bedingung finden
                    const additionalRegionColumn: LooseObject | undefined =
                        lastOwnContactColumn.additionalConditions.find(
                            (condition: LooseObject) => condition.column === 'region_id',
                        );
                    if (additionalRegionColumn !== undefined) {
                        additionalRegionColumn.value = regionId;
                    }
                }
            }
        });
    }

    /**
     * Setzt das ignoreHierachy flag im service das die suche weiß die hierach zu ignorieren
     */
    public onChangeIgnoreHierarchy() {
        this.ignoreHierarchy = !this.ignoreHierarchy;
        this.institutionsListService.ignoreHierarchy = this.ignoreHierarchy;
    }

    /**
     * toggelt das flag loadAll
     */
    public onChangeLoadAll() {
        this.loadAll = !this.loadAll;
    }
}
