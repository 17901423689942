/**
 * @brief   Container-Komponente für alle Institutions-Submodule
 * @author  Massimo Feth <m.feth@pharmakon.software>
 */
// Angular-Module
import {Component, OnDestroy, Input, OnInit} from '@angular/core';
// Service für Übersetzungen über NGX-Translate
import {TranslateService} from '@ngx-translate/core';
// ReactiveX for JavaScript
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
// Globale Services
import {GlobalRegionsfilterService} from '@global/components/global-regionsfilter/global-regionsfilter.service';
import {AppCoreService} from '@global/services/app-core.service';
import {UserPermissionsService} from '@global/services/user-permissions.service';
import {GridService} from '@shared/grid/grid.service';
import {InstitutionsDataService} from '../institutions-data/institutions-data.service';
// Service dieses Feature-Moduls
import {InstitutionsService} from '../institutions.service';
/*
 * Shared Services
 * Interfaces für Structured Objects einbinden
 */
import {environment} from '@environment';
import {CWEvent} from '@shared/cw-event';
import {Institution} from '@shared/institution';
import {hasOwn} from '@shared/utils';

@Component({
    selector: 'phscw-institutions-details',
    templateUrl: './institutions-details.component.html',
    styleUrls: ['./institutions-details.component.scss'],
    /**
     * 2019-09-27, PhS(MFe):
     * ChangeDetection darf in dieser Komponente (noch) nicht generell auf
     * OnPush umgestellt werden, da dies Auswirkungen auf sämtliche
     * untergeordneten C-World Einrichtungs-Module / -Komponenten hat.
     */
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstitutionsDetailsComponent implements OnInit, OnDestroy {
    // Wird bei ngOnDestroy ausgelöst um Observables-Subscription zu stoppen
    private _componentDestroyed$ = new Subject<void>();

    // Setter method for the input property
    @Input() set routedId(value: number) {
        if (value > 0) {
            const id = +value;
            const serviceRequest$ = this.institutionsDataService.loadData(id);
            serviceRequest$.subscribe((result: any) => {
                if (!result.success) {
                    return;
                }
                this.institutionsService.selectInstitution(result.data);
                this.onSelectionChanged(+value);
            });
        }
    }

    // Standardtext für "Neue Einrichtung"
    textNewItem = 'Neue Einrichtung anlegen';

    // Überschrift der Detail-Module
    headerText = '';
    // Icon neben Überschrift
    headerIcon = '';

    // ID der aktuell ausgewählten Einrichtung
    institutionId: number;
    // Icons der aktuell ausgewählten Einrichtung
    institutionIcons = '';

    // Level der Hierarchie
    currentHierarchyLevel = 0;
    maxHierarchyLevel = 0;

    // Definiert, ob über die Toolbar eine neue Person zur Einrichtung hinzugefügt werden darf
    allowNewPerson = false;

    // Definiert, ob eine neue Person zur Einrichtung hinzugefügt werden darf
    allowMoveInstitution = false;

    // Definiert, ob eine neue Abteilung zur Einrichtung hinzugefügt werden darf
    allowNewDepartment = false;

    // Definiert, ob über die Toolbar Personen angelegt werden dürfen
    enableAddPersonButton = false;

    // Berechtigung zum Zuordnen von Einrichtungen in andere (oder keine) übergeordnete Einrichtungen
    permissionAllowMoveInstitution = false;

    // Button, um Abteilungen zu erstellen
    enableAddDepartmentButton = false;

    // Array der Namen aktiver / zur Verfügung stehender Submodule
    activeSubmodules: string[] = [];

    /**
     * Konstruktor (inkl. dependency injection)
     * @param appCore
     * @param gridService
     * @param userPermissions
     * @param regionsfilterService
     * @param institutionsService
     * @param translateService
     * @param institutionsDataService
     */
    constructor(
        private appCore: AppCoreService,
        private gridService: GridService,
        private userPermissions: UserPermissionsService,
        private regionsfilterService: GlobalRegionsfilterService,
        private institutionsService: InstitutionsService,
        private translateService: TranslateService,
        private institutionsDataService: InstitutionsDataService,
    ) {}

    /**
     * Initialisieren
     */
    ngOnInit() {
        /*
         * Eventuell wurden die Menüdaten bereits zuvor im AppCore abgelegt.
         * Diese Daten werden benötigt um die Sichtbarkeit der Submodule zu
         * regeln. Das notwendige Event wird hier manuell ausgelöst.
         */
        const eventData: CWEvent = {
            sender: 'institutions',
            target: 'institutions',
            data: {
                globalMenu: this.appCore.globalMenu,
                submodules: this.appCore.submodules,
            },
        };
        this.receiveAppCoreGlobalMenuChanged(eventData);

        // Übersetzungen subscriben
        this.initializeTranslateSubscriptions();

        // Events subscriben
        this.initializeEventSubscriptions();

        // Einstellung aus Environment übernehmen
        this.getEnvironmentConfigurations();

        // Berechtigung prüfen
        this.checkAllowMoveInstititution();
    }

    /**
     * Aufräumen
     */
    ngOnDestroy() {
        this._componentDestroyed$.next();
        this._componentDestroyed$.complete();
    }

    /**
     * @brief   Übersetzungen subscriben
     * @details Subscribe auf Stream bekommt Änderung der Sprache mit
     *          und lädt Übersetzungen neu statt nur bei Initialisierung
     * @todo    Keys für stream() in Variable auslagern sobald von ngx-translate unterstützt wird
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    initializeTranslateSubscriptions(): void {
        this.translateService
            .stream(['MODULES.INSTITUTIONS.LIST.ADDNEW'])
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((translation: any) => {
                this.textNewItem = translation['MODULES.INSTITUTIONS.LIST.ADDNEW'];
            });
    }

    /**
     * Events subscriben
     */
    initializeEventSubscriptions(): void {
        // Wenn Regionsfilter geändert wird
        this.regionsfilterService.eventRegionsfilterChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((event: CWEvent) => {
                // Abbruch, falls Regionsfilter nicht für Einrichtungen gilt
                if (event.target != 'institutions') {
                    return;
                }
                this.onEventRegionsfilterChanged(event);
            });

        // Event "selectionChanged" von "institutionsService"
        this.institutionsService.selectionChanged
            .pipe(takeUntil(this._componentDestroyed$))
            .subscribe((result: number) => {
                this.onSelectionChanged(result);
            });

        // Event "Daten haben sich geändert"
        this.appCore.appDataChanged.pipe(takeUntil(this._componentDestroyed$)).subscribe((event: CWEvent) => {
            this.onAppDataChanged(event);
        });

        // Auf Änderung im globalen Menü reagieren
        this.appCore.globalMenuChanged.pipe(takeUntil(this._componentDestroyed$)).subscribe((result: CWEvent) => {
            const event: CWEvent = result;
            if (event.target !== 'institutions') {
                return;
            }
            this.receiveAppCoreGlobalMenuChanged(event);
        });
    }

    /**
     * Auf Event "eventRegionsfilterChanged" des globalen Regionsfilters reagieren
     * @param event
     */
    onEventRegionsfilterChanged(event: CWEvent): void {
        // Prüfe, ob das Hinzufügen einer Abteilung nun erlaubt ist oder nicht
        this.checkAllowMoveInstititution(event.data['division'], event.data['region'], event.data['regionLevel']);
    }

    /**
     * In der E-Liste wurde eine (andere) Einrichtung ausgewählt
     * @param selectedId
     */
    onSelectionChanged(selectedId: number): void {
        // ID "merken"
        this.institutionId = selectedId;
        // Header-Text unterscheidet sich zwischen Neuanlage und existierender Einrichtung
        if (this.institutionId == 0) {
            this.headerText = this.textNewItem;
            this.headerIcon = '';
        } else if (this.institutionId > -1) {
            this.setHeaderText(this.institutionsService.selectedInstitution);
        } else {
            // Text zurücksetzen
            this.headerText = '';
            this.headerIcon = '';

            // Abbrechen, da keine Einrichtung gesetzt ist
            return;
        }

        // Icons zwischenspeichern
        if (
            this.institutionsService.selectedInstitution !== null &&
            hasOwn(this.institutionsService.selectedInstitution, 'icons')
        ) {
            this.institutionIcons = this.institutionsService.selectedInstitution.icons;
        }

        // Prüfe, ob das Hinzufügen einer Person zu dieser Einrichtung erlaubt ist oder nicht
        this.checkAllowNewPerson();

        // Prüfe, ob das Hinzufügen einer Abteilung zu dieser Einrichtung erlaubt ist oder nicht
        this.checkAllowNewDepartment();
    }

    /**
     * Es wurden Daten geändert
     * @param event
     */
    onAppDataChanged(event: CWEvent): void {
        if (event.sender == 'institutions-data') {
            this.setHeaderText(event.data['changedItem']);
            this.setHierarchyLevel(event.data['changedItem']);
        } else if (event.sender == 'characteristics' && event.target === 'characteristic-groups-list-popup') {
            this.onCharacteristicsDataChanged(event);
            // Grid Data neu laden
            this.gridService.reloadGridData('institutionsList');
        } else if (event.sender == 'characteristics' && event.target === 'institution') {
            this.onCharacteristicsDataChanged(event);
        } else if (event.sender == 'contacts' && event.target === 'contacts-form-popup') {
            // Grid Data neu laden
            this.gridService.reloadGridData('institutionsList');
        }
    }

    /**
     * Es wurden Kennzeichen geändert
     * @param eventData
     */
    onCharacteristicsDataChanged(eventData: CWEvent): void {
        if (
            hasOwn(eventData, 'data') &&
            eventData.data !== null &&
            typeof eventData.data !== 'undefined' &&
            eventData.data.id === this.institutionId
        ) {
            // Daten speichern
            this.institutionsService.updateInstitutionIcons(eventData.data.iconString);

            // Icons zwischenspeichern
            this.institutionIcons = eventData.data.iconString;
        }
    }

    /**
     * Header-Text (& Icon) ändern
     * @param institution
     */
    setHeaderText(institution: Institution): void {
        this.headerText = '';
        this.headerIcon = '';
        if (!institution) {
            return;
        }
        if (institution.name1) {
            this.headerText = institution.name1;
        }
        if (institution.name2) {
            if (this.headerText.length > 0) {
                this.headerText += ' ';
            }
            this.headerText += institution.name2;
        }
        if (institution.institution_icon) {
            this.headerIcon = institution.institution_icon;
        }
    }

    /**
     * Daten zur Hierarchie speichern
     * @param institution
     */
    setHierarchyLevel(institution: Institution): void {
        if (institution !== null && hasOwn(institution, 'current_hierarchy_level')) {
            this.currentHierarchyLevel = institution.current_hierarchy_level;
        }
        if (institution !== null && hasOwn(institution, 'max_hierarchy_level')) {
            this.maxHierarchyLevel = institution.max_hierarchy_level;
        }
    }

    /**
     * Prüfe, ob das Hinzufügen einer Person zu dieser Einrichtung erlaubt ist
     */
    checkAllowNewPerson(): void {
        const permissionAllowNewPerson: boolean = this.userPermissions.getPermissionValue('allowNewPerson');
        if (this.institutionId > 0 && permissionAllowNewPerson == true) {
            this.allowNewPerson = true;
        } else {
            this.allowNewPerson = false;
        }
    }

    /**
     * Prüfe, ob das Hinzufügen einer Abteilung zu dieser Einrichtung erlaubt ist
     */
    checkAllowNewDepartment(): void {
        const permissionAllowNewDepartment: boolean = this.userPermissions.getPermissionValue('allowNewDepartment');
        if (this.institutionId > 0 && permissionAllowNewDepartment == true) {
            this.allowNewDepartment = true;
        } else {
            this.allowNewDepartment = false;
        }
    }

    /**
     * Berechtigung "allowMoveInstitution" prüfen
     * @param division
     * @param region
     * @param regionLevel
     */
    checkAllowMoveInstititution(division = 0, region = 0, regionLevel = 0): void {
        // Berechtigung auslesen
        this.permissionAllowMoveInstitution = this.userPermissions.getPermissionValue('allowMoveInstitution');

        // Konfiguration prüfen - Segmente werden im Frontend und Backend aktiviert
        this.allowMoveInstitution = this.institutionsService.checkNewEntityAllowed(
            this.permissionAllowMoveInstitution,
            division,
            region,
            regionLevel,
        );
    }

    /**
     * @param event
     * @brief   Event "GlobalMenuChanged" vom "AppCore" erhalten und anhand der
     *          Daten definieren, welche Submodule geladen werden.
     * @author  Massimo Feth <m.feth@pharmakon.software>
     */
    receiveAppCoreGlobalMenuChanged(event: CWEvent): void {
        if (hasOwn(event.data, 'submodules') && hasOwn(event.data['submodules'], 'institutions')) {
            this.activeSubmodules = Object.keys(event.data['submodules']['institutions']);
        }
    }

    /**
     * @brief   Einstellung aus Environment übernehmen
     * @author  Tobias Hannemann <t.hannemann@pharmakon.software>
     */
    getEnvironmentConfigurations(): void {
        // Definiert, welche Buttons angezeigt werden sollen
        if (hasOwn(environment, 'enableInstitutionsDataAddDepartmentButton')) {
            this.enableAddPersonButton = environment.enableInstitutionsDataAddPersonButton;
        }
        if (hasOwn(environment, 'enableInstitutionsDataAddDepartmentButton')) {
            this.enableAddDepartmentButton = environment.enableInstitutionsDataAddDepartmentButton;
        }
    }
}
