import {Component} from '@angular/core';
import {GridCellRenderer} from '@shared/grid-new/grid-new-config';

@Component({
    selector: 'phscw-grid-template-currency',
    standalone: true,
    imports: [],
    templateUrl: './grid-template-currency.component.html',
    styleUrl: './grid-template-currency.component.scss',
})
export class GridTemplateCurrencyComponent implements GridCellRenderer<number> {
    value: number;
}
