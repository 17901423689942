// Angular-Module
import {Injectable} from '@angular/core';
// ReactiveX for JavaScript
import {Observable, Subject} from 'rxjs';
// Globale Services
import {AppCoreService} from '@global/services/app-core.service';
import {BackendService} from '@global/services/backend.service';
// Interfaces für Structured Objects einbinden
import {CWEvent} from '@shared/cw-event';
import {User} from '@shared/user';

@Injectable({providedIn: 'root'})
export class AdminUsersService {
    // Ausgewählter User
    public selectedUser: User;

    // Subject (Observable) definieren "Es wurde eine anderer User ausgewählt"
    public selectionChanged = new Subject<number>();

    // Konstruktor
    constructor(
        private appCore: AppCoreService,
        private backendService: BackendService,
    ) {}

    /*
     * @brief   Wird aufgerufen, falls eine anderer User ausgewählt werden soll
     */
    selectUser(selectedUser: User) {
        // Ausgewählte User zwischenspeichern
        this.selectedUser = selectedUser;
        // Event auslösen um andere Komponenten des Feature-Moduls zu informieren
        this.selectionChanged.next(this.selectedUser.id);
    }

    /*
     * @brief   Wird aufgerufen, falls die Auswahl "geleert" werden soll
     */
    selectEmptyUser() {
        this.selectedUser = null;
        this.selectionChanged.next(-1);
    }

    /**
     * @param id
     * @brief   Stammdaten eines Users laden
     * @returns  Observable<any>
     */
    loadData(id): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('AdminUsers/details/' + id);
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @param id
     * @param formData
     * @brief   Speichert Stammdaten eines Users
     * @returns  Observable<any>
     */
    saveData(id: number, formData: any): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('AdminUsers/edit/' + id, formData);
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @param data
     * @brief      Falls Daten erfolgreich geändert wurden
     * @details    Sendet aktualisierten Datensatz per Event, damit andere
     *             Module (z.B. Listen) darauf reagieren können
     */
    dataChanged(data: User): void {
        // Informationen für Event
        const eventData: CWEvent = {
            sender: 'admin-users-data',
            target: '',
            data: {changedItem: data},
        };
        this.appCore.appDataChanged.next(eventData);
    }

    /**
     * @param id
     * @brief   Stammdaten eines Mitarbeiters laden
     * @returns  Observable<any>
     */
    loadEmployeeData(id): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('Employees/details/' + id);
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @param employeeId
     * @brief   Stammdaten eines Mitarbeiters laden
     * @returns  Observable<any>
     */
    loadEmployeeDataById(employeeId): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('Employees/getEmployeeById/' + employeeId);
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @brief   Speichert Stammdaten eines Mitarbeiters
     * @param   number      userId              Benutzer ID
     * @param   boolean     employeeIdReadonly  Flag, ob Personalnummer editierbar.
     *                                          Wenn TRUE - werden Mitarbeiterdaten aktualisiert.
     * @param userId
     * @param formData
     * @param employeeIdReadonly
     *                                          Wenn FALSE - wird neuen Mitarbeiter angelegt.
     * @returns  Observable<any>
     */
    saveEmployeeData(userId: number, formData: any, employeeIdReadonly: boolean): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'Employees/edit/' + userId + '/' + employeeIdReadonly,
            formData,
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @brief   Rechte eines Users laden
     * @param id
     * @param   number  userId  Benutzer ID
     * @returns  Observable<any>
     */
    loadPermissionsData(id): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('Permissions/getUsersPermissions/' + id);
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @brief   Rollen eines Users laden
     * @param id
     * @param   number  userId  Benutzer ID
     * @returns  Observable<any>
     */
    loadRolesData(id): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('Permissions/getUsersRoles/' + id);
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @brief   Rollen eines Users laden
     * @param id
     * @param   number  userId  Benutzer ID
     * @returns  Observable<any>
     */
    loadRefreshTokenData(id): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('Users/getRefreshTokens/' + id);
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @brief   Einzelne Rolle laden
     * @param userId
     * @param roleId
     * @param   number  userId  Benutzer ID
     *          number  roleId  Rolle-ID
     * @returns  Observable<any>
     */
    loadRoleDetails(userId: number, roleId: number): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest(
            'Permissions/roleDetails/' + userId + '/' + roleId,
        );
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @brief   Speichert Rolle eines Users
     * @param userId
     * @param roleId
     * @param   number  userId  Benutzer ID
     *          number  roleId  Rolle-ID
     * @returns  Observable<any>
     */
    saveRoleData(userId: number, roleId: number): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'Permissions/addRole/' + userId + '/' + roleId,
            {},
        );

        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @brief   Löschen einer Rolle
     * @param userId
     * @param roleId
     * @param   number  userId  Benutzer ID
     *          number  roleId  Rolle-ID
     * @returns  Observable<any>
     */
    deleteUserRoleData(userId: number, roleId: number): Observable<any> {
        /*
         * let deleteData: any = {'userId': userId, 'roleId': roleId};
         * POST-Request über BackendService senden
         */
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'Permissions/delete/' + userId + '/' + roleId,
            {},
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @brief   Priorität einer Rolle ändern
     * @param   number  userId      Benutzer ID
     *          number  roleId      Rolle-ID
     * @param userId
     * @param roleId
     * @param currentPrio
     * @param wantedPrio
     *          number  currentPrio aktuelle Prio
     *          number  wantedPrio  gewünschte Prio
     * @returns  Observable<any>
     */
    setUserRolePriority(userId: number, roleId: number, currentPrio: number, wantedPrio: number): Observable<any> {
        // POST-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest(
            'Permissions/setUserRolePriority/' + userId + '/' + roleId + '/' + currentPrio + '/' + wantedPrio,
            {},
        );
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }

    /**
     * @brief   Feiertags-Kalender laden
     * @returns  Observable<any>
     */
    loadHolidayCalendars(): Observable<any> {
        // GET-Request über BackendService senden
        const getRequest$: Observable<any> = this.backendService.getRequest('Holidays/getCalendars/');
        // Observable (an Komponente) zurücklieferen
        return getRequest$;
    }

    /**
     * @brief   Alle Rollen laden
     * @returns  Observable<any>
     */
    loadAllRoles(): Observable<any> {
        // GET-Request über BackendService senden
        const postRequest$: Observable<any> = this.backendService.postRequest('AdminRoles/index/', {
            regionsfilter: [],
            columnData: [],
            selectionlist: '10',
            page: 1,
        });
        // Observable (an Komponente) zurücklieferen
        return postRequest$;
    }
}
